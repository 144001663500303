import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./notification.scss";
import Bell from "components/icons/Bell";
import NotificationBlock from "components/notifications/NotificationBlock";
import Heading from "components/typography/heading/Heading";
import { AUTH_REPORT_DELETE, AUTH_WORKFLOW_VIEW } from "domain/authority";
import { Notification } from "domain/notification";
import { FLAG_DELETE_REPORT } from "services/feature/FeatureFlagService";
import { notificationService } from "services/notification/NotificationService";
import { ReportDeletionNotificationData } from "services/report/ReportDeletionService";
import { ImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { TenantDeletionNotificationData } from "services/tenants/TenantService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const NotificationMenu = (
    props: {
        notifications: Notification[];
        onDelete: (notifications: Notification[]) => void;
        showImportReportDialog: (jobData: ImportNotificationData) => void;
        showExportReport: (jobId: string) => void;
        showDeleteReportDialog: (jobData: ReportDeletionNotificationData) => void;
        showDeleteTenantDialog?: (tenantNameData: TenantDeletionNotificationData) => void;
        showLicenseAlertDialog?: (uuid: string) => void;
        className?: string;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const featureUpdateData = props.notifications.find((notification) => notification.data === "Workflow");

    const deleteNotification = (notificationUuid: string) => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.REMOVE_NOTIFICATION,
        });
        notificationService.deleteNotification(notificationUuid).then(() => {
            const notification = props.notifications?.find((notification) => notification.uuid === notificationUuid);
            if (notification == null) {
                return;
            }
            const index = props.notifications?.indexOf(notification);
            if (index != undefined && index > -1) {
                props.onDelete(props.notifications.filter((_each, key) => key !== index));
            }
        });
    };

    const getFeatureAuthority = () => {
        const featureAuthority = new Map();
        featureAuthority.set("Workflow", AUTH_WORKFLOW_VIEW);
        return featureAuthority;
    };

    const createFeatureAuthority = getFeatureAuthority();
    function deriveNotification(notification: Notification, index: number) {
        if (notification.type.includes("REPORT_DELETION") || notification.type.includes("FEATURE_UPDATE")) {
            if (
                (userSessionService.userHasAllAuthorities([AUTH_REPORT_DELETE]) && useFeature(FLAG_DELETE_REPORT)) ||
                userSessionService.userHasAnyAuthority([createFeatureAuthority.get(featureUpdateData?.data)])
            ) {
                return (
                    <NotificationBlock
                        notification={notification}
                        key={index}
                        onDelete={deleteNotification}
                        showImportReportDialog={props.showImportReportDialog}
                        showExportReport={props.showExportReport}
                        showDeleteReport={props.showDeleteReportDialog}
                        showLicenseAlertDialog={props.showLicenseAlertDialog}
                    />
                );
            }
        } else {
            return (
                <NotificationBlock
                    notification={notification}
                    key={index}
                    onDelete={deleteNotification}
                    showImportReportDialog={props.showImportReportDialog}
                    showExportReport={props.showExportReport}
                    showDeleteReport={props.showDeleteReportDialog}
                    showDeleteTenant={props.showDeleteTenantDialog}
                    showLicenseAlertDialog={props.showLicenseAlertDialog}
                />
            );
        }
    }

    return (
        <div className={classNames(style.menu, props.className)} data-testid={testIds.header.notificationMenu.itself}>
            {props.notifications.length < 1 ? (
                <div className={style.notificationEmptyState}>
                    <Bell fillColor={props.theme.textColor} size={36} />
                    <div className={style.notificationEmptyStateContent}>
                        <Heading tag="div" variant="SUBTITLE_2">
                            {t("Notification.title.noNotifications.title")}
                        </Heading>
                        <div className={style.message}>{t("Notification.title.noNotifications.message")}</div>
                    </div>
                </div>
            ) : (
                <div>{props.notifications?.map((each, index) => deriveNotification(each, index))}</div>
            )}
        </div>
    );
};

export default connector(NotificationMenu);
