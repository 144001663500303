import { TFunction } from "i18next";
import * as React from "react";
import SelectSearch, { DomProps, SelectedOption, SelectSearchOption } from "react-select-search";

// import styleSelector from "./create-report-view-modal.scss";
import style from "./filter-view-data.scss";
import AddNewIcon from "components/icons/AddNewIcon";
import Delete from "components/icons/Delete";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import { PathType, toPathType } from "domain/reports";
import { Path } from "services/report/erasure/ReportService";
import {
    AdvanceSerchFilter,
    ReportPath,
    ReportViewFilterOperator,
    reportViewService,
    toFilterOperator,
} from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export const PATH_TYPE_TO_OPERATORS: Map<PathType, ReportViewFilterOperator[]> = new Map([
    ["KEYWORD", ["EQUAL", "EXIST", "VALUE_STARTS_WITH"]],
    ["ONLY_STRING", ["EXIST", "MATCH", "WORD_STARTS_WITH"]],
    ["STRING", ["EQUAL", "EXIST", "MATCH", "VALUE_STARTS_WITH", "WORD_STARTS_WITH"]],
]);
const EXIST_OPERATOR = "EXIST";
const DEFAULT_OPERATOR = EXIST_OPERATOR;
const OPERATOR_TO_TRANSLATION_KEY_MAP: Record<ReportViewFilterOperator, string> = {
    EQUAL: "CreateReportView.form.operator.equal",
    EXIST: "CreateReportView.form.operator.exist",
    MATCH: "CreateReportView.form.operator.match",
    VALUE_STARTS_WITH: "CreateReportView.form.operator.valueStartsWith",
    WORD_STARTS_WITH: "CreateReportView.form.operator.wordStartsWith",
};

export function filterPaths(options: SelectSearchOption[], searchInput: string): SelectSearchOption[] {
    const lowered = searchInput.toLowerCase();

    return options.filter(
        (each) =>
            each.value.toString().toLowerCase().includes(lowered) || each.name.toLocaleLowerCase().includes(lowered)
    );
}

function createFilterPathsFunction(options: SelectSearchOption[]): (searchInput: string) => SelectSearchOption[] {
    return (searchInput: string) => filterPaths(options, searchInput);
}

export interface SearchInputData {
    index: number;
    condition: string;
    name: string;
    value: string;
    operator: ReportViewFilterOperator;
    path_type: string;
}

export interface PathSelection {
    available: SelectSearchOption[];
}

function sortAvailable(available: SelectSearchOption[]): SelectSearchOption[] {
    return available.sort((first, second) => first.name.localeCompare(second.name));
}

interface Props {
    maximumSelectedCount: number;
    paths: ReportPath[];
    t: TFunction;
    theme: typeof defaultColor | typeof tenantColor;
    translatePath: (path: string) => string;
    create: boolean;
    filters: AdvanceSerchFilter[];
    conditionType: string;
}

interface State {
    pathSelection: PathSelection;
    product: string;
    advanceSearchInput: AdvanceSerchFilter[];
}

const defaultSelectedSearchData: AdvanceSerchFilter = {
    index: 0,
    condition: "Where",
    name: "",
    value: "",
    operator: "MATCH",
    path_type: "",
};

export function createProductIdToPathMap(paths: ReportPath[]): Map<string, string[]> {
    return paths
        .map((each) => each.productIds.map((productId) => [productId.toString(), each.path]))
        .flat()
        .reduce<Map<string, string[]>>((accruing: Map<string, string[]>, [productId, path]: [string, string]) => {
            const paths =
                accruing.get(productId) ??
                (() => {
                    const productPaths: string[] = [];
                    accruing.set(productId, productPaths);
                    return productPaths;
                })();
            paths.push(path);
            return accruing;
        }, new Map<string, string[]>());
}

const NONE_PRODUCT_ID = "none";
const ALL_PRODUCT_ID = "all";

function createTooltip(visibleText: string): JSX.Element {
    return (
        <Tooltip maxWidth={350} delay={[300, 0]} placement={"auto"}>
            <span>{visibleText}</span>
        </Tooltip>
    );
}

/**
 * Within reportPaths, return those that can be used as filters.
 */
function filterWithSupportedPathTypes(reportPaths: ReportPath[]): ReportPath[] {
    // "Pretty stupid so fix this somehow. Maybe change definition of pathType in ReportPath." - Väinö
    // "Nah, this will do for now. The original author may feel free to return to this if he ever finds this again." - Mikko
    // "Challenge accepted." - Väinö
    const types: PathType[] = ["STRING", "ONLY_STRING", "KEYWORD"];
    const strings: Set<string> = new Set(types.map((each) => each.toString()));
    return reportPaths.filter((each) => strings.has(each.pathType));
}

export default class AdvanceSearchView extends React.Component<Props, State> {
    private readonly commonPaths: SelectSearchOption[];
    private readonly productIdToPaths: Map<string, string[]>;
    private selectSearchComponent: React.RefObject<React.Component>;

    constructor(props: Props) {
        super(props);
        const filters = props.filters.map((each: AdvanceSerchFilter) => ({
            name: this.props.translatePath(each.name),
            value: each.name,
        }));

        this.commonPaths = [
            ...filters,
            { name: props.t("ErasureReportsTable.reportVerification"), value: Path.VERIFIED },
            { name: props.t("ErasureReportsTable.productName"), value: Path.PRODUCT_ID },
        ];
        this.productIdToPaths = createProductIdToPathMap(props.paths);
        const pathTypeName = filterWithSupportedPathTypes(props.paths);
        const selectedPaths = filters.reduce((accrued, each) => accrued.add(each.value), new Set<string>());
        this.state = {
            pathSelection: {
                available: sortAvailable(
                    pathTypeName
                        .map((each) => each.path)
                        .filter((each) => !selectedPaths.has(each))
                        .map((path) => ({
                            name: props.translatePath(path),
                            value: path,
                        }))
                ),
            },
            product: ALL_PRODUCT_ID,
            advanceSearchInput: [defaultSelectedSearchData],
        };
        this.selectSearchComponent = React.createRef();
    }

    public setAdvanceSearchInput(advanceSearchInput: AdvanceSerchFilter[]): void {
        this.setState({
            advanceSearchInput,
        });
    }

    createFilterWithDefaultValues = (index: number, path: string, name: string) => {
        const reportPath = reportViewService.getPaths().find((each) => each.path === path);
        if (reportPath == null) {
            throw Error(`Can't find ReportPath for path ${path}`);
        }
        const path_type = toPathType(reportPath.pathType);
        const operators = PATH_TYPE_TO_OPERATORS.get(path_type);
        if (operators == null) {
            throw Error(`Received null operators for path ${path} and its path type ${path_type}`);
        }
        const operator = operators.find((each) => each === "MATCH") ?? operators[0];
        const advanceSearchInput = [...this.state.advanceSearchInput];
        const item = { ...advanceSearchInput[index] };
        item.name = path;
        item.value = name;
        item.path_type = path_type;
        item.operator = operator;
        advanceSearchInput[index] = item;
        this.setState({ advanceSearchInput });
    };

    onFilterOperatorChange = (path: string, operator: ReportViewFilterOperator) => {
        usageStatisticsService.sendEvent({
            category: Category.REPORT_VIEW,
            action: Action.CHANGE_FILTER_OPERATOR,
            label: operator.toString(),
        });
        this.state.advanceSearchInput.map((each) => ({
            ...each,
            operator: each.name === path ? operator : each.operator,
        }));
    };

    createOperatorSelect = (
        filter: AdvanceSerchFilter,
        onChange: (path: string, operator: ReportViewFilterOperator) => void
    ): JSX.Element => {
        const pathType = toPathType(reportViewService.getPaths().find((each) => each.path === filter.name)?.pathType);
        if (!PATH_TYPE_TO_OPERATORS.has(pathType)) {
            logger.error(`No operators found for path type ${pathType}.`);
        }
        const operators = PATH_TYPE_TO_OPERATORS.get(pathType) ?? [DEFAULT_OPERATOR];
        const options = operators.map((each) => {
            const key = OPERATOR_TO_TRANSLATION_KEY_MAP[each];
            return (
                <option key={each} value={each}>
                    {this.props.t(key)}
                </option>
            );
        });
        // Obviously operator should always be included but if it isn't, at least
        // the UI isn't bricked and the end user has a way to move forward and try
        // to fix the situation. Even if she's not immediately notified and
        // doesn't notice for some time.
        const selectedValue = operators.find((each) => each === filter.operator) || DEFAULT_OPERATOR;

        const onChangeWrapper = (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(filter.name, toFilterOperator(event.currentTarget.value));
        };

        return (
            <select
                className={form.select}
                value={selectedValue}
                onChange={onChangeWrapper}
                data-testid={
                    testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.operatorSelect.itself
                }
            >
                {options}
            </select>
        );
    };

    addRow = () => {
        const newAdded: AdvanceSerchFilter = {
            index: Math.max(...this.state.advanceSearchInput.map((item) => item.index)) + 1,
            condition: defaultSelectedSearchData.condition,
            name: defaultSelectedSearchData.name,
            value: defaultSelectedSearchData.value,
            operator: defaultSelectedSearchData.operator,
            path_type: defaultSelectedSearchData.path_type,
        };
        const AdvanceSearchData = this.state.advanceSearchInput.concat([newAdded]);
        this.setAdvanceSearchInput(AdvanceSearchData);
    };

    removeRow = (index: number) => {
        if (this.state.advanceSearchInput.length > 1) {
            this.setAdvanceSearchInput(this.state.advanceSearchInput.filter((each) => each.index !== index));
        } else {
            this.setAdvanceSearchInput([defaultSelectedSearchData]);
        }
    };

    render(): JSX.Element {
        const filterAvailableByProduct = (): SelectSearchOption[] => {
            const deriveAcceptablePaths = (): string[] => {
                const generateCommonPaths = () => this.commonPaths.map((each) => each.value.toString());
                switch (this.state.product) {
                    case NONE_PRODUCT_ID:
                        return generateCommonPaths();
                    case ALL_PRODUCT_ID:
                        return [...Array.from(this.productIdToPaths.values()).flat(), ...generateCommonPaths()];
                    default:
                        return (() => {
                            const paths = this.productIdToPaths.get(this.state.product);
                            if (paths == null) {
                                throw new Error("It should be impossible for acceptablePaths to be null");
                            }
                            return paths;
                        })();
                }
            };
            const acceptablePaths = new Set(deriveAcceptablePaths());
            return this.state.pathSelection.available.filter((each) => acceptablePaths.has(each.value.toString()));
        };

        const createTableContent = () => {
            if (this.state.advanceSearchInput.length > 0) {
                return this.state.advanceSearchInput.map((filterInput) => [
                    <div key={0} className={style.whereWrapper}>
                        <div className={style.where}>{"Where"}</div>
                    </div>,
                    <div key={1} className={style.selectParent}>
                        <SelectSearch
                            ref={this.selectSearchComponent}
                            closeOnSelect={true}
                            onChange={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.ADVANCE_SEARCH,
                                    action: Action.ADD_FILTER,
                                });
                            }}
                            filterOptions={createFilterPathsFunction}
                            options={filterAvailableByProduct()}
                            renderOption={(domProps: DomProps, option: SelectedOption): React.ReactNode => {
                                // Typing system claims that this is SelectedOption even thou it seems to be
                                // SelectedOptionValue. The latter contains the needed "name". So it seems we're once
                                // again forced to go around TypeScript's typing.
                                const { name } = option as unknown as { name: string };
                                return (
                                    <button
                                        type={"button"}
                                        className={"src-components-reports-create-report-view-modal__select__option"}
                                        tabIndex={parseInt(domProps.tabIndex, 10)}
                                        value={option.value}
                                        onClick={() => {
                                            this.createFilterWithDefaultValues(filterInput.index, option.value, name);
                                        }}
                                    >
                                        {createTooltip(name)}
                                    </button>
                                );
                            }}
                            search={true}
                            placeholder={
                                this.state.advanceSearchInput[filterInput.index].value != ""
                                    ? this.state.advanceSearchInput[filterInput.index].value
                                    : this.props.t("ErasureReportsTable.SelectOrTypeField")
                            }
                            className={style.select}
                        />
                    </div>,
                    // <div key={"opertor" + filterInput.index}>
                    //     {createOperatorSelect(filter, onFilterOperatorChange)}
                    // </div>,

                    <div key={2}>
                        {this.state.advanceSearchInput[filterInput.index].value != "" ? (
                            this.createOperatorSelect(filterInput, this.onFilterOperatorChange)
                        ) : (
                            <select>
                                <option>MATCH</option>
                                <option>EQUAL</option>
                                <option>EXIST</option>
                                <option>VALUE_STARTS_WITH</option>
                                <option>WORD_STARTS_WITH</option>
                            </select>
                        )}
                    </div>,
                    <div key={3}>
                        <input className={style.input}></input>
                    </div>,
                    <button key={4} type="button" onClick={() => this.removeRow(filterInput.index)}>
                        {this.state.advanceSearchInput.length > 1 && (
                            <Delete strokeColor={this.props.theme.errorIconColor} />
                        )}
                    </button>,
                ]);
            } else {
                return [
                    [
                        <div key={0}></div>,
                        <div className={style.emptyMessage} key={1}>
                            {this.props.t("CreateReportView.form.emptyStateMessage")}
                        </div>,
                        <div key={2}></div>,
                    ],
                ];
            }
        };

        return (
            <div>
                <div>
                    <StaticTable headers={[]} cells={createTableContent()} tableClass={style.table} />
                    <div className={style.actions}>
                        <button className={style.buttons} onClick={this.addRow}>
                            <AddNewIcon className={style.addNewIcon} color={this.props.theme.iconFillColor} />
                            <b className={style.where}>{this.props.t("ErasureReportsTable.addCondition")}</b>
                        </button>
                        <div className={style.actionsChild} />
                        <button className={style.buttons}>
                            <AddNewIcon className={style.addNewIcon} color={this.props.theme.iconFillColor} />
                            <b className={style.where}>{this.props.t("ErasureReportsTable.addGroup")}</b>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(): void {
        if (this.selectSearchComponent.current != null) {
            let selectSearchElement: HTMLElement | null;
            let searchInput: HTMLInputElement | null;
            try {
                // This is hacky but so far I haven't found a typesafe way to
                // get access to the underlying HTML element. It works at
                // least for now. I.e. with React ^16.13.1. Can't let the
                // entire component fail if there's an issue with setting an
                // HTML test ID so we'll catch all exceptions, print the
                // logger.error, and move on. Another option would be to not
                // do this at all and let test automation handle finding the
                // input on that side. At least for now we'll try to do our
                // part and lessen the work in test automation.
                selectSearchElement = this.selectSearchComponent.current as unknown as HTMLElement;
                searchInput = selectSearchElement.querySelector("input");
            } catch (error) {
                logger.error(
                    "Failed to find native input element when trying to set test ID for filter search input.",
                    error
                );
                return;
            }
            if (selectSearchElement != null) {
                selectSearchElement.dataset.testid = testIds.common.searchSelectContainer.itself;
            }
            if (searchInput != null) {
                searchInput.dataset.testid = testIds.common.searchSelectContainer.searchInput.itself;
            }
        }
    }
}
