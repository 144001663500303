import { details } from "domain/endpoint";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { logger } from "utils/logging";

/**
 * Handles interactions with authentication API.
 */
class AuthenticationService {
    public async authenticate(username: string, password: string): Promise<details> {
        try {
            const requestBody = {
                username,
                password,
            };

            const response: details = await apiGatewayService.invokeApi("/authentication", "post", requestBody, {
                refreshSession: false,
                apiType: ApiType.GLOBAL_STAN,
            });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async ssoAuthenticate(authorizationCode: string): Promise<details> {
        try {
            const response: details = await apiGatewayService.invokeApi(
                "/authentication/sso?code=" + authorizationCode,
                "get",
                undefined,
                {
                    refreshSession: false,
                    apiType: ApiType.GLOBAL_STAN,
                }
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async requestPasswordReset(email: string): Promise<void> {
        return apiGatewayService.invokeApi("/forgot-password?username=" + encodeURIComponent(email), "get", null);
    }

    public passwordReset(
        email: string,
        verificationCode: string | null,
        temporaryPassword: string | null,
        newPassword: string
    ): Promise<void> {
        const body: { [key: string]: string } = {
            username: email,
            password: newPassword,
        };
        if (verificationCode != null) {
            body["code"] = verificationCode;
        } else if (temporaryPassword != null && temporaryPassword != "") {
            body["temporary_password"] = temporaryPassword;
        }
        return apiGatewayService.invokeApi(`/reset-password`, "post", body, { refreshSession: false });
    }

    public updatePassword(oldPassword: string, updatePassword: string): Promise<void> {
        const body: { [key: string]: string } = {
            old_password: oldPassword,
            new_password: updatePassword,
        };
        return apiGatewayService.invokeApi("/api/v1/users/update-passwords", "post", body, {
            refreshSession: false,
            apiType: ApiType.LAUREL,
            emptyResponse: true,
        });
    }

    public async logout() {
        try {
            await apiGatewayService.invokeApi("/logout", "get");
        } catch (e) {
            logger.error("Logout failed: " + e);
        }
    }
}

export const authenticationService = new AuthenticationService();
