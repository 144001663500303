import classNames from "classnames";
import { ErrorMessage, FormikErrors } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { AddIcon } from "components/icons/AddIcon";
import { BundlesTenantTypes, CorporateBundle, ProcessorBundle } from "components/licenses/bundles";
import { getDeliveryTypes } from "components/licenses/common";
import style from "components/licenses/delivery-history/add-license-delivery.scss";
import GlobalDateAmountComponent from "components/licenses/GlobalExpirationDateAndAmount";
import { FormValues, generateSelectableLicenses } from "components/tenants/add-tenant/AddTenantForm";
import Tooltip from "components/tooltip/Tooltip";
import { deliveryTypes, MAX_DATE } from "domain/licenses";
import { LicensingModel, TenantType } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    hasSubTenantCookie,
    isCurrentTenantInternal,
    isUserParentInternal,
} from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import form from "styles/form.scss";
import { getMaxDate, hasOnlyNumbers, isStringNotBlank } from "utils/commonFunctions";
import { formatIsoDate, MONTHS, toUtcDateString } from "utils/format";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

export interface DeliveryForm {
    deliveryType: string;
    caseNumber: string;
    opportunityId: string;
    notes: string;
    amount: null | number;
    expirationDate: string;
    tenantType?: TenantType | string;
    bundle?: string;
}

export interface License {
    index: number;
    licenseType: string;
    available: number;
    licensesToAdd: number;
    totalOfLicenses: number;
    expirationDate: string;
    productName: string;
    parentAvailableAmount: number;
    parentLicenseExpirationDate: string | null;
    assigned?: number;
}

export const validateParameters = (caseNumber: string | undefined, opportunityId: string | undefined) => {
    if (!isUserParentInternal()) {
        // Input boxes for the case number and opportunity ID are hidden when user is not root,
        // so returning false means that functionalities in the dialog are not restricted by the parameters.
        return false;
    }
    if (!hasSubTenantCookie()) {
        return true;
    }

    return isStringNotBlank(caseNumber) || isStringNotBlank(opportunityId);
};

// TODO BCC-3359 consider having it only in delivery creation form
export const isConfirmationDialogVisible = (deliveryType: string, opportunityId: string, caseNumber: string) => {
    return (
        deliveryType.trim() !== "" &&
        deliveryType !== DEFAULT_SELECT_DELIVERY_TYPE &&
        (validateParameters(caseNumber, opportunityId)
            ? isStringNotBlank(opportunityId) &&
              opportunityId.length <= MAX_OPPORTUNITY_ID_LENGTH &&
              hasOnlyNumbers(caseNumber) &&
              caseNumber.length <= MAX_CASE_NUMBER_LENGTH
            : true)
    );
};

export const addMoreLicenses = (color: string, linecolor: string, text: string) => {
    return (
        <>
            <AddIcon color={color} linecolor={linecolor} />
            <span className={style.addMoreButton}>{text}</span>
        </>
    );
};

export function createDefaultSelectedLicense(t: TFunction) {
    return {
        licenseType: "default",
        licensesToAdd: 0,
        productName: t("DeliveryHistory.addLicenseDelivery.table.default"),
        totalOfLicenses: 0,
        expirationDate: formatIsoDate(new Date()),
        index: 0,
        available: 0,
        parentAvailableAmount: 0,
        parentLicenseExpirationDate: isCurrentTenantInternal() ? toUtcDateString(MAX_DATE) : null,
    };
}

export const MAX_CASE_NUMBER_LENGTH = 8;
export const MAX_OPPORTUNITY_ID_LENGTH = 32;
export const DESCRIPTION_MAX_LENGTH = 128;
const DEFAULT_SELECT_DELIVERY_TYPE = "delivery_type";

interface Props {
    availableLicenses: License[];
    ownedLicenses: License[];
    preselectedFromBundle?: boolean;
    deliveryForm: DeliveryForm;
    errors: FormikErrors<DeliveryForm> | FormikErrors<FormValues>;
    handleChange: (
        e:
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    setSelectedLicenses: (licenses: License[]) => void;
    selectedLicenses: License[];
    inTenantCreation: boolean;
    licensingModel: LicensingModel;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const DeliveryFormContent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const DELIVERY_TYPES = getDeliveryTypes(props.inTenantCreation);
    const DEFAULT_SELECTION_VALUE = "DEFAULT";
    const [bundleOption, setBundleOption] = React.useState(DEFAULT_SELECTION_VALUE);
    const formErrors = props.errors as FormikErrors<DeliveryForm>;
    function lastDate() {
        const maxDate = getMaxDate();
        return maxDate.getDate().toString() + " " + MONTHS[maxDate.getMonth()] + " " + maxDate.getFullYear();
    }
    function createElementId(id: string): string {
        return props.inTenantCreation ? "deliveryDetails." + id : id;
    }
    return (
        <>
            <div className={form.formFields}>
                <label htmlFor="deliveryType" className={classNames(form.label, style.wideWidth)}>
                    {t("DeliveryHistory.addLicenseDelivery.deliveryType.label")}
                </label>
                <select
                    id={createElementId("deliveryType")}
                    className={classNames(form.select, style.selectArrow, form.fixedWidthInput, {
                        [form.inputError]: formErrors.deliveryType,
                    })}
                    onChange={props.handleChange}
                    value={props.deliveryForm.deliveryType}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.LICENSE_DELIVERY,
                            action: Action.CHANGE_DELIVERY_TYPE,
                        });
                    }}
                    disabled={props.inTenantCreation}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.deliveryTypeSelect.itself
                    }
                >
                    {!props.inTenantCreation && (
                        <option key={DEFAULT_SELECT_DELIVERY_TYPE} value={DEFAULT_SELECT_DELIVERY_TYPE}>
                            {t("DeliveryHistory.addLicenseDelivery.deliveryType.type.default")}
                        </option>
                    )}
                    {deliveryTypes.map((value) => (
                        <option key={value} value={value}>
                            {DELIVERY_TYPES.get(value)}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.deliveryTypeSelect.errorLabel
                    }
                >
                    <ErrorMessage name={createElementId("deliveryType")} />
                </div>
            </div>
            {props.preselectedFromBundle ? (
                <>
                    <div className={form.formFields}>
                        <label htmlFor="tenantType" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.tenantTypeLabel")}
                        </label>
                        <select
                            id={createElementId("tenantType")}
                            className={classNames(form.select, style.selectArrow, form.fixedWidthInput)}
                            onChange={(event) => {
                                props.handleChange(event);
                                props.setSelectedLicenses([createDefaultSelectedLicense(t)]);
                                setBundleOption(DEFAULT_SELECTION_VALUE);
                            }}
                            value={props.deliveryForm.tenantType}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.tenantTypeSelect.itself
                            }
                        >
                            <option key={"default tenant type"} value={DEFAULT_SELECTION_VALUE}>
                                {t("DeliveryHistory.addLicenseDelivery.defaultTenantType")}
                            </option>
                            {Object.keys(BundlesTenantTypes).map((value) => (
                                <option key={value} value={value}>
                                    {generateTenantTypeToTranslationMap(t).get(value as TenantType)}
                                </option>
                            ))}
                        </select>
                    </div>
                    {!props.inTenantCreation ? (
                        <div className={form.formFields}>
                            <label htmlFor="bundle" className={classNames(form.label, style.wideWidth)}>
                                {t("AddCustomerForm.blanccoBundles")}
                            </label>
                            <select
                                id={createElementId("bundle")}
                                className={classNames(form.select, style.selectArrow, form.fixedWidthInput)}
                                onChange={(event) => {
                                    props.handleChange(event);
                                    const selectedBundle = event.target.value;
                                    setBundleOption(selectedBundle);
                                    if (selectedBundle === DEFAULT_SELECTION_VALUE) {
                                        props.setSelectedLicenses([createDefaultSelectedLicense(t)]);
                                        return;
                                    }
                                    const preselectedLicenses: License[] = [];
                                    const licensesPerTier: {
                                        productName: string;
                                        productId: string;
                                    }[] = [];
                                    generateSelectableLicenses(licensesPerTier, selectedBundle, props.licensingModel);
                                    licensesPerTier.forEach((eachLicenseInTier) => {
                                        const foundOwnedLicense = props.ownedLicenses.find(
                                            (each) => each.licenseType == eachLicenseInTier.productId
                                        );
                                        if (foundOwnedLicense) {
                                            if (props.deliveryForm.amount) {
                                                foundOwnedLicense.licensesToAdd = props.deliveryForm.amount;
                                            }
                                            if (props.deliveryForm.expirationDate) {
                                                foundOwnedLicense.expirationDate = props.deliveryForm.expirationDate;
                                            }
                                            preselectedLicenses.push(foundOwnedLicense);
                                        } else {
                                            const foundInAvailableLicenses = props.availableLicenses.find(
                                                (each) => each.licenseType == eachLicenseInTier.productId
                                            );
                                            if (foundInAvailableLicenses) {
                                                if (props.deliveryForm.amount) {
                                                    foundInAvailableLicenses.licensesToAdd = props.deliveryForm.amount;
                                                }
                                                if (props.deliveryForm.expirationDate) {
                                                    foundInAvailableLicenses.expirationDate =
                                                        props.deliveryForm.expirationDate;
                                                }
                                                preselectedLicenses.push(foundInAvailableLicenses);
                                            }
                                        }
                                    });
                                    props.setSelectedLicenses(preselectedLicenses);
                                }}
                                value={bundleOption}
                                data-testid={
                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog.tenantTypeSelect
                                        .itself
                                }
                            >
                                <option key={"default bundle type"} value={DEFAULT_SELECTION_VALUE}>
                                    {t("DeliveryHistory.addLicenseDelivery.defaultBundle")}
                                </option>
                                {props.deliveryForm.tenantType === DEFAULT_SELECTION_VALUE
                                    ? null
                                    : props.deliveryForm.tenantType == "CORPORATE"
                                    ? Object.keys(CorporateBundle).map((value) => (
                                          <option key={value} value={value}>
                                              {generateTierToTranslationMap(t).get(value)}
                                          </option>
                                      ))
                                    : Object.keys(ProcessorBundle).map((value) => (
                                          <option key={value} value={value}>
                                              {generateTierToTranslationMap(t).get(value)}
                                          </option>
                                      ))}
                            </select>
                        </div>
                    ) : null}
                </>
            ) : null}
            {isUserParentInternal() && (
                <>
                    <div className={form.formFields}>
                        {hasSubTenantCookie() ? <span className={form.optional}>{t("Common.optional")}</span> : null}
                        <label htmlFor="opportunityId" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.opportunityId")}
                        </label>
                        <input
                            id={createElementId("opportunityId")}
                            className={classNames(form.input, form.fixedWidthInput, {
                                [form.inputError]: formErrors.opportunityId,
                            })}
                            onChange={(event) => props.handleChange(event)}
                            value={props.deliveryForm.opportunityId}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput.itself
                            }
                        />

                        <div
                            className={form.error}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput
                                    .errorLabel
                            }
                        >
                            <ErrorMessage name={createElementId("opportunityId")} />
                        </div>
                    </div>

                    <div className={form.formFields}>
                        {hasSubTenantCookie() ? <span className={form.optional}>{t("Common.optional")}</span> : null}
                        <label htmlFor="caseNumber" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.caseNumber")}
                        </label>

                        <input
                            id={createElementId("caseNumber")}
                            className={classNames(form.input, form.fixedWidthInput, {
                                [form.inputError]: formErrors.caseNumber,
                            })}
                            onChange={props.handleChange}
                            value={props.deliveryForm.caseNumber}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.caseNumberInput.itself
                            }
                        />
                        <div
                            className={form.error}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.caseNumberInput.errorLabel
                            }
                        >
                            <ErrorMessage name={createElementId("caseNumber")} />
                        </div>
                    </div>
                </>
            )}

            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={classNames(form.label, style.wideWidth)}>
                        {t("DeliveryHistory.addLicenseDelivery.notes")}
                    </label>
                </div>
                <div className={classNames(style.gridRows, form.optional)}>
                    <textarea
                        id={createElementId("notes")}
                        className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                        onChange={(event) => props.handleChange(event)}
                        data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.notesTextArea.itself}
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        value={props.deliveryForm.notes}
                    />
                    <span className={form.notes}>
                        {t("DeliveryHistory.addLicenseDelivery.validation.notes", {
                            remainingCharacters: (DESCRIPTION_MAX_LENGTH - props.deliveryForm.notes.length).toString(),
                            maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <Tooltip content={t("DeliveryHistory.addLicenseDelivery.warning")}>
                    <div className={style.warningIcon} tabIndex={0}>
                        <img src={alert} alt={t("AltText.alertIcon")} />
                    </div>
                </Tooltip>
            </div>
            <GlobalDateAmountComponent
                showAmountType={false}
                amount={props.deliveryForm.amount}
                expirationDate={props.deliveryForm.expirationDate}
                onAmountChange={(value) => {
                    props.deliveryForm["amount"] = parseInt(value);
                    props.setSelectedLicenses(
                        props.selectedLicenses.map((each) => ({
                            ...each,
                            licensesToAdd: parseInt(value),
                            totalOfLicenses: isNaN(parseInt(value)) ? each.available : each.available + parseInt(value),
                        }))
                    );
                }}
                dateString={lastDate()}
                onDateChange={(value) => {
                    props.deliveryForm["expirationDate"] = value;
                    props.setSelectedLicenses(
                        props.selectedLicenses.map((each) => ({
                            ...each,
                            expirationDate: value,
                        }))
                    );
                }}
            />
        </>
    );
};

export default connector(DeliveryFormContent);
