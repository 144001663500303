import classNames from "classnames";
import { useFeature } from "flagged";
import { ErrorMessage, Field, Formik, FormikErrors, FormikProps } from "formik";
import { TFunction } from "i18next";
import * as React from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DatePicker } from "rsuite";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { number, object, string } from "yup";

import style from "./add-tenant.scss";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import QuestionMark from "components/icons/QuestionMark";
import {
    BUNDLES_ADD_ONS,
    CORE_TIER,
    CorporateBundle,
    ENTERPRISE_TIER,
    ESSENTIALS_TIER,
    MANAGEMENT_CONSOLE_LICENSE,
    POWER_TIER,
    PRO_SCALE_TIER,
    PRO_TIER,
    ProcessorBundle,
} from "components/licenses/bundles";
import {
    BLANCCO_TOKEN_ID,
    createLicenseTypes,
    FEATURE_LICENSES,
    isAmountAvailable,
    isExpirationDateValid,
    NON_DELIVERABLE_FEATURE_LICENSES,
    showOnlyTokenLicense,
} from "components/licenses/common";
import DeliveryFormContent, {
    DeliveryForm,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/DeliveryFormContent";
import LicenseSelectionForm from "components/licenses/delivery-history/LicenseSelectionForm";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import PricingTierView from "components/tenants/PricingTierView";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { getCountries } from "domain/countries";
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import { EmsConfiguration, LicenseData, MAX_DATE, MIN_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, OldLicensingModelTier, TENANT_TYPES, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { CheckEmailAvailability } from "domain/users";
import { FLAG_BLANCCO_TOKEN } from "services/feature/FeatureFlagService";
import { licenseService } from "services/licenses/LicenseService";
import {
    getCurrentTenantDetails,
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    showAllowedTierList,
} from "services/tenants/tenantCookieService";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { formatDateWithoutTime, formatIsoDate, MONTHS } from "utils/format";

import testIds from "testIds.json";

interface Props {
    submitEventHandler: (values: FormValues, selectedLicenses: License[]) => void;
    closeHandler: () => void;
    regionsList: string[];
    theme: typeof defaultColor | typeof tenantColor;
    tenantLicenses?: LicenseData[];
    parentExpirationDate: Date | undefined;
    parentHierarchyLevel: number;
    isUserDetailsOptional: boolean;
    tenantType: TenantType;
    tenantTier: CombinedTier;
    licensingModel: LicensingModel;
}

interface CodedName {
    name: string;
    code: string;
}

export interface GeneralRequiredFormValues {
    customerName: string;
    region: string;
    country: string;
    mainContactName: string;
    email: string;
    salesforceAccountId: string;
}

export interface DeliveryDetailsFormValues {
    deliveryDetails: DeliveryForm;
}

export interface SettingsFormValues {
    ems: EmsConfiguration;
}

export interface FormValues extends GeneralRequiredFormValues, DeliveryDetailsFormValues, SettingsFormValues {
    type: TenantType;
    tier: CombinedTier;
    notes: string;
    expirationDate?: Date;
    licensingModel: LicensingModel;
}

interface FormState {
    notesCharactersLeft: number;
    formValues: FormValues;
    miniloader: boolean;
    selectedLicenses: License[];
    emsIdUseDefault: boolean;
}

function findTierLicenses(
    tierLicenses: string[],
    allLicenses: { productName: string; productId: string }[]
): { productName: string; productId: string }[] {
    const foundLicenses: { productName: string; productId: string }[] = [];
    allLicenses.forEach((eachInAllLicenses) => {
        tierLicenses.forEach((eachInTier) => {
            if (eachInTier == eachInAllLicenses.productId) {
                foundLicenses.push(eachInAllLicenses);
            }
        });
    });
    return foundLicenses;
}

function isTokenLicense(productId: string): boolean {
    return productId === BLANCCO_TOKEN_ID;
}

export function generateSelectableLicenses(
    dropdownLicenses: { productName: string; productId: string }[],
    selectedTier: CombinedTier,
    licensingModel: LicensingModel
) {
    const deliverableLicenses: { productName: string; productId: string }[] = [];
    const allAvailableLicenses = createLicenseTypes(true);
    const tokenInUse = showOnlyTokenLicense(licensingModel);
    if (licensingModel === LicensingModel.OLD_MODEL) {
        dropdownLicenses.push(
            ...allAvailableLicenses.filter((element) => !NON_DELIVERABLE_FEATURE_LICENSES.includes(element.productId))
        );
        return;
    }
    allAvailableLicenses.forEach((eachInAllLicenses) => {
        if (!tokenInUse && isTokenLicense(eachInAllLicenses.productId)) {
            return;
        }
        if (!FEATURE_LICENSES.includes(eachInAllLicenses.productId)) {
            deliverableLicenses.push(eachInAllLicenses);
        }
    });
    const tierLicenses = deduceTierLicenses(selectedTier, false);
    if (tierLicenses) {
        const filteredTierLicenses = findTierLicenses(tierLicenses, deliverableLicenses);
        if (tokenInUse) {
            dropdownLicenses.push(...extractAddOns(filteredTierLicenses));
            dropdownLicenses.push(...deliverableLicenses.filter((each) => isTokenLicense(each.productId)));
        } else {
            dropdownLicenses.push(...filteredTierLicenses);
        }
    }
}

export function deduceTierLicenses(tier: CombinedTier, withoutAddOns: boolean) {
    const tierToLicenses = new Map<CombinedTier, string[]>([
        [CorporateBundle.ESSENTIALS, Object.values(ESSENTIALS_TIER)],
        [CorporateBundle.POWER, Object.values(POWER_TIER)],
        [CorporateBundle.ENTERPRISE, Object.values(ENTERPRISE_TIER)],
        [ProcessorBundle.CORE, Object.values(CORE_TIER)],
        [ProcessorBundle.PRO, Object.values(PRO_TIER)],
        [ProcessorBundle.PRO_SCALE, Object.values(PRO_SCALE_TIER)],
    ]);
    if (withoutAddOns) {
        return tierToLicenses.get(tier)?.filter((each) => !BUNDLES_ADD_ONS.includes(each));
    }
    return tierToLicenses.get(tier);
}

function deduceDefaultTenantOptions(
    parentTier: CombinedTier,
    parentType?: TenantType
): { defaultType: TenantType; defaultTier: CombinedTier; defaultModel: LicensingModel } {
    if (parentType == undefined || parentType === "INTERNAL" || parentType === "CHANNEL") {
        return {
            defaultType: "CORPORATE",
            defaultModel: LicensingModel.BUNDLE,
            defaultTier: CorporateBundle.ESSENTIALS,
        };
    }
    if (parentType === "CUSTOMER") {
        return {
            defaultType: "CUSTOMER",
            defaultTier: OldLicensingModelTier.BASIC,
            defaultModel: LicensingModel.OLD_MODEL,
        };
    }
    return {
        defaultType: parentType,
        defaultModel: LicensingModel.BUNDLE,
        defaultTier: parentTier,
    };
}

function extractAddOns(availableLicenses: { productName: string; productId: string }[]) {
    return availableLicenses.filter((each) => isProductAddOn(each.productId));
}

function isProductAddOn(productId: string) {
    return BUNDLES_ADD_ONS.includes(productId);
}

export default class AddTenantForm extends React.Component<Props, FormState> {
    private regions: Array<CodedName>;
    private countries: Array<CodedName>;
    private selectedExpirationDate?: Date;
    private defaultValues = deduceDefaultTenantOptions(this.props.tenantTier, this.props.tenantType);
    constructor(props: Props) {
        super(props);
        this.state = {
            miniloader: false,
            notesCharactersLeft: NOTES_MAX_LENGTH,
            emsIdUseDefault: true,
            formValues: {
                customerName: "",
                type: this.defaultValues.defaultType,
                region: this.currentTenantDetails.region || "",
                tier: this.defaultValues.defaultTier,
                country: "",
                notes: "",
                mainContactName: "",
                email: "",
                salesforceAccountId: "",
                ems: {
                    emsId: "",
                    hlEntitlements: false,
                    slEntitlements: false,
                    availableSlActivations: 0,
                    usedSlActivations: 0,
                    lastSynced: "",
                },
                deliveryDetails: {
                    deliveryType: "",
                    caseNumber: "",
                    opportunityId: "",
                    notes: "",
                    amount: null,
                    expirationDate: "",
                },
                licensingModel: this.defaultValues.defaultModel,
            },
            selectedLicenses: this.populateWithPredefinedLicenses(
                this.defaultValues.defaultModel,
                this.defaultValues.defaultTier
            ),
        };
    }

    public setMiniloader(miniloader: boolean): void {
        this.setState({
            miniloader,
        });
    }

    public setSelectedLicenses(selectedLicenses: License[]): void {
        this.setState({
            selectedLicenses,
        });
    }
    public currentTenantDetails = getCurrentTenantDetails();
    private generateDropdownLicenses(
        selectedTier: CombinedTier,
        dropdownLicenses: { productName: string; productId: string }[],
        licensingModel: LicensingModel
    ) {
        generateSelectableLicenses(dropdownLicenses, selectedTier, licensingModel);
    }

    private manageLicenseList(
        eachInAllLicenses: { productId: string; productName: string },
        predefinedLicenses: License[]
    ) {
        const parentLicense = this.props.tenantLicenses?.find(
            (eachParentLicense) => eachParentLicense.licenseType == eachInAllLicenses.productId
        );
        if (parentLicense) {
            predefinedLicenses.push(
                this.createLicense(
                    parentLicense.type || eachInAllLicenses.productId,
                    0,
                    eachInAllLicenses.productName,
                    parentLicense.expirationDate,
                    predefinedLicenses.length,
                    parentLicense.available
                )
            );
        }
    }

    private createLicense(
        licenseType: string,
        available: number,
        productName: string,
        expirationDate: string,
        index: number,
        parentAvailableAmount: number
    ): License {
        return {
            index: index,
            licenseType: licenseType,
            available: available,
            licensesToAdd: 1,
            totalOfLicenses: available + 1,
            expirationDate: expirationDate,
            productName: productName,
            parentAvailableAmount: parentAvailableAmount,
            parentLicenseExpirationDate: null,
        };
    }

    private generateRegions(t: TFunction): Array<CodedName> {
        const awsRegions = [
            { name: t("AddCustomerForm.UsEastNorthVirginia"), code: "us-east-1" },
            { name: t("AddCustomerForm.UsEastOhio"), code: "us-east-2" },
            { name: t("AddCustomerForm.UsWestOregon"), code: "us-west-2" },
            { name: t("AddCustomerForm.UsWestNorthernCalifornia"), code: "us-west-1" },
            { name: t("AddCustomerForm.SouthAmericaSaoPaulo"), code: "sa-east-1" },
            { name: t("AddCustomerForm.EuropeFrankfurt"), code: "eu-central-1" },
            { name: t("AddCustomerForm.EuropeLondon"), code: "eu-west-2" },
            { name: t("AddCustomerForm.EuropeParis"), code: "eu-west-3" },
            { name: t("AddCustomerForm.MiddleEastBahrain"), code: "me-south-1" },
            { name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" },
            { name: t("AddCustomerForm.EuropeMilan"), code: "eu-south-1" },
            { name: t("AddCustomerForm.EuropeStockholm"), code: "eu-north-1" },
            { name: t("AddCustomerForm.AwsAfricaCapeTown"), code: "af-south-1" },
            { name: t("AddCustomerForm.MainlandChinaBeijing"), code: "cn-north-1" },
            { name: t("AddCustomerForm.MainlandChinaNingxia"), code: "cn-northwest-1" },
            { name: t("AddCustomerForm.AsiaPacificSydney"), code: "ap-southeast-2" },
            { name: t("AddCustomerForm.AsiaPacificOsaka"), code: "ap-northeast-3" },
            { name: t("AddCustomerForm.AsiaPacificTokyo"), code: "ap-northeast-1" },
            { name: t("AddCustomerForm.AsiaPacificMumbai"), code: "ap-south-1" },
            { name: t("AddCustomerForm.AsiaPacificSeoul"), code: "ap-northeast-2" },
            { name: t("AddCustomerForm.AsiaPacificHongKong"), code: "ap-east-1" },
        ];

        if (this.props.regionsList) {
            this.regions = awsRegions.filter((region) =>
                this.props.regionsList.some((reg: string) => region.code === reg)
            );
            this.regions.sort((a, b) => (a.name > b.name ? 1 : -1));
        } else {
            return [{ name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" }];
        }

        return this.regions;
    }

    private generateCountries(t: TFunction): Array<CodedName> {
        if (this.countries != null) {
            return this.countries;
        }

        const countries = getCountries(t);
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.countries = countries;
        return this.countries;
    }

    private createField(
        id: keyof EmsConfiguration,
        label: string,
        testId: string,
        value: string | boolean | number,
        type: "text" | "checkbox" | "number" | "label",
        inErrorState: boolean,
        required: boolean,
        handleChange?: (event: React.ChangeEvent) => void,
        errorTestId?: string,
        disabled?: boolean
    ): JSX.Element {
        const { t } = useTranslation();
        const className = classNames(form.input, {
            [form.fixedWidthInput]: ["text", "number"].includes(type),
            [form.inputError]: inErrorState,
        });
        const valueAttribute = type === "checkbox" ? "checked" : "value";
        const attributes = Object.assign(
            {
                id,
                name: "ems." + id,
                className,
                "data-testid": testId,
                onChange: handleChange,
                type,
                [valueAttribute]: value,
                disabled,
            },
            type !== "number" ? {} : { min: 0 }
        );
        const element =
            type === "label" ? (
                <span className={form.fixedWidthInput} data-testid={testId}>
                    {value}
                </span>
            ) : (
                <Field {...attributes} />
            );

        const labels =
            type === "checkbox" ? (
                <label className={form.container}>
                    {element}
                    <span className={form.checkmark} />
                </label>
            ) : (
                <>{element}</>
            );

        return (
            <div className={form.formFields}>
                <span className={form.optional}>{required ? "" : t("Common.optional")}</span>
                <label htmlFor={id} className={form.label}>
                    {label}
                </label>
                {labels}
                <div className={form.error} data-testid={errorTestId}>
                    <ErrorMessage component="div" name={attributes.name} className={form.error} />
                </div>
            </div>
        );
    }

    private createCustomerNameSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="customerName" className={form.label}>
                    {t("Common.customerName")}
                </label>
                <input
                    id="customerName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.customerName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.itself}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.customerName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.errorLabel}
                >
                    <ErrorMessage name="customerName" />
                </div>
            </div>
        );
    }

    private createCustomerTypeSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const tenantToTranslationKeyMap = new Map<TenantType, string>([
            ["CHANNEL", t("Common.channel")],
            ["CORPORATE", t("Common.corporate")],
            ["PROCESSOR", t("Common.processor")],
            ["CUSTOMER", t("Common.tenant")],
        ]);
        const validTenantTypes: TenantType[] = [];

        if (this.props.tenantType === "INTERNAL" || this.props.tenantType === "CHANNEL") {
            validTenantTypes.push(...TENANT_TYPES);
        } else {
            validTenantTypes.push(this.props.tenantType);
        }

        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="type" className={classNames(form.label)}>
                        {t("AddCustomerForm.type")}
                    </label>
                    <select
                        id="type"
                        className={classNames(form.select, form.fixedWidthInput)}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.typeSelect.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedType = event.target.value as TenantType;
                            const selectedLicensingModel =
                                selectedType == "CORPORATE" || selectedType == "PROCESSOR"
                                    ? LicensingModel.BUNDLE
                                    : LicensingModel.OLD_MODEL;

                            formikProps.setFieldValue("licensingModel", selectedLicensingModel);
                            const preselectedTier = showAllowedTierList(
                                "",
                                event.target.value as TenantType,
                                this.props.tenantTier,
                                selectedLicensingModel == LicensingModel.OLD_MODEL
                            )[0];
                            formikProps.setFieldValue("tier", preselectedTier);
                            this.setSelectedLicenses(
                                this.populateWithPredefinedLicenses(selectedLicensingModel, preselectedTier)
                            );
                        }}
                        value={formikProps.values.type}
                    >
                        {validTenantTypes.map((value, index) => (
                            <option key={index} value={value}>
                                {tenantToTranslationKeyMap.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    private createRegionSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="region" className={form.label}>
                        {t("AddCustomerForm.customerFormRegion")}:
                    </label>
                    <select
                        id="region"
                        className={classNames(form.select, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.region,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.regionSelect.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.region}
                    >
                        {this.generateRegions(t) &&
                            this.generateRegions(t).map((value, index) => (
                                <option key={index} value={value.code}>
                                    {value.name}
                                </option>
                            ))}
                    </select>
                </div>
                <Tooltip
                    content={
                        <div className={style.tooltipOpen}>
                            {t("AddCustomerForm.tooltip")}
                            <br />
                            <a
                                href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t("AddCustomerForm.tooltipOpen")}
                            </a>
                        </div>
                    }
                    placement={"top"}
                >
                    <a
                        href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <div className={style.info} tabIndex={0}>
                            <Info
                                borderColor={this.props.theme.contentBackgroundColor}
                                color={this.props.theme.iconFillColor}
                            />
                        </div>
                    </a>
                </Tooltip>
            </div>
        );
    }

    private createLicensingModelSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const licensingModelToTranslationKey = new Map<string, string>([
            [LicensingModel.OLD_MODEL, t("AddCustomerForm.licensingModel.old")],
            [LicensingModel.BUNDLE, t("AddCustomerForm.licensingModel.bundles")],
            [LicensingModel.BUNDLE_WITH_TOKEN, t("AddCustomerForm.licensingModel.bundlesWithToken")],
        ]);
        const licensingModelOptions: string[] = [];
        switch (this.props.tenantType) {
            case "INTERNAL":
                if (useFeature(FLAG_BLANCCO_TOKEN)) {
                    licensingModelOptions.push(...Object.keys(LicensingModel));
                } else {
                    licensingModelOptions.push(...[LicensingModel.OLD_MODEL, LicensingModel.BUNDLE]);
                }
                break;
            case "CHANNEL":
                licensingModelOptions.push(...[LicensingModel.OLD_MODEL, LicensingModel.BUNDLE]);
                break;
            case "CUSTOMER":
                licensingModelOptions.push(LicensingModel.OLD_MODEL);
                break;
            default:
                licensingModelOptions.push(this.props.licensingModel);
                break;
        }
        return (
            <>
                <div className={form.formFields}>
                    <label htmlFor="licensingModel" className={classNames(form.label)}>
                        {t("AddCustomerForm.licensingModel.label")}
                    </label>
                    <select
                        id={"licensingModel"}
                        className={classNames(form.select, form.fixedWidthInput)}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedLicensingModel = event.target.value as LicensingModel;
                            const oldModel = selectedLicensingModel == LicensingModel.OLD_MODEL;
                            const selectedTenantType = oldModel ? "CUSTOMER" : "CORPORATE";
                            formikProps.setFieldValue("type", selectedTenantType);
                            const preselectedTier = showAllowedTierList(
                                "",
                                selectedTenantType,
                                this.props.tenantTier,
                                oldModel
                            )[0];
                            formikProps.setFieldValue("tier", preselectedTier);
                            this.setSelectedLicenses(
                                this.populateWithPredefinedLicenses(selectedLicensingModel, preselectedTier)
                            );
                        }}
                        value={formikProps.values.licensingModel}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.licensingModelSelect.itself}
                    >
                        {licensingModelOptions.map((value, index) => (
                            <option key={index} value={value}>
                                {licensingModelToTranslationKey.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        );
    }

    private populateWithPredefinedLicenses(licensingModel: LicensingModel, selectedTier: CombinedTier) {
        if (licensingModel !== LicensingModel.OLD_MODEL) {
            const dropdownLicenses: { productName: string; productId: string }[] = [];
            this.generateDropdownLicenses(selectedTier, dropdownLicenses, licensingModel);
            const predefinedLicenses: License[] = [];
            dropdownLicenses.forEach((eachInAllLicenses) => {
                if (eachInAllLicenses.productId == MANAGEMENT_CONSOLE_LICENSE) {
                    if (selectedTier == CorporateBundle.ENTERPRISE || selectedTier == ProcessorBundle.PRO_SCALE) {
                        this.manageLicenseList(eachInAllLicenses, predefinedLicenses);
                    }
                } else if (!BUNDLES_ADD_ONS.includes(eachInAllLicenses.productId)) {
                    this.manageLicenseList(eachInAllLicenses, predefinedLicenses);
                }
            });
            return predefinedLicenses;
        }
        return [
            {
                licenseType: "default",
                licensesToAdd: 0,
                productName: "",
                totalOfLicenses: 0,
                expirationDate: formatIsoDate(new Date()),
                parentLicenseExpirationDate: null,
                index: 0,
                available: 0,
                parentAvailableAmount: 0,
            },
        ];
    }

    private createTierSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        const oldLicensingModel = formikProps.values.licensingModel == LicensingModel.OLD_MODEL;
        const allowedTiers = [];
        if (
            this.props.tenantType === "INTERNAL" ||
            this.props.tenantType === "CHANNEL" ||
            this.props.tenantType === "CUSTOMER"
        ) {
            allowedTiers.push(
                ...showAllowedTierList("", formikProps.values.type, this.props.tenantTier, oldLicensingModel)
            );
        } else {
            allowedTiers.push(this.props.tenantTier);
        }

        return (
            <>
                <div className={style.regionAndInfoContainer}>
                    <div className={form.formFields}>
                        <label htmlFor="tier" className={classNames(form.label)}>
                            {oldLicensingModel ? t("AddCustomerForm.tier") : t("AddCustomerForm.blanccoBundles")}
                        </label>
                        <select
                            id="tier"
                            className={classNames(form.select, form.fixedWidthInput)}
                            data-testid={testIds.workArea.tenant.manageTenantDialog.details.tierSelect.itself}
                            onChange={(event) => {
                                formikProps.handleChange(event);
                                this.setSelectedLicenses(
                                    this.populateWithPredefinedLicenses(
                                        formikProps.values.licensingModel,
                                        event.target.value as CombinedTier
                                    )
                                );
                            }}
                            value={formikProps.values.tier}
                        >
                            {allowedTiers.map((value, index) => (
                                <option key={index} value={value}>
                                    {generateTierToTranslationMap(t).get(value)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className={style.tooltipPostion}
                        onClick={() => setPricingTierModalVisible(true)}
                        type="button"
                    >
                        <QuestionMark color={theme.iconFillColor} />
                    </button>
                </div>
                <PricingTierView
                    tenantType={formikProps.values.type}
                    visibility={pricingTierModalVisible}
                    setVisibility={setPricingTierModalVisible}
                    selectedTier={formikProps.values.tier}
                />
            </>
        );
    }

    private createCountrySection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={form.formFields}>
                <label htmlFor="country" className={form.label}>
                    {t("Common.country")}:
                </label>
                <select
                    id="country"
                    className={classNames(form.select, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.country,
                    })}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.country}
                >
                    <option>{t("AddCustomerForm.SelectCountry")}</option>
                    {this.generateCountries(t).map((value, index) => (
                        <option key={index} value={value.code}>
                            {value.name}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.errorLabel}
                >
                    <ErrorMessage name="country" />
                </div>
            </div>
        );
    }

    private createNotesSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.setState({
                notesCharactersLeft: event.target.maxLength - event.target.value.length,
            });
        };

        return (
            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={form.label}>
                        {t("Common.notes")}:
                    </label>
                </div>
                <div className={style.notesContainer}>
                    <textarea
                        id="notes"
                        className={classNames(
                            form.input,
                            form.fixedWidthInput,
                            { [form.inputError]: formikProps.errors.notes },
                            style.notesHeight
                        )}
                        maxLength={NOTES_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.itself}
                        onChange={(e) => {
                            formikProps.handleChange(e);
                            notesChangeHandler(e);
                        }}
                        value={formikProps.values.notes}
                    />
                    <span className={form.optional}>
                        {t("AddCustomerForm.charactersLeft", {
                            remainingCharacters: this.state.notesCharactersLeft.toString(),
                            notesMaxLength: NOTES_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.errorLabel}
                >
                    <ErrorMessage name="notes" />
                </div>
            </div>
        );
    }

    private createMainContactSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="mainContactName" className={form.label}>
                    {t("AddCustomerForm.contactName")}
                </label>
                <input
                    id="mainContactName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.mainContactName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.mainContactName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.errorLabel}
                >
                    <ErrorMessage name="mainContactName" />
                </div>
            </div>
        );
    }

    private createEmailSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="email" className={form.label}>
                        {t("Common.email")}:
                    </label>
                    <input
                        id="email"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.email,
                        })}
                        maxLength={EMAIL_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.email}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.errorLabel}
                    >
                        <ErrorMessage name="email" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.contactEmailInfoToolTip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    private createAccessExpirySection(t: TFunction): JSX.Element {
        function formatDate(date: Date): string {
            return date.getDate().toString() + " " + MONTHS[date.getMonth()] + " " + date.getFullYear();
        }
        const [selectDate, setSelectDate] = React.useState("");
        const handleOkClick = (date: Date) => {
            this.selectedExpirationDate = date;
            setSelectDate(formatDate(date));
        };
        const handleClean = () => {
            setSelectDate(t("Common.never"));
            this.selectedExpirationDate = undefined;
        };
        const createYesterday = () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        };
        const setDefaultDate = () => {
            if (this.selectedExpirationDate != null) {
                return selectDate;
            }
            if (this.props.parentExpirationDate == null) {
                return t("Common.never");
            }
            this.selectedExpirationDate = this.props.parentExpirationDate;
            return formatDate(this.props.parentExpirationDate);
        };

        const isDateDisabled = (date: Date) => {
            if (this.props.parentExpirationDate == null) {
                return isBefore(date, createYesterday());
            }

            date.setHours(0, 0, 0, 0);
            return isBefore(date, createYesterday()) || isAfter(date, this.props.parentExpirationDate);
        };

        const calendar = document.getElementsByClassName("rs-picker-toggle-caret rs-icon")[0] as HTMLElement;
        if (calendar) {
            calendar.setAttribute("aria-label", t("AltText.calendar"));
        }
        return (
            <>
                {
                    <>
                        <span className={form.optional}>{t("Common.optional")}</span>
                        <label htmlFor="expirationDate" className={form.label}>
                            {t("Common.accessExpiry")}:
                        </label>
                        <DatePicker
                            id="expirationDate"
                            disabledDate={isDateDisabled}
                            ranges={[
                                {
                                    label: "Today",
                                    value: new Date(),
                                    closeOverlay: true,
                                },
                            ]}
                            title={t("AltText.calendar")}
                            placeholder={setDefaultDate()}
                            onSelect={handleOkClick}
                            onClean={handleClean}
                            placement={"top"}
                            className={form.dateRange}
                            renderValue={(value: Date) => {
                                return `${formatDateWithoutTime(value.toString())}`;
                            }}
                        />
                    </>
                }
            </>
        );
    }

    private createSalesforceAccountId(t: TFunction, formikProps: FormikProps<FormValues>, mandatoryField: boolean) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    {mandatoryField ? null : <span className={form.optional}>{t("Common.optional")}</span>}
                    <label htmlFor="salesforceAccountId" className={form.label}>
                        {t("AddCustomerForm.accountId")}
                    </label>
                    <input
                        id="salesforceAccountId"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.salesforceAccountId,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            if (this.state.emsIdUseDefault) {
                                formikProps.setFieldValue("ems.emsId", event.target.value);
                            }
                        }}
                        value={formikProps.values.salesforceAccountId}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.errorLabel}
                    >
                        <ErrorMessage name="salesforceAccountId" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.accountIdTooltip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    private emsIdUseDefaultCheckbox(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        const handleToggle = (): void => {
            this.setState({ emsIdUseDefault: !this.state.emsIdUseDefault }, () => {
                if (this.state.emsIdUseDefault) {
                    formikProps.setFieldValue("ems.emsId", formikProps.values.salesforceAccountId);
                }
            });
        };
        return (
            <div className={classNames(form.formFields, style.defaultValueCheckbox)}>
                {loader}
                <label className={classNames(form.container, style.defaultCheckboxAlignment)}>
                    <input
                        type="checkbox"
                        id="emsIdUseDefault"
                        className={classNames(form.input)}
                        defaultChecked={this.state.emsIdUseDefault}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.settings.emsIdUseDefaultCheckbox}
                        onChange={() => {
                            handleToggle();
                        }}
                    />
                    <span className={form.checkmark} />
                </label>
                <label htmlFor="emsIdUseDefault" className={classNames(form.label)}>
                    {t("AddCustomerForm.useDefaultValue")}
                </label>
            </div>
        );
    }

    private showOptionalFields() {
        return this.props.isUserDetailsOptional || hasTenantCookie() || hasSubTenantCookie();
    }
    private createLicenceTable(formikProps: FormikProps<FormValues>) {
        const dropdownLicenses: { productName: string; productId: string }[] = [];
        const tenantLicenses: LicenseData[] = [];
        if (this.props.tenantLicenses !== undefined && this.props.tenantLicenses !== null) {
            if (showOnlyTokenLicense(formikProps.values.licensingModel)) {
                tenantLicenses.push(
                    ...this.props.tenantLicenses?.filter(
                        (each) => isTokenLicense(each.type) || isProductAddOn(each.type)
                    )
                );
            } else {
                tenantLicenses.push(...this.props.tenantLicenses?.filter((each) => !isTokenLicense(each.type)));
            }
        }
        const fetchLicences = () => {
            this.generateDropdownLicenses(formikProps.values.tier, dropdownLicenses, formikProps.values.licensingModel);
            const allLicenses: License[] = [];
            tenantLicenses.forEach((tenantLicense) => {
                if (isExpirationDateValid(tenantLicense.expirationDate) && tenantLicense.available > 0) {
                    const productDetails = dropdownLicenses.find((each) => each.productId === tenantLicense.type);
                    if (typeof productDetails === "undefined") {
                        return;
                    }
                    if (
                        (this.currentTenantDetails.type === "INTERNAL" ||
                            this.currentTenantDetails.type === "CHANNEL") &&
                        formikProps.values.licensingModel === LicensingModel.BUNDLE
                    ) {
                        allLicenses.push(
                            this.createLicense(
                                tenantLicense.type,
                                0,
                                productDetails.productName,
                                tenantLicense.expirationDate,
                                allLicenses.length,
                                tenantLicense.available
                            )
                        );
                    } else {
                        allLicenses.push(
                            this.createLicense(
                                tenantLicense.type,
                                0,
                                productDetails.productName,
                                tenantLicense.expirationDate,
                                allLicenses.length,
                                tenantLicense.available
                            )
                        );
                    }
                }
            });
            return allLicenses;
        };
        const isExpirationDateValid = (expiration: string): boolean => {
            const expirationDate = new Date(expiration);
            return expirationDate >= MIN_DATE && expirationDate <= MAX_DATE;
        };
        const allAvailableLicenses = fetchLicences();
        return (
            <>
                <DeliveryFormContent
                    availableLicenses={allAvailableLicenses}
                    inTenantCreation={true}
                    ownedLicenses={[]}
                    selectedLicenses={this.state.selectedLicenses}
                    setSelectedLicenses={(licenses) => this.setSelectedLicenses(licenses)}
                    deliveryForm={formikProps.values.deliveryDetails}
                    errors={formikProps.errors}
                    handleChange={formikProps.handleChange}
                    licensingModel={formikProps.values.licensingModel}
                />
                <div className={style.deliveryTable}>
                    <LicenseSelectionForm
                        inTenantCreation={true}
                        availableLicenses={allAvailableLicenses}
                        ownedLicenses={[]}
                        globalAmount={this.state.formValues.deliveryDetails.amount}
                        globalExpirationDate={this.state.formValues.deliveryDetails.expirationDate}
                        selectedLicenses={this.state.selectedLicenses}
                        setSelectedLicenses={(licenses) => this.setSelectedLicenses(licenses)}
                    />
                </div>
            </>
        );
    }

    public render(): JSX.Element {
        const setMiniLoaderState = (miniLoader: boolean) => {
            this.setMiniloader(miniLoader);
        };

        const getSubmitHandler = (values: FormValues) => {
            values.expirationDate = this.selectedExpirationDate;

            this.setState({
                // Keep these values in case tenant creation fails. The form is
                // shown to the user again and the old values need to be there.
                formValues: values,
            });

            this.props.submitEventHandler(values, this.state.selectedLicenses);
        };

        const isAddButtonDisabled = (parentLicenseExpirationDate: string) => {
            return this.state.selectedLicenses.some(
                (item) =>
                    isNaN(item.licensesToAdd) ||
                    item.totalOfLicenses < 0 ||
                    !isExpirationDateValid(
                        item.expirationDate,
                        item.parentLicenseExpirationDate
                            ? new Date(item.parentLicenseExpirationDate)
                            : parentLicenseExpirationDate
                    ) ||
                    !isAmountAvailable(item)
            );
        };

        const isUserInternal = () => {
            return (
                !this.props.isUserDetailsOptional &&
                isUserParentInternal() &&
                !hasTenantCookie() &&
                !hasSubTenantCookie()
            );
        };

        // The following arrays (generalInputLabels, settingsInputLabels and licenseDeliveryInputLabels) contain the required form fields and other fields that needs to be validated.
        // These field names are derived from the key names of their respective interfaces.
        // Whenever a new required field or other fields that need to pass validation is added to these interfaces, these arrays need to be updated as well.
        const generalInputLabels: (keyof GeneralRequiredFormValues)[] = [
            "email",
            "country",
            "customerName",
            "mainContactName",
            "salesforceAccountId",
            "region",
        ];

        const settingsInputLabels: (keyof SettingsFormValues)[] = ["ems"];

        const licenseDeliveryInputLabels: (keyof DeliveryDetailsFormValues)[] = ["deliveryDetails"];

        const highlightTabWithError = (errors: FormikErrors<FormValues>, inputLabels: string[], text: string) => {
            let foundErrors = false;

            for (const each of Object.keys(errors)) {
                if (inputLabels.includes(each)) {
                    foundErrors = true;
                    break;
                }
            }
            return (
                <div className={style.gridColumns}>
                    <div
                        className={classNames({
                            [form.inputError]: foundErrors,
                        })}
                    >
                        {foundErrors ? (
                            <FailedRedNotificationIcon
                                backgroundColor={this.props.theme.errorIconColor}
                                iconColor={this.props.theme.contentBackgroundColor}
                            />
                        ) : null}
                    </div>
                    <div
                        className={classNames({
                            [style.tabTitle]: foundErrors,
                        })}
                    >
                        {text}
                    </div>
                </div>
            );
        };

        return (
            <Translation>
                {(t) => (
                    <div className={style.formContainer}>
                        <Formik
                            initialValues={this.state.formValues}
                            onSubmit={(values: FormValues) => getSubmitHandler(values)}
                            validationSchema={object().shape({
                                customerName: string().required(t("AddCustomerForm.nameRequired")).max(NAME_MAX_LENGTH),
                                region: string().required(t("AddCustomerForm.regionRequired")),
                                country: string().required(t("AddCustomerForm.countryRequired")),
                                notes: string().max(NOTES_MAX_LENGTH),
                                mainContactName: isUserInternal()
                                    ? string().required(t("AddCustomerForm.contactNameRequired")).max(NAME_MAX_LENGTH)
                                    : string().max(NAME_MAX_LENGTH),
                                email: isUserInternal()
                                    ? string()
                                          .email(t("Common.invalidEmail"))
                                          .required(t("AddCustomerForm.contactEmailRequired"))
                                          .max(EMAIL_MAX_LENGTH)
                                          .test("exists", t("Common.emailNotAvailable"), function (value) {
                                              setMiniLoaderState(true);
                                              if (value == null) {
                                                  return Promise.resolve(true);
                                              }

                                              const emailToBeValidated = value.trim();
                                              const abortController = new AbortController();
                                              return userService
                                                  .checkEmailAvailability(emailToBeValidated, abortController)
                                                  .then((value: CheckEmailAvailability) => {
                                                      if (value.emailIsUndeliverable) {
                                                          return this.createError({
                                                              message: t("Common." + value.errorMessage, {
                                                                  domain_name: value.domainName,
                                                              }),
                                                          });
                                                      }
                                                      return value.emailIsAvailable;
                                                  })
                                                  .finally(() => setMiniLoaderState(false));
                                          })
                                    : string()
                                          .email(t("Common.invalidEmail"))
                                          .max(EMAIL_MAX_LENGTH)
                                          .test("exists", t("Common.emailNotAvailable"), function (value) {
                                              setMiniLoaderState(true);
                                              if (value == null) {
                                                  return Promise.resolve(true);
                                              }

                                              const emailToBeValidated = value.trim();
                                              const abortController = new AbortController();
                                              return userService
                                                  .checkEmailAvailability(emailToBeValidated, abortController)
                                                  .then((value: CheckEmailAvailability) => {
                                                      if (value.emailIsUndeliverable) {
                                                          return this.createError({
                                                              message: t("Common." + value.errorMessage, {
                                                                  domain_name: value.domainName,
                                                              }),
                                                          });
                                                      }
                                                      return value.emailIsAvailable;
                                                  })
                                                  .finally(() => setMiniLoaderState(false));
                                          }),
                                expirationDate: string().default("AddCustomerForm.defaultValueSent"),
                                salesforceAccountId: isUserInternal()
                                    ? string().required(t("AddCustomerForm.accountIdRequired"))
                                    : string(),
                                ems: object().shape({
                                    availableSlActivations: number().min(0),
                                    emsId: string().test(
                                        "exists",
                                        t("EditCustomerView.emsIdDoesNotExist"),
                                        (value: string): Promise<boolean> => {
                                            this.setMiniloader(true);
                                            if (value == null) {
                                                this.setMiniloader(false);
                                                return Promise.resolve(true);
                                            }
                                            const trimmed = value.trim();
                                            const abortController = new AbortController();
                                            return licenseService
                                                .checkEmsCustomerIdExistence(trimmed, abortController)
                                                .finally(() => this.setMiniloader(false));
                                        }
                                    ),
                                }),
                                deliveryDetails: object().shape({
                                    caseNumber:
                                        !this.showOptionalFields() &&
                                        isUserParentInternal() &&
                                        this.state.selectedLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.caseNumberRequired"))
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                            : string()
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  ),
                                    opportunityId:
                                        !this.showOptionalFields() &&
                                        isUserParentInternal() &&
                                        this.state.selectedLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.opportunityIdRequired"))
                                                  .max(
                                                      MAX_OPPORTUNITY_ID_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                          {
                                                              length: MAX_OPPORTUNITY_ID_LENGTH,
                                                          }
                                                      )
                                                  )
                                            : string().max(
                                                  MAX_OPPORTUNITY_ID_LENGTH,
                                                  t(
                                                      "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                      {
                                                          length: MAX_OPPORTUNITY_ID_LENGTH,
                                                      }
                                                  )
                                              ),
                                }),
                            })}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <Tabs>
                                        <TabList>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.details.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    generalInputLabels,
                                                    t("AddCustomerForm.generic")
                                                )}
                                            </Tab>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.settings.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    settingsInputLabels,
                                                    t("Common.settings")
                                                )}
                                            </Tab>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    licenseDeliveryInputLabels,
                                                    t("AddCustomerForm.deliveryDetails")
                                                )}
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                {t("AddCustomerForm.generic")}
                                            </Heading>
                                            {this.createCustomerNameSection(t, formikProps)}
                                            {this.createLicensingModelSection(t, formikProps)}
                                            {this.createCustomerTypeSection(t, formikProps)}
                                            {this.createTierSection(t, formikProps)}
                                            {this.createRegionSection(t, formikProps)}
                                            {this.createCountrySection(t, formikProps)}
                                            {this.createNotesSection(t, formikProps)}
                                            <br />
                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                {t("AddCustomerForm.details")}
                                            </Heading>
                                            {this.createMainContactSection(t, formikProps)}
                                            {this.createEmailSection(t, formikProps)}
                                            {isUserParentInternal() ? (
                                                <>
                                                    <br />
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("AddCustomerForm.salesforceAccount")}
                                                    </Heading>
                                                    {this.createSalesforceAccountId(t, formikProps, isUserInternal())}
                                                </>
                                            ) : null}
                                        </TabPanel>
                                        <TabPanel>
                                            {isUserParentInternal() ? (
                                                <>
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("EditCustomerView.entitlementSettings")}
                                                    </Heading>
                                                    <div className={style.formFieldGroup}>
                                                        {this.createField(
                                                            "emsId",
                                                            t("AddCustomerForm.accountId"),
                                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                                .accountIdInput.itself,
                                                            formikProps.values.ems.emsId,
                                                            "text",
                                                            formikProps.errors.ems?.emsId != null,
                                                            false,
                                                            formikProps.handleChange,
                                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                                .accountIdInput.errorLabel,
                                                            this.state.emsIdUseDefault
                                                        )}
                                                        {this.emsIdUseDefaultCheckbox(t, formikProps)}
                                                    </div>
                                                    {this.createField(
                                                        "hlEntitlements",
                                                        t("EditCustomerView.hlEntitlements"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .hlEntitlementsCheckbox,
                                                        formikProps.values.ems.hlEntitlements,
                                                        "checkbox",
                                                        false,
                                                        false,
                                                        formikProps.handleChange
                                                    )}
                                                    {this.createField(
                                                        "slEntitlements",
                                                        t("EditCustomerView.slEntitlements"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .slEntitlementsCheckbox,
                                                        formikProps.values.ems.slEntitlements,
                                                        "checkbox",
                                                        false,
                                                        false,
                                                        formikProps.handleChange
                                                    )}
                                                    {this.createField(
                                                        "availableSlActivations",
                                                        t("EditCustomerView.availableSlActivations"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .availableSlActivationsInput.itself,
                                                        formikProps.values.ems.availableSlActivations,
                                                        "number",
                                                        formikProps.errors.ems?.availableSlActivations != null,
                                                        false,
                                                        formikProps.handleChange,
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .availableSlActivationsInput.errorLabel
                                                    )}
                                                    <br />
                                                </>
                                            ) : null}
                                            {
                                                <Heading tag={"div"} variant="SUBTITLE_1">
                                                    {t("AddCustomerForm.status")}
                                                </Heading>
                                            }
                                            {this.createAccessExpirySection(t)}
                                        </TabPanel>
                                        <TabPanel>{this.createLicenceTable(formikProps)}</TabPanel>
                                    </Tabs>

                                    <div className={form.buttonContainer}>
                                        <button
                                            type="submit"
                                            className={
                                                isAddButtonDisabled(MAX_DATE.toLocaleDateString())
                                                    ? classNames(buttons.disabledButton, form.submitButton)
                                                    : classNames(
                                                          buttons.primaryButton,
                                                          buttons.medium,
                                                          form.submitButton
                                                      )
                                            }
                                            disabled={
                                                formikProps.isSubmitting ||
                                                isAddButtonDisabled(MAX_DATE.toLocaleDateString())
                                            }
                                            data-testid={testIds.workArea.tenant.manageTenantDialog.submitButton}
                                        >
                                            {t("AddCustomerView.addCustomer")}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
            </Translation>
        );
    }
}
