import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./software-packages.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import Download from "components/icons/Download";
import { BUNDLES_ADD_ONS } from "components/licenses/bundles";
import { BLANCCO_TOKEN_ID } from "components/licenses/common";
import StaticTable from "components/support/api-guide/StaticTable";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import { LicenseData } from "domain/licenses";
import { SoftwarePackage, SoftwarePackagesData, Versions } from "domain/softwarePackages";
import { LicensingModel } from "domain/tenants";
import { LicenseResponse } from "services/licenses/LicenseService";
import { getCurrentTenantDetails, hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import formStyle from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Props {
    licensePromise: Promise<LicenseResponse>;
    fetchSoftwarePackagesData: () => Promise<SoftwarePackagesData>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const SoftwarePackages = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [cellData, setCellData] = React.useState<Array<Array<string | JSX.Element | null>>>([[null]]);
    const data: string[] = [];
    const BLANCCO_MANAGEMENT_CONSOLE_ID = "51";
    const versionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        data[index] = event.target.value;
    };
    const [errorMessage, showErrorMessage] = React.useState<boolean>(false);

    const generateVersionSelect = (versions: Versions[], index: number): JSX.Element => {
        if (versions.length === 0) {
            return <div>{t("Common.na")}</div>;
        }
        return versions.length > 1 ? (
            <select
                onChange={(event) => versionChangeHandler(event, index)}
                onMouseDown={(event) => event.stopPropagation()}
                className={classNames(formStyle.select, style.versionsDropdown)}
                key={index}
                data-testid={testIds.workArea.support.supportAndHelp.cards.software.table.versionSelect}
            >
                {versions.map((data, key) => (
                    <option key={key} value={data.downloadUrl}>
                        {data.version}
                    </option>
                ))}
            </select>
        ) : (
            <div data-testid={testIds.workArea.support.supportAndHelp.cards.software.table.versionSelect}>
                {versions[0].version}
            </div>
        );
    };

    const downloadSoftwarePackage = (index: number): void => {
        window.location.href = data[index];
    };

    const filterSoftwarePackages = (
        softwarePackages: SoftwarePackage[],
        licensesData: LicenseData[]
    ): SoftwarePackage[] => {
        if (userSessionService.currentUserDetails()?.tenantType === "INTERNAL" && !hasTenantCookie()) {
            return softwarePackages;
        }
        const filteredPackages: Set<SoftwarePackage> = new Set();
        const tenantDetails = getCurrentTenantDetails();
        if (tenantDetails.licensingModel == LicensingModel.BUNDLE_WITH_TOKEN) {
            const availableLicenses: string[] = [];
            licensesData.forEach((license) => {
                if (license.available <= 0 && license.expirationDate < new Date().toJSON()) {
                    return;
                }
                if (license.type == BLANCCO_TOKEN_ID) {
                    const deducedTierLicenses = deduceTierLicenses(tenantDetails.tenantTier, true);
                    if (deducedTierLicenses) {
                        availableLicenses.push(...deducedTierLicenses);
                    }
                } else {
                    if (BUNDLES_ADD_ONS.includes(license.type)) {
                        availableLicenses.push(license.type);
                    }
                }
            });
            softwarePackages.forEach((product) => {
                availableLicenses.forEach((each) => {
                    if (product.licenseIds.includes(each)) {
                        filteredPackages.add(product);
                    }
                });
            });
        } else {
            softwarePackages.forEach((product) => {
                licensesData.forEach((license) => {
                    if (product.licenseIds.includes(license.type)) {
                        filteredPackages.add(product);
                    }
                });
            });
        }
        return Array.from(filteredPackages);
    };

    const sortSoftwarePackages = (products: SoftwarePackage[]): void => {
        // Lexicographic sort
        products.sort((a, b) => a.productName.localeCompare(b.productName));
        // Versions sorting
        products.forEach((softwarePackage) => {
            softwarePackage.versions.sort((a, b) => b.version.localeCompare(a.version, undefined, { numeric: true }));
        });
    };

    const showErrorDialog = (e: Error) => {
        logger.error(e);
        setLoading(false);
        showErrorMessage(true);
    };

    React.useEffect(() => {
        try {
            setLoading(true);
            const softwarePackagesPromise = props.fetchSoftwarePackagesData();
            Promise.all([softwarePackagesPromise, props.licensePromise])
                .then((promises) => {
                    const softwarePackagesData: SoftwarePackagesData = promises[0];
                    const licenseData: LicenseData[] = promises[1].licenses;

                    const productDownloads: SoftwarePackage[] = softwarePackagesData.productDownloads.filter(
                        (product) => !product.licenseIds.includes(BLANCCO_MANAGEMENT_CONSOLE_ID)
                    );

                    const filteredPackages = filterSoftwarePackages(productDownloads, licenseData);
                    sortSoftwarePackages(filteredPackages);
                    setCellData(
                        filteredPackages.map((product: SoftwarePackage, index: number) => {
                            if (product.versions.length > 0) {
                                data.push(product.versions[0].downloadUrl);
                            }

                            return [
                                <div
                                    key={index}
                                    data-testid={testIds.workArea.support.supportAndHelp.cards.software.table.nameLabel}
                                >
                                    {product.productName}
                                </div>,
                                generateVersionSelect(product.versions, index),
                                product.versions.length > 0 ? (
                                    <div
                                        onClick={() => downloadSoftwarePackage(index)}
                                        key={index}
                                        className={style.downloadIcon}
                                        data-testid={
                                            testIds.workArea.support.supportAndHelp.cards.software.table
                                                .versionDownloadButton
                                        }
                                    >
                                        <Download color={props.theme.iconFillColor} />
                                    </div>
                                ) : (
                                    ""
                                ),
                            ];
                        })
                    );
                    setLoading(false);
                })
                .catch((e: Error) => showErrorDialog(e))
                .finally(() => setLoading(false));
        } catch (e) {
            showErrorDialog(e);
        }
    }, []);
    return (
        <>
            <div className={style.heading}>{t("Support.supportAndHelp.softwarePackages.introduction")}</div>
            {errorMessage ? (
                <ErrorMessage />
            ) : (
                <>
                    <div className={style.tableContainer}>
                        <StaticTable
                            headers={[
                                {
                                    className: style.firstColumnHeader,
                                    value: t("Support.supportAndHelp.softwarePackages.product"),
                                },
                                {
                                    className: style.secondColumnHeader,
                                    value: t("Support.supportAndHelp.softwarePackages.version"),
                                },
                                {
                                    className: style.thirdColumnHeader,
                                    value: t("Common.download"),
                                },
                            ]}
                            cells={cellData}
                            loading={loading}
                            tableClass={style.table}
                            testId={testIds.workArea.support.supportAndHelp.cards.software.table.itself}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default connector(SoftwarePackages);
