import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { DeleteIcon } from "components/icons/DeleteIcon";
import {
    displayAmountErrorLabel,
    FEATURE_LICENSES,
    isExpirationDateValid,
    isSubscriptionLicense,
} from "components/licenses/common";
import style from "components/licenses/delivery-history/add-license-delivery.scss";
import { addMoreLicenses, License } from "components/licenses/delivery-history/DeliveryFormContent";
import SubscriptionExpiryWarningBanner from "components/licenses/subscription-expiry-warning-banner/SubscriptionExpiryWarningBanner";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import { MAX_DATE, MIN_DATE } from "domain/licenses";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie, isCurrentTenantInternal, isUserParentInternal } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { getMaxDate } from "utils/commonFunctions";
import {
    formatExpirationDate,
    formatExpirationDateWithoutTime,
    formatIsoDate,
    MONTHS,
    toUtcDateString,
} from "utils/format";

import testIds from "testIds.json";

interface Props {
    availableLicenses: License[];
    ownedLicenses: License[];
    globalAmount: number | null;
    globalExpirationDate: string;
    setSelectedLicenses: (licenses: License[]) => void;
    selectedLicenses: License[];
    inTenantCreation: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const LicenseSelectionForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const MAX_NUMBER_OF_LICENSES = 24;
    const [selectedSubscriptionLicenses, setSelectedSubscriptionLicenses] = React.useState<string[]>([]);
    const defaultSelectedLicense = {
        licenseType: "default",
        licensesToAdd: 0,
        productName: t("DeliveryHistory.addLicenseDelivery.table.default"),
        totalOfLicenses: 0,
        expirationDate: formatIsoDate(new Date()),
        index: 0,
        available: 0,
        parentAvailableAmount: 0,
        parentLicenseExpirationDate: isCurrentTenantInternal() ? toUtcDateString(MAX_DATE) : null,
    };
    const lastDate = () => {
        const maxDate = getMaxDate();
        return maxDate.getDate().toString() + " " + MONTHS[maxDate.getMonth()] + " " + maxDate.getFullYear();
    };
    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_DELIVERY,
            action: Action.ADD_LICENSE_TO_DELIVERY,
        });
        const newAdded: License = {
            licenseType: defaultSelectedLicense.licenseType,
            expirationDate: props.selectedLicenses[props.selectedLicenses.length - 1].expirationDate,
            productName: defaultSelectedLicense.productName,
            available: defaultSelectedLicense.available,
            licensesToAdd: defaultSelectedLicense.licensesToAdd,
            totalOfLicenses: defaultSelectedLicense.totalOfLicenses,
            index: Math.max(...props.selectedLicenses.map((item) => item.index)) + 1,
            parentAvailableAmount: defaultSelectedLicense.parentAvailableAmount,
            parentLicenseExpirationDate: defaultSelectedLicense.parentLicenseExpirationDate,
        };
        const licenses = props.selectedLicenses.concat([newAdded]);
        props.setSelectedLicenses(licenses);
    };

    const removeRow = (index: number) => {
        props.setSelectedLicenses(props.selectedLicenses.filter((each) => each.index !== index));
    };
    if (!props.inTenantCreation) {
        const rebuildSubscriptionLicenses = () => {
            if (!isUserParentInternal() || hasTenantCookie()) {
                const licenseListBuilt = props.selectedLicenses
                    .filter((e) => e.available == 1)
                    .filter((e) => FEATURE_LICENSES.includes(e.licenseType) || isSubscriptionLicense(e.licenseType))
                    .map((e) => e.productName);

                setSelectedSubscriptionLicenses(licenseListBuilt);
            }
        };

        React.useEffect(() => {
            rebuildSubscriptionLicenses();
        }, []);
    }

    React.useEffect(() => {
        if (props.ownedLicenses.length > 0) {
            const sortedLicenses = props.ownedLicenses.sort((value1, value2) =>
                value1.productName.localeCompare(value2.productName)
            );
            props.setSelectedLicenses(sortedLicenses);
        }
    }, []);

    const isAddMoreButtonHidden = (): boolean => {
        return props.selectedLicenses.length === MAX_NUMBER_OF_LICENSES;
    };
    return (
        <>
            <div className={classNames(form.formFields)}>
                <SubscriptionExpiryWarningBanner productNames={selectedSubscriptionLicenses} />
            </div>
            <StaticTable
                headers={[
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.licenseType"),
                    },
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.available"),
                    },
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.licensesToAdd"),
                    },
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.totalOfLicenses"),
                    },
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.remainingLicenses"),
                    },
                    {
                        value: t("DeliveryHistory.addLicenseDelivery.table.expirationDate"),
                    },
                ]}
                cells={props.selectedLicenses.map((license) => {
                    return [
                        <Tooltip key={license.licenseType} content={license.productName}>
                            <select
                                className={classNames(
                                    form.select,
                                    style.fixedWidthInput,
                                    style.ellipsis,
                                    style.selectArrow
                                )}
                                id={"licenseType" + license.index}
                                name={"licenseType" + license.index}
                                key={"licenseType" + license.index}
                                onChange={(e) => {
                                    const selected = props.availableLicenses.find(
                                        (item) => item.licenseType === e.target.value
                                    );
                                    if (selected !== undefined) {
                                        props.setSelectedLicenses(
                                            props.selectedLicenses.map((each) => {
                                                if (license.index === each.index) {
                                                    each = selected;
                                                    each.index = license.index;
                                                    const selectedLicensesLength = props.selectedLicenses.length;
                                                    if (
                                                        selectedLicensesLength >= 1 &&
                                                        each.available == 0 &&
                                                        license.licenseType === "default"
                                                    ) {
                                                        each.expirationDate =
                                                            props.selectedLicenses[
                                                                selectedLicensesLength - 1
                                                            ].expirationDate;
                                                        each.assigned = 0;
                                                    }
                                                    if (props.globalAmount != null) {
                                                        each.licensesToAdd = props.globalAmount;
                                                        each.totalOfLicenses = isNaN(props.globalAmount)
                                                            ? each.available
                                                            : each.available + props.globalAmount;
                                                        each.assigned = 0;
                                                    }
                                                    if (props.globalExpirationDate != "") {
                                                        each.expirationDate = props.globalExpirationDate;
                                                    }
                                                }
                                                return each;
                                            })
                                        );
                                    }
                                }}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_DELIVERY,
                                        action: Action.CHANGE_LICENSE_TYPE,
                                    });
                                }}
                                value={license.licenseType}
                                data-testid={
                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog.licenseTypeSelect
                                        .itself
                                }
                            >
                                <option value={"default"}>
                                    {t("DeliveryHistory.addLicenseDelivery.table.default")}
                                </option>
                                {props.availableLicenses.map((each) => (
                                    <option
                                        key={each.licenseType}
                                        value={each.licenseType}
                                        defaultValue={license.licenseType}
                                        hidden={
                                            each.licenseType !== license.licenseType &&
                                            props.selectedLicenses.some((item) => item.licenseType === each.licenseType)
                                        }
                                    >
                                        {each.productName}
                                    </option>
                                ))}
                            </select>
                        </Tooltip>,
                        <div
                            key={"available" + license.index}
                            className={style.label}
                            data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.availableLabel}
                        >
                            {(!isUserParentInternal() || hasTenantCookie()) &&
                            (isSubscriptionLicense(license.licenseType) ||
                                FEATURE_LICENSES.includes(license.licenseType))
                                ? t("DeliveryHistory.addLicenseDelivery.table.subscription", {
                                      totalSubscriptionLicenses: license.available,
                                  })
                                : license.available}
                        </div>,
                        <div key={"assigned" + license.index}>
                            <input
                                autoFocus
                                className={classNames(form.input, style.inputWidth, style.fixedWidth)}
                                key={"added" + license.index}
                                id={"added" + license.index}
                                type={"number"}
                                onChange={(e) => {
                                    props.setSelectedLicenses(
                                        props.selectedLicenses.map((each) => {
                                            if (each.index === license.index) {
                                                each.licensesToAdd = parseInt(e.target.value);
                                                each.totalOfLicenses = isNaN(each.licensesToAdd)
                                                    ? each.available
                                                    : each.available + each.licensesToAdd;
                                            }
                                            return each;
                                        })
                                    );
                                }}
                                value={license.licensesToAdd.toString()}
                                data-testid={
                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog.assignedAmountInput
                                        .itself
                                }
                            />
                            {displayAmountErrorLabel(license, t)}
                        </div>,
                        <div
                            key={"total" + license.index}
                            className={style.totalLabel}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.totalOfLicensesLabel
                                    .itself
                            }
                        >
                            {license.totalOfLicenses}
                            {license.totalOfLicenses < 0 ? (
                                <div
                                    className={form.error}
                                    data-testid={
                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                            .totalOfLicensesLabel.errorLabel
                                    }
                                >
                                    <span>{t("DeliveryHistory.addLicenseDelivery.validation.totalOfLicenses")}</span>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>,
                        <div
                            key={"remaining" + license.index}
                            className={style.label}
                            data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.availableLabel}
                        >
                            {license.licensesToAdd
                                ? license.parentAvailableAmount - license.licensesToAdd
                                : license.parentAvailableAmount}
                        </div>,
                        <div key={"expirationDate" + license.index} className={style.gridColumns}>
                            <div className={style.gridRows}>
                                <input
                                    id={"expirationInput" + license.index}
                                    type="date"
                                    className={classNames(form.input, style.inputWidth, style.customDateInput, {
                                        [form.inputError]: !isExpirationDateValid(
                                            license.expirationDate,
                                            formatExpirationDate(
                                                license.parentLicenseExpirationDate
                                                    ? license.parentLicenseExpirationDate
                                                    : lastDate()
                                            )
                                        ),
                                    })}
                                    onChange={(e) => {
                                        props.setSelectedLicenses(
                                            props.selectedLicenses.map((each) => {
                                                if (each.index === license.index) {
                                                    each.expirationDate = e.target.value;
                                                }
                                                return each;
                                            })
                                        );
                                    }}
                                    max={formatExpirationDate(
                                        license.parentLicenseExpirationDate
                                            ? license.parentLicenseExpirationDate
                                            : lastDate()
                                    )}
                                    value={formatExpirationDate(license.expirationDate)}
                                    data-testid={
                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                            .expirationDateInput.itself
                                    }
                                />
                                {isExpirationDateValid(
                                    license.expirationDate,
                                    formatExpirationDate(
                                        license.parentLicenseExpirationDate
                                            ? license.parentLicenseExpirationDate
                                            : lastDate()
                                    )
                                ) ? null : (
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .expirationDateInput.errorLabel
                                        }
                                    >
                                        <span className={style.wrapped}>
                                            {t("DeliveryHistory.addLicenseDelivery.validation.expirationDate", {
                                                minDate: MIN_DATE.toLocaleDateString(),
                                                maxDate: formatExpirationDateWithoutTime(
                                                    license.parentLicenseExpirationDate
                                                        ? license.parentLicenseExpirationDate
                                                        : lastDate()
                                                ),
                                            })}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div key={"deleteIcon" + license.index} className={style.gridRows}>
                                {props.selectedLicenses.length > 1 ? (
                                    <div
                                        key={"deleteRow" + license.index}
                                        className={classNames(style.margin, buttons.textButton)}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.LICENSE_DELIVERY,
                                                action: Action.REMOVE_LICENSE,
                                                label: license.licenseType,
                                            });
                                            removeRow(license.index);
                                        }}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .removeRowButton
                                        }
                                    >
                                        <DeleteIcon
                                            color={props.theme.contentBackgroundColor}
                                            linecolor={props.theme.iconFillColor}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>,
                    ];
                })}
            />
            <div>
                <button
                    onClick={addRow}
                    className={classNames(style.link, buttons.textButton)}
                    data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.addLicenseButton}
                    hidden={isAddMoreButtonHidden()}
                    type="button"
                >
                    {addMoreLicenses(
                        props.theme.contentBackgroundColor,
                        props.theme.iconFillColor,
                        t("Common.addMoreLicenses")
                    )}
                </button>
            </div>
        </>
    );
};

export default connector(LicenseSelectionForm);
