import { CustomReportView, JobDetail, JobDetailDto, PathType, toPathType } from "domain/reports";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export interface FetchReportViewsResponseDto {
    report_views: CustomReportView[];
}

export interface FetchReportViewsResponse {
    reportViews: CustomReportView[];
}

export interface CreateReportViewResponse {
    reportView: {
        uuid: string;
    };
}

export interface CreateReportViewResponseDto {
    report_view: {
        uuid: string;
    };
}

export interface ReportViewDto {
    name: string;
    shared: boolean;
    columns: string[];
    filters?: CustomReportViewFilter[];
}

export interface AdvanceSearchDto {
    filters: CustomReportViewFilter[];
}

const STARTS_WITH_FILTER_OPERATORS = ["VALUE_STARTS_WITH", "WORD_STARTS_WITH"] as const;
const FILTER_OPERATORS = [...STARTS_WITH_FILTER_OPERATORS, "MATCH", "EQUAL", "EXIST"] as const;
export type ReportViewFilterOperator = typeof FILTER_OPERATORS[number];

export function toFilterOperator(value?: string | null): ReportViewFilterOperator {
    if (value == null) {
        return "MATCH";
    }
    // This is possibly a bit paranoid. For any given enum returned from
    // backend, it's always possible that the string isn't a recognized enum
    // property. This way we'll immediately throw an exception when it
    // isn't.
    const found = FILTER_OPERATORS.find((each) => each === value);
    if (found != null) {
        return found;
    }
    throw Error("Unknown FilterOperator: " + value);
}

export function isStartsWithFilterOperator(operator: ReportViewFilterOperator): boolean {
    for (const startsWithOperator of STARTS_WITH_FILTER_OPERATORS) {
        if (startsWithOperator == operator) {
            return true;
        }
    }
    return false;
}

interface CommonCustomReportViewFilter {
    name: string;
    value: string;
    operator: ReportViewFilterOperator;
}

export interface CustomReportViewFilter extends CommonCustomReportViewFilter {
    path_type: string;
}

export function toCustomReportViewFilterDto(filter: CustomReportViewFilter) {
    return {
        name: filter.name,
        value: filter.value,
        operator: filter.operator,
        path_type: filter.path_type,
    };
}

interface CommonAdvanceSerchFilter {
    index: number;
    condition: string;
    name: string;
    value: string;
    operator: ReportViewFilterOperator;
}

export interface AdvanceSerchFilter extends CommonAdvanceSerchFilter {
    path_type: string;
}

export function toAdvanceSerchFilterDto(filter: AdvanceSerchFilter) {
    return {
        index: filter.index,
        condition: filter.condition,
        name: filter.name,
        value: filter.value,
        operator: filter.operator,
        path_type: filter.path_type,
    };
}

export interface Product {
    id: string;
    name: string;
}

export interface CreatedReportView {
    name: string;
    shared: boolean;
    columns: string[];
    filters: CustomReportViewFilter[];
}

export interface ImportReportView {
    name: string;
    columns: string[];
    filters: CustomReportViewFilter[];
}

interface CommonReportPath {
    origin: string;
    path: string;
}

interface ReportPathDto extends CommonReportPath {
    product_ids: string[];
    path_type: string;
    add_date: string;
}

export interface ReportPath extends CommonReportPath {
    productIds: string[];
    pathType: string;
    addDate: string;
}

function toCreateReportViewResponse(responseDto: CreateReportViewResponseDto) {
    return {
        reportView: {
            uuid: responseDto.report_view.uuid,
        },
    };
}

function toJob(dto: JobDetailDto): JobDetail {
    return {
        filename: dto.filename,
        status: dto.status,
        timestamp: dto.timestamp,
        tenantUuid: dto.tenant_uuid,
        userUuid: dto.user_uuid,
        totalReportsProcessed: dto.total_reports_processed,
        presignedUrl: dto.presigned_url,
        totalReportsExported: dto.total_reports_exported,
        creationDate: dto.creation_date,
        format: dto.format,
        jobUuid: dto.job_uuid,
        totalReports: dto.total_reports,
        linkExpirationDate: dto.link_expiration_date,
        totalFailures: dto.total_failures,
    };
}

export function toFetchReportViewsResponse(responseDto: FetchReportViewsResponseDto, paths: ReportPath[]) {
    const pathToType = paths.reduce(
        (map, each) => map.set(each.path, toPathType(each.pathType)),
        new Map<string, PathType>()
    );
    const views: CustomReportView[] = responseDto.report_views.map((each) => ({
        ...each,
        // At least within dev environment users may have persisted views in
        // DynamoDB that begin with "blancco_diagnostic_report.".
        columns: each.columns.map((column) => column.replace(/^blancco_diagnostic_report\./, "")),
        filters: (each.filters ?? []).map((each) => ({
            ...each,
            operator: toFilterOperator(each.operator),
            // Early on filters were defined without path types.
            path_type: each.path_type ?? pathToType.get(each.name),
        })),
    }));
    return {
        reportViews: views,
    };
}

function toReportPaths(paths: ReportPathDto[]): ReportPath[] {
    // Trimming might be a little paranoid but frontend should be more robust with it.
    return paths.map((each) => ({
        origin: each.origin.trim(),
        path: each.path.trim(),
        // Product IDs used to be numeric so here we're preparing for that,
        // making sure that they are strings.
        productIds: (each.product_ids ?? []).map((each) => each.toString()),
        pathType: each.path_type.trim(),
        addDate: each.add_date.trim(),
    }));
}

export function isReportViewDetails(candidate: unknown): candidate is ImportReportView {
    if (typeof candidate !== "object" || candidate == null) {
        return false;
    }
    const record = candidate as Record<string, unknown>;
    for (const property of Object.keys(candidate)) {
        if (record[property] == null) {
            return false;
        }
    }
    return true;
}

/**
 * Drop duplicate paths in reportView while keeping the order.
 */
export function dropDuplicatePaths(reportView: CustomReportView): CustomReportView {
    const seen = new Set<string>();
    const trimmed: string[] = [];
    for (const each of reportView.columns) {
        if (seen.has(each)) {
            continue;
        }
        seen.add(each);
        trimmed.push(each);
    }
    return {
        ...reportView,
        columns: trimmed,
    };
}

const PATH_API_REPORT_PATH = "/api/report-path";
const PATH_API_REPORT_VIEWS = "/api/report-views";

export interface ReportPathFilters {
    [key: string]: string;
}

class ReportViewService {
    private reportPaths: ReportPath[] = [];

    /**
     * Fetch report views.
     *
     * Note! Before this method is called, refreshPaths must have been called first.
     */
    public fetchViews(abortController?: AbortController): Promise<FetchReportViewsResponse> {
        return (
            apiGatewayService
                .invokeApi(PATH_API_REPORT_VIEWS, "GET", null, {
                    abortController: abortController,
                    refreshSession: true,
                    apiType: ApiType.LAUREL,
                })
                .then((responseDto: FetchReportViewsResponseDto) =>
                    toFetchReportViewsResponse(responseDto, this.reportPaths)
                )
                // This shouldn't be necessary but frontend has at some point
                // allowed creation of views that contain duplicate
                // paths/columns. If we don't remove the duplicates,
                // frontend code will crash later on in Table component.
                .then((response) => ({ reportViews: response.reportViews.map((each) => dropDuplicatePaths(each)) }))
        );
    }

    public fetchExportNotificationDetails(jobId: string, abortController?: AbortController): Promise<JobDetail> {
        return apiGatewayService
            .invokeApi("/api/export-jobs/" + jobId, "GET", null, {
                abortController,
                apiType: ApiType.LAUREL,
            })
            .then((response: JobDetailDto) => {
                return toJob(response);
            });
    }

    public createView(data: CreatedReportView, abortController: AbortController): Promise<CreateReportViewResponse> {
        const dto: ReportViewDto = Object.assign(
            {},
            {
                name: data.name,
                shared: data.shared,
                columns: data.columns,
            },
            data.filters != null && data.filters.length > 0
                ? { filters: data.filters.map(toCustomReportViewFilterDto) }
                : {}
        );
        return apiGatewayService
            .invokeApi(PATH_API_REPORT_VIEWS, "POST", dto, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.LAUREL,
            })
            .then((responseDto: CreateReportViewResponseDto) => toCreateReportViewResponse(responseDto));
    }

    public refreshPaths(abortController?: AbortController, requestParameters?: ReportPathFilters): Promise<void> {
        const keyValuePairs: string[] = [];
        if (typeof requestParameters !== "undefined") {
            Object.keys(requestParameters).forEach((key) => {
                keyValuePairs.push(encodeURI(`${key}=${requestParameters[key]}`));
            });
        }
        let path = PATH_API_REPORT_PATH;
        if (keyValuePairs.length > 0) {
            path = path + "?" + keyValuePairs.join("&");
        }
        return apiGatewayService
            .invokeApi(path, "GET", null, { apiType: ApiType.LAUREL, abortController })
            .then((dto: { paths: ReportPathDto[] }) => toReportPaths(dto.paths))
            .then((fetchedPaths) => {
                const mapped = fetchedPaths.reduce(
                    (accruing, each) => accruing.set(each.path, each),
                    new Map<string, ReportPath>()
                );
                this.reportPaths = Array.from(mapped.values());
                return Promise.resolve();
            });
    }

    public getPaths(): ReportPath[] {
        return this.reportPaths;
    }

    public deleteView(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(PATH_API_REPORT_VIEWS + "/" + uuid, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.LAUREL,
        });
    }

    public editView(
        uuid: string,
        name: string,
        shared: boolean,
        columns: string[],
        filters: CustomReportViewFilter[] | undefined,
        abortController: AbortController
    ): Promise<void> {
        const postData = Object.assign(
            {},
            { name, shared, columns },
            filters == null ? {} : { filters: filters.map(toCustomReportViewFilterDto) }
        );
        return apiGatewayService.invokeApi(PATH_API_REPORT_VIEWS + "/" + uuid, "POST", postData, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.LAUREL,
        });
    }
}

export const reportViewService = new ReportViewService();
