import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { CHECKMARK_SIZE, STROKE_WIDTH, updateSelectedTierTitle } from "./PricingTierView";
import Checkmark from "components/icons/Checkmark";
import Diamond from "components/icons/Diamond";
import Premium from "components/icons/Premium";
import Sparkle from "components/icons/Sparkle";
import { CorporateBundle, ProcessorBundle } from "components/licenses/bundles";
import StaticTable from "components/support/api-guide/StaticTable";
import style from "components/tenants/pricing-tier-view.scss";
import { CombinedTier } from "domain/tenants";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    tenantCreation?: boolean;
    corporate?: boolean;
    selectedTier: CombinedTier;
}

enum BmpFeatures {
    LICENSE_ALERTS = "License alerts",
    LICENSE_VIEW_AND_ENTITLEMENTS = "Blancco license view and entitlements",
    LICENSE_POOLS = "License pools",
    LICENSE_CONSUMPTION = "License consumption",
    PRODUCT_DOWNLOADS = "Product downloads",
    KNOWLEDGEBASE = "Knowledge base",
    TECHNICAL_SUPPORT_SP1 = "Technical Support SP1",
    TECHNICAL_SUPPORT_SP2 = "Technical Support SP2",
    TECHNICAL_SUPPORT_SPE = "Technical Support SPE",
    KEY_VALUE_STORE = "Key value store",
    BMP_APIS = "BMP APIs",
    CENTRALIZED_REPORTING = "Centralized reporting",
    REPORTING_CUSTOM_VIEWS = "Reporting custom views",
    SUSTAINABILITY_DASHBOARD = "Sustainability dashboard",
    WORKFLOWS = "Intelligent Business Routing (IBR / Workflows)",
    REPORT_CUSTOMIZATION = "Report template library",
    OVERVIEW_DASHBOARD = "Overview dashboard",
    SSO = "Single sign-on (SSO)",
    TENANT_MANAGEMENT = "Tenant management",
    USER_GROUPS = "User groups",
    CUSTOM_ROLES = "Custom roles",
    BMC = "Blancco Management Console",
}

interface TierFeature {
    name: string;
    corporateBundles: CorporateBundle[];
    processorBundles: ProcessorBundle[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const NewModelPricingTierView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const allCorporateBundles = [CorporateBundle.ESSENTIALS, CorporateBundle.POWER, CorporateBundle.ENTERPRISE];
    const allProcessorBundles = [ProcessorBundle.CORE, ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE];
    const availableFeatures: TierFeature[] = [
        {
            name: BmpFeatures.LICENSE_VIEW_AND_ENTITLEMENTS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.LICENSE_ALERTS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.LICENSE_POOLS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.LICENSE_CONSUMPTION,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.PRODUCT_DOWNLOADS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.KNOWLEDGEBASE,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.CUSTOM_ROLES,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.KEY_VALUE_STORE,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.CENTRALIZED_REPORTING,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.SSO,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.REPORTING_CUSTOM_VIEWS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
        },
        {
            name: BmpFeatures.BMP_APIS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.SUSTAINABILITY_DASHBOARD,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.WORKFLOWS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.USER_GROUPS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.OVERVIEW_DASHBOARD,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.REPORT_CUSTOMIZATION,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.TENANT_MANAGEMENT,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SP1,
            corporateBundles: [CorporateBundle.POWER],
            processorBundles: [ProcessorBundle.PRO],
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SP2,
            corporateBundles: [CorporateBundle.ESSENTIALS],
            processorBundles: [ProcessorBundle.CORE],
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SPE,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
        },
    ];

    function deduceTierTranslationKey(
        tierType: string,
        selected: boolean
    ):
        | {
              translationKey: string;
              icon: JSX.Element;
          }
        | undefined {
        const corporateBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                CorporateBundle.ESSENTIALS.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.essentials"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Sparkle />,
                },
            ],
            [
                CorporateBundle.POWER.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.power"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Premium />,
                },
            ],
            [
                CorporateBundle.ENTERPRISE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.enterprise"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
        ]);
        if (props.corporate) {
            return corporateBundlesToTranslationKey.get(tierType);
        }
        const processorBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                ProcessorBundle.CORE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.core"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Sparkle />,
                },
            ],
            [
                ProcessorBundle.PRO.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.pro"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Premium />,
                },
            ],
            [
                ProcessorBundle.PRO_SCALE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.proScale"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
        ]);
        return processorBundlesToTranslationKey.get(tierType);
    }

    function createHeaders() {
        const tiers = props.corporate ? Object.keys(CorporateBundle) : Object.keys(ProcessorBundle);
        const headers = [];
        headers.push({
            className: style.firstColumnHeader,
            value: t("AddCustomerForm.pricingTierDialog.header.feature"),
        });
        tiers.forEach((each, index) => {
            const tierAndIcon = deduceTierTranslationKey(each, props.selectedTier === each);
            headers.push({
                value: (
                    <div className={style.headerContaner}>
                        <div
                            className={
                                index == 0 || index == tiers.length - 1 ? style.headerIcon : style.secondHeaderIcon
                            }
                        >
                            {tierAndIcon?.icon}
                        </div>
                        {tierAndIcon?.translationKey}
                    </div>
                ),
            });
        });
        return headers;
    }

    function displayAvailability(
        tier: CorporateBundle | ProcessorBundle,
        corporateBundles: CorporateBundle[],
        processorBundles: ProcessorBundle[]
    ) {
        return props.corporate
            ? corporateBundles.includes(tier as CorporateBundle)
            : processorBundles.includes(tier as ProcessorBundle);
    }

    function generateCellData(tiers: CorporateBundle[] | ProcessorBundle[]) {
        if (!props.corporate) {
            availableFeatures.push({
                name: BmpFeatures.BMC,
                corporateBundles: [],
                processorBundles: [ProcessorBundle.PRO_SCALE],
            });
        }
        const tierIndexToColorMap = new Map<number, { backgroundColor: string; iconColor: string }>([
            [0, { backgroundColor: style.tierFirstLevelColor, iconColor: props.theme.basicTierBannerBorderColor }],
            [1, { backgroundColor: style.tierSecondLevelColor, iconColor: props.theme.premiumTierBannerBorderColor }],
            [2, { backgroundColor: style.tierThirdLevelColor, iconColor: props.theme.diamondTierBannerBorderColor }],
        ]);
        return availableFeatures.map((eachLicense) => {
            return [
                <td key={"row" + "-" + eachLicense.name}>
                    <div key={eachLicense.name}>{eachLicense.name}</div>
                </td>,
                ...tiers.map((eachTier, index) => {
                    const tierColor = tierIndexToColorMap.get(index);
                    const backgroundColor = tierColor?.backgroundColor || "";
                    const iconColor = tierColor?.iconColor || "";
                    return (
                        <td
                            key={eachTier + "-" + eachLicense}
                            className={classNames(
                                //[style.tableDataBorder]:  index != tiers.length,
                                { [backgroundColor]: props.selectedTier === eachTier }
                            )}
                        >
                            <div className={style.checkMarkCenter}>
                                {displayAvailability(
                                    eachTier,
                                    eachLicense.corporateBundles,
                                    eachLicense.processorBundles
                                ) ? (
                                    <Checkmark color={iconColor} size={CHECKMARK_SIZE} strokeWidth={STROKE_WIDTH} />
                                ) : null}
                            </div>
                        </td>
                    );
                }),
            ];
        });
    }

    return (
        <div className={style.tableContainer}>
            <StaticTable
                headers={createHeaders()}
                styledCells={generateCellData(props.corporate ? allCorporateBundles : allProcessorBundles)}
                tableClass={style.table}
                testId={testIds.workArea.tenant.pricingTierDialog.table.itself}
            />
        </div>
    );
};

export default connector(NewModelPricingTierView);
