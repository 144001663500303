import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { DateRangePicker } from "rsuite";
import { DateRange, RangeType } from "rsuite/esm/DateRangePicker";
import {
    addDays,
    addMonths,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subDays,
} from "rsuite/esm/utils/dateUtils";
import { PickerHandle } from "rsuite/Picker";

import crossIconStyle from "./all-workflows-table.scss";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import formStyle from "styles/form.scss";
import { formatDate, formatDateWithoutTime } from "utils/format";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Props {
    from: (date: string) => void;
    to: (date: string) => void;
}

const OVERLAY_CLOSE = false;
const OVERLAY_PLACEMENT = "left";

export enum DateRangeSelection {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    THIS_WEEK = "THIS_WEEK",
    LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH",
    LAST_THIRTY_DAYS = "LAST_THIRTY_DAYS",
    THIS_YEAR = "THIS_YEAR",
    LAST_YEAR = "LAST_YEAR",
}

enum LocalizationKey {
    TODAY = "Common.dateRanges.today",
    YESTERDAY = "Common.dateRanges.yesterday",
    THIS_WEEK = "Common.dateRanges.thisWeek",
    LAST_SEVEN_DAYS = "Common.dateRanges.lastSevenDays",
    THIS_MONTH = "Common.dateRanges.thisMonth",
    LAST_MONTH = "Common.dateRanges.lastMonth",
    LAST_THIRTY_DAYS = "Common.dateRanges.lastThirtyDays",
    THIS_YEAR = "Common.dateRanges.thisYear",
    LAST_YEAR = "Common.dateRanges.lastYear",
}

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

const connector = connect(mapState, { applyMode });

const WorkflowEventDatePicker = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const dateRangePicker = React.useRef<PickerHandle>(null);
    const [dateRange, setDateRange] = React.useState({
        from: "",
        to: "",
    });

    function toConflictAdjustedDate(date: Date): Date {
        const result = new Date(date);
        result.setSeconds(date.getSeconds() - 1);
        return result;
    }

    const today: DateRange = [startOfDay(new Date()), endOfDay(new Date())];
    const yesterday: DateRange = [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))];
    const thisWeek: DateRange = [startOfISOWeek(new Date()), endOfISOWeek(new Date())];
    const lastSevenDays: DateRange = [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())];
    const thisMonth: DateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
    const lastMonth: DateRange = [
        startOfMonth(addMonths(new Date(), -1)),
        toConflictAdjustedDate(endOfMonth(addMonths(new Date(), -1))),
    ];
    const lastThirtyDays: DateRange = [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())];
    const thisYear: DateRange = [
        startOfMonth(new Date(new Date().getFullYear(), 0)),
        endOfMonth(new Date(new Date().getFullYear(), 11)),
    ];
    const lastYear: DateRange = [
        startOfMonth(new Date(new Date().getFullYear() - 1, 0)),
        endOfMonth(new Date(new Date().getFullYear() - 1, 11)),
    ];

    const selectableRanges: RangeType[] = [];
    const localizationToStatisticsLabelMap = new Map<string, Label>();
    const dateRangeSelectionToDateRangeMap = new Map<DateRangeSelection, DateRange>();
    [
        [t(LocalizationKey.TODAY), Label.TODAY, DateRangeSelection.TODAY, today],
        [t(LocalizationKey.YESTERDAY), Label.YESTERDAY, DateRangeSelection.YESTERDAY, yesterday],
        [t(LocalizationKey.THIS_WEEK), Label.THIS_WEEK, DateRangeSelection.THIS_WEEK, thisWeek],
        [t(LocalizationKey.LAST_SEVEN_DAYS), Label.LAST_SEVEN_DAYS, DateRangeSelection.LAST_SEVEN_DAYS, lastSevenDays],
        [t(LocalizationKey.LAST_SEVEN_DAYS), Label.LAST_SEVEN_DAYS, DateRangeSelection.LAST_SEVEN_DAYS, lastSevenDays],
        [t(LocalizationKey.THIS_MONTH), Label.THIS_MONTH, DateRangeSelection.THIS_MONTH, thisMonth],
        [t(LocalizationKey.LAST_MONTH), Label.LAST_MONTH, DateRangeSelection.LAST_MONTH, lastMonth],
        [
            t(LocalizationKey.LAST_THIRTY_DAYS),
            Label.LAST_THIRTY_DAYS,
            DateRangeSelection.LAST_THIRTY_DAYS,
            lastThirtyDays,
        ],
        [t(LocalizationKey.THIS_YEAR), Label.THIS_YEAR, DateRangeSelection.THIS_YEAR, thisYear],
        [t(LocalizationKey.LAST_YEAR), Label.LAST_YEAR, DateRangeSelection.LAST_YEAR, lastYear],
    ].map((rangeSpecification: [string, Label, DateRangeSelection, DateRange]) => {
        selectableRanges.push({
            label: rangeSpecification[0],
            value: rangeSpecification[3],
            placement: OVERLAY_PLACEMENT,
            closeOverlay: OVERLAY_CLOSE,
        });
        localizationToStatisticsLabelMap.set(rangeSpecification[0], rangeSpecification[1]);
        dateRangeSelectionToDateRangeMap.set(rangeSpecification[2], rangeSpecification[3]);
    });

    React.useEffect(() => {
        let dateRangeInput: Element | null | undefined;
        try {
            dateRangeInput = dateRangePicker.current?.target
                ?.getElementsByClassName("rs-picker-toggle-textbox rs-picker-toggle-read-only")
                .item(0);
            if (dateRangeInput != null) {
                dateRangeInput.setAttribute("data-testid", testIds.workArea.license.licenseEvents.dateRangePickerInput);
            }

            const closeButton = document.getElementsByClassName(
                "rs-picker-toggle-clean rs-btn-close"
            )[0] as HTMLElement;
            if (closeButton) {
                closeButton.setAttribute("class", crossIconStyle.dragCross);
                closeButton.setAttribute("alt", t("AltText.clearIcon"));
            }
        } catch (error) {
            logger.error(
                "Failed to find the input element when trying to set its test ID for date range picker.",
                error
            );
        }
    });

    return (
        <>
            <DateRangePicker
                ref={dateRangePicker}
                className={classNames(formStyle.dateRange)}
                editable={false}
                ranges={selectableRanges}
                placement={"autoVertical"}
                onOk={(date: DateRange) => {
                    const start = formatDate(moment(date[0]).startOf("day"));
                    const end = formatDate(moment(date[1]).endOf("day"));
                    props.from(start);
                    props.to(end);
                    setDateRange({
                        from: start,
                        to: end,
                    });
                    usageStatisticsService.sendEvent({
                        category: Category.LICENSE_EVENTS,
                        action: Action.FILTER_BY_DATE,
                    });
                }}
                renderValue={(value: DateRange) => {
                    return `${formatDateWithoutTime(value[0].toString())} - ${formatDateWithoutTime(
                        value[1].toString()
                    )}`;
                }}
                placeholder={
                    dateRange.to == ""
                        ? t("WorkflowsTable.filter.selectDate")
                        : formatDateWithoutTime(dateRange.from) + " - " + formatDateWithoutTime(dateRange.to)
                }
                onClean={() => {
                    props.from("");
                    props.to("");
                    setDateRange({
                        from: "",
                        to: "",
                    });
                }}
            />
        </>
    );
};

export default connector(WorkflowEventDatePicker);
