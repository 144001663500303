import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";

import UserKebabMenu from "./UserKebabMenu";
import style from "./users.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import { AddIcon } from "components/icons/AddIcon";
import Checked from "components/icons/Checked";
import GroupIcon from "components/icons/GroupIcon";
import IndeterminateBox from "components/icons/IndeterminateBox";
import Unchecked from "components/icons/Unchecked";
import KebabMenu from "components/kebab-menu/KebabMenu";
import addIconStyle from "components/licenses/delivery-history/add-license-delivery.scss";
import AssignLicensePoolDialog from "components/licenses/licenses-pool/AssignLicensePoolDialog";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import tableStyle from "components/reports/erasure-reports-table.scss";
import SearchView from "components/search/SearchView";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { generateUserTypesToTranslationMap } from "components/users/add-user/AddUserForm";
import {
    AUTH_LICENSE_POOL_CREATE,
    AUTH_LICENSE_POOL_DELETE,
    AUTH_LICENSE_POOL_EDIT,
    AUTH_LICENSE_POOL_VIEW,
    AUTH_USER_DELETE,
    AUTH_USER_EDIT,
    AUTH_USER_GROUP_CREATE,
    AUTH_USER_GROUP_DELETE,
    AUTH_USER_GROUP_EDIT,
    AUTH_USER_GROUP_VIEW,
} from "domain/authority";
import { ssoLoginMethodList } from "domain/tenants";
import { INTEGRATION_USER_TYPE, MACHINE_USER_TYPE, STANDARD_USER_TYPE, UserType } from "domain/user";
import { AllUserGroups } from "domain/userGroups";
import { Group, UserTableData } from "domain/users";
import { FLAG_USER_GROUPS } from "services/feature/FeatureFlagService";
import { LicensePoolListResponse, licensePoolService } from "services/licenses/LicensePoolService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantHasFeatureLicense } from "services/tenants/tenantCookieService";
import { userGroupsService } from "services/user-groups/UserGroupsService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { getInitials } from "utils/commonFunctions";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    usersData: UserTableData[];
    cursor: string;
    scrollPosition?: number;
}

interface LicensePoolList {
    poolUuid: string;
    poolName: string;
}

export interface Props {
    allLoaded: boolean;
    scrollPosition?: number;
    count: number;
    onUserDelete: () => void;
    onUserEdit: () => void;
    setUsersFetched: (fetched: boolean) => void;
    search?: string;
    onLicensePoolAssigned?: () => void;
    selectedUser: (value: string) => void;
    showDetailsView: (value: boolean) => void;
}

const connector = connect((state: StoreState) => ({
    userDetail: state.userReducer.user,
}));

function removeDuplicateUsers(previousUserData: UserTableData[], currentUserData: UserTableData[]): UserTableData[] {
    const previousEmailIds = new Set(previousUserData.map(({ email }) => email));
    const finalUserData: UserTableData[] = [
        ...previousUserData,
        ...currentUserData.filter(({ email }) => !previousEmailIds.has(email)),
    ];
    return finalUserData;
}

const UsersTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        usersData: [],
        cursor: "",
        scrollPosition: 0,
    });
    const [userGroups, setUserGroups] = React.useState<AllUserGroups>({
        cursor: "",
        groups: [],
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const [searchGroup, setSearchGroup] = React.useState("");
    const userUuid = props.userDetail ? props.userDetail.uuid : undefined;
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [assignLicensePoolFormVisible, setAssignLicensePoolFormVisible] = React.useState(false);

    const allUsersReference = React.useRef<UserTableData[]>(tableState.usersData);
    const [deselected, setDeselected] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [licensePoolsList, setLicensePoolsList] = React.useState<LicensePoolList[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchLicensePool, setSearchLicensePool] = React.useState("");
    const [licensePoolsLoading, setlicensePoolsLoading] = React.useState<boolean>(false);
    const USER_TYPE_TOOLTIPS_TO_TRANSLATION_MAP = new Map<UserType, string>([
        [STANDARD_USER_TYPE, t("UsersTable.standardUserTooltip")],
        [INTEGRATION_USER_TYPE, t("UsersTable.integrationUserTooltip")],
        [MACHINE_USER_TYPE, t("UsersTable.machineUserTooltip")],
    ]);
    const userGroupsEnabled = useFeature(FLAG_USER_GROUPS);

    function handleState() {
        setIsLoading((current) => !current);
    }

    const fetchData = (abortController: AbortController, initialLoading: boolean, reinitialized: boolean) => {
        setLoading(true);
        if (reinitialized) {
            setInitialLoading(initialLoading);
        }

        const fetchUsers = userService.fetchUsers(
            abortController,
            search,
            initialLoading ? "" : tableState.cursor,
            searchGroup,
            searchLicensePool
        );
        const fetchGroups =
            userGroupsEnabled && userGroupAccess ? userGroupsService.fetchAllGroups(abortController) : userGroups;

        Promise.all([fetchUsers, fetchGroups, fetchLicensePoolsList])
            .then(([usersData, groupsData]) => {
                allUsersReference.current = reinitialized
                    ? usersData.userTableData
                    : tableState.usersData.concat(usersData.userTableData);

                setTableState((previousState) => ({
                    scrollPosition: previousState.usersData.length - 1,
                    usersData: removeDuplicateUsers(previousState.usersData, usersData.userTableData),
                    cursor: usersData.cursor,
                }));
                setUserGroups({
                    cursor: groupsData.cursor,
                    groups: groupsData.groups,
                });
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("UsersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
                props.setUsersFetched(true);
            });
    };

    const fetchLicensePoolsList = () => {
        if (!licensePoolsAccess) {
            return;
        }
        setlicensePoolsLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licensePoolService.fetchLicensePoolsList(abortController).then((data: LicensePoolListResponse) => {
            const licensePool = data.pools.map((each) => {
                return {
                    poolUuid: each.poolUuid,
                    poolName: each.poolName,
                };
            });
            setlicensePoolsLoading(false);
            setLicensePoolsList(licensePool);
        });
    };

    const showGroupInitials = (groups: Group[]): JSX.Element => {
        const maximumGroupIconsCount = 4;
        return (
            <div className={style.groupIcons}>
                {groups
                    .filter((group) => group.name != null)
                    .slice(0, maximumGroupIconsCount)
                    .map((group) => {
                        return (
                            <div key={group.name}>
                                <TextWithTooltip text={group.name}>
                                    <GroupIcon
                                        backgroundColor={group.backgroundColor}
                                        textColor={group.textColor}
                                        groupInitials={getInitials(group.name)}
                                    />
                                </TextWithTooltip>
                            </div>
                        );
                    })}
                {groups.length > maximumGroupIconsCount && (
                    <div className={style.remainingGroupsCount}>
                        {t("UsersTable.remainingGroupsCount", {
                            remainingGroupsCount: groups.length - maximumGroupIconsCount,
                        })}
                    </div>
                )}
            </div>
        );
    };

    function findSelectedUserUuids(users: UserTableData[]): string[] {
        const foundUuids: string[] = [];
        users.forEach((each) => {
            if (each.checked && each.uuid != userUuid) {
                foundUuids.push(each.uuid);
            }
        });
        return foundUuids;
    }

    const isUuidSelected = (uuid: string): boolean => {
        return typeof allUsersReference.current.find((each) => each.uuid === uuid && each.checked) !== "undefined";
    };

    function isUserTypeStandard(userType: string): boolean {
        return userType == STANDARD_USER_TYPE;
    }

    const updateTableStateBasedOnUuid = (checkedValue: boolean, selectedUuid: string) => {
        setTableState((prevState) => ({
            ...prevState,
            usersData: prevState.usersData.map((each) => {
                if (each.uuid === selectedUuid) {
                    each.checked = checkedValue;
                }
                return each;
            }),
            scrollPosition: 0,
        }));
    };

    const updateUserState = (checkedValue: boolean) => {
        setTableState((prevState) => ({
            ...prevState,
            usersData: prevState.usersData.map((each) => {
                each.checked = checkedValue;

                return each;
            }),
        }));
    };

    function fetchUniqueUsers(): UserTableData[] {
        const emails = new Set();

        return allUsersReference.current.filter((user) => {
            if (emails.has(user.email)) {
                return false;
            } else {
                emails.add(user.email);
                return true;
            }
        });
    }

    const hasAllUsersSelected = (): boolean => {
        const foundUsers: UserTableData[] = [];
        const uniqueUsers: UserTableData[] = fetchUniqueUsers();

        allUsersReference.current.forEach((each) => {
            if (each.checked) {
                foundUsers.push(each);
            }
        });
        return foundUsers.length == uniqueUsers.length;
    };

    const isAnyUsersSelected = (): boolean => {
        return typeof allUsersReference.current.find((each) => each.checked) !== "undefined";
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
        setDeselected(false);
        if (event.target.checked) {
            updateTableStateBasedOnUuid(true, uuid);
        } else {
            updateTableStateBasedOnUuid(false, uuid);
        }
    };

    const createSelectionCheckbox = (uuid: string) => {
        return (
            <label className={tableStyle.container}>
                <input
                    data-testid={testIds.common.primaryView.table.selectRowCheckbox}
                    key={uuid}
                    name="uuids"
                    id={uuid}
                    type="checkbox"
                    checked={isUuidSelected(uuid) && !deselected}
                    value={uuid}
                    onChange={(e) => handleCheckboxChange(e, uuid)}
                />
                <span className={tableStyle.checkmark} />
            </label>
        );
    };

    const isLoggedInUserSelected = () => {
        const foundUsers: UserTableData[] = [];
        const foundUncheckedUsers: UserTableData[] = [];
        let userUuidUnChecked = "";
        allUsersReference.current.forEach((each) => {
            if (each.checked) {
                foundUsers.push(each);
            } else {
                foundUncheckedUsers.push(each);
                userUuidUnChecked = each.uuid;
            }
        });
        return userUuidUnChecked == userUuid && foundUsers.length == 0 && foundUncheckedUsers.length == 0;
    };

    const authorized = userSessionService.userHasAnyAuthority([
        AUTH_LICENSE_POOL_CREATE,
        AUTH_LICENSE_POOL_EDIT,
        AUTH_LICENSE_POOL_VIEW,
        AUTH_LICENSE_POOL_DELETE,
    ]);

    const userGroupAccess =
        tenantHasFeatureLicense("FEATURE_USER_GROUPS") &&
        userSessionService.userHasAnyAuthority([
            AUTH_USER_GROUP_CREATE,
            AUTH_USER_GROUP_DELETE,
            AUTH_USER_GROUP_EDIT,
            AUTH_USER_GROUP_VIEW,
        ]);

    const hasLicensePoolFeature = tenantHasFeatureLicense("FEATURE_LICENSE_POOLS");

    const canAssignLicensePool = userSessionService.userHasAnyAuthority([AUTH_USER_EDIT, AUTH_LICENSE_POOL_VIEW]);

    const licensePoolsAccess = hasLicensePoolFeature && authorized;

    const columns: Array<Column<UserTableData>> = [];
    hasLicensePoolFeature &&
        canAssignLicensePool &&
        columns.push({
            Header: () => (
                <div className={tableStyle.selectAllCheckbox}>
                    {!isLoggedInUserSelected() ? (
                        <label className={tableStyle.selectAllCheckboxContainer}>
                            <input
                                data-testid={testIds.common.primaryView.table.selectAllCheckbox}
                                type="checkbox"
                                checked={hasAllUsersSelected()}
                                onChange={(event) => {
                                    const allSelected = hasAllUsersSelected();
                                    if (event.target.checked) {
                                        updateUserState(true);
                                    } else {
                                        updateUserState(!allSelected);
                                    }
                                }}
                            />
                            <div className={tableStyle.customCheckboxIcon}>
                                {isAnyUsersSelected() ? (
                                    !hasAllUsersSelected() ? (
                                        <IndeterminateBox
                                            backgroundColor={theme.activeBackgroundColor}
                                            lineColor={theme.contentBackgroundColor}
                                        />
                                    ) : (
                                        <Checked
                                            backgroundColor={theme.activeBackgroundColor}
                                            checkColor={theme.contentBackgroundColor}
                                        />
                                    )
                                ) : (
                                    <Unchecked
                                        borderColor={theme.textFieldColor}
                                        backgroundColor={theme.inputBackgroundColor}
                                    />
                                )}
                            </div>
                        </label>
                    ) : (
                        ""
                    )}
                </div>
            ),
            accessor: "checked",
            disableSortBy: true,
            minWidth: 70,
            maxWidth: 70,
            Cell: ({
                cell: {
                    row: { original: userData },
                },
            }) => {
                return (
                    <>
                        <div className={classNames(tableStyle.alignment)}>
                            <div className={classNames(style.tableDetailsGrid, tableStyle.gridContentSpaceBetween)}>
                                <div className={tableStyle.flexDisplay}>
                                    {userUuid != userData.uuid && createSelectionCheckbox(userData.uuid)}
                                </div>
                            </div>
                        </div>
                    </>
                );
            },
        });

    columns.push({
        Header: () => <TextWithTooltip text={t("Common.name")} key="name" />,
        accessor: "name",
        Cell: (cellInfo) => (
            <div className={classNames(tableStyle.alignment)}>
                {userSessionService.userHasAnyAuthority([AUTH_USER_DELETE, AUTH_USER_EDIT]) &&
                    userUuid != cellInfo.cell.row.original.uuid && (
                        <div className={classNames(tableStyle.flexDisplay, tableStyle.marginRight)}>
                            <KebabMenu>
                                <UserKebabMenu
                                    uuid={cellInfo.cell.row.original.uuid}
                                    userName={
                                        typeof cellInfo.value === "undefined" || cellInfo.value.trim() === ""
                                            ? cellInfo.cell.row.original.email
                                            : cellInfo.value
                                    }
                                    userRoleUuid={cellInfo.cell.row.original.roleUuid}
                                    email={cellInfo.cell.row.original.email}
                                    expirationDate={cellInfo.cell.row.original.expirationDate}
                                    onUserDelete={props.onUserDelete}
                                    onUserEdit={props.onUserEdit}
                                    enabled={cellInfo.cell.row.original.enabled}
                                    emailVerified={cellInfo.cell.row.original.emailVerified}
                                    loginMethod={cellInfo.cell.row.original.loginMethod}
                                    userLicensePoolUuid={cellInfo.cell.row.original.poolName}
                                    userType={cellInfo.cell.row.original.userType}
                                />
                            </KebabMenu>
                        </div>
                    )}
                <div
                    className={
                        !isUserTypeStandard(cellInfo.cell.row.original.userType)
                            ? style.nameContainer
                            : style.nameContainerStandardUser
                    }
                >
                    <button
                        className={style.userNameCell}
                        onClick={() => {
                            props.selectedUser(cellInfo.cell.row.original.uuid);
                            props.showDetailsView(true);
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                    {!isUserTypeStandard(cellInfo.cell.row.original.userType) && (
                        <TextWithTooltip
                            text={USER_TYPE_TOOLTIPS_TO_TRANSLATION_MAP.get(cellInfo.cell.row.original.userType)}
                            key={"userTypeTooltip"}
                        >
                            <span className={style.userTypeContainer}>
                                {generateUserTypesToTranslationMap(t).get(cellInfo.cell.row.original.userType)}
                            </span>
                        </TextWithTooltip>
                    )}
                </div>
            </div>
        ),
        width: deriveColumnWidth(25, tableContainerRef),
    });

    userGroupsEnabled &&
        userGroupAccess &&
        columns.push({
            Header: () => <TextWithTooltip text={t("UsersTable.groups")} key="groups" />,
            accessor: "groups",
            Cell: ({ cell: { value } }) => <div>{showGroupInitials(value ? value : [])}</div>,
            width: deriveColumnWidth(18, tableContainerRef),
        });
    columns.push(
        {
            Header: () => <TextWithTooltip text={t("UsersTable.userStatus.title")} key="status" />,
            accessor: "enabled",
            Cell: ({ cell: { value } }) => (
                <StatusBadge
                    values={[
                        value
                            ? {
                                  status: Status.SUCCESS,
                                  title: t("UsersTable.userStatus.confirmed"),
                              }
                            : {
                                  status: Status.ERROR,
                                  title: t("UsersTable.userStatus.forceChangePassword"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
            width: deriveColumnWidth(12, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.email")} key="email" />,
            accessor: "email",
            Cell: ({ cell: { value } }) => {
                return (
                    <div className={style.alignment}>
                        <TextWithTooltip text={value} />
                        <CopyToClipboard value={value} />
                    </div>
                );
            },
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.emailStatus")} key="emailVerified" />,
            accessor: "emailVerified",
            Cell: ({ row }) => (
                <StatusBadge
                    values={[
                        ssoLoginMethodList.includes(row.original.loginMethod)
                            ? {
                                  status: Status.INFO,
                                  title: t("UsersTable.ssoUsers"),
                              }
                            : row.original.emailVerified
                            ? {
                                  status: Status.SUCCESS,
                                  title: t("UsersTable.emailVerified"),
                              }
                            : {
                                  status: Status.ERROR,
                                  title: t("UsersTable.emailUnverified"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
            width: deriveColumnWidth(12, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.expirationDate")} key="expirationDate" />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) =>
                value ? <DateCell tooltip={true} value={value} withoutTime={true} /> : t("Common.never"),
            width: deriveColumnWidth(18, tableContainerRef),
        }
    );

    licensePoolsAccess &&
        columns.push({
            Header: () => <TextWithTooltip text={t("UsersTable.licensePoolAssigned")} key="pool_name" />,
            accessor: "poolName",
            Cell: ({ cell: { value } }) => {
                return (
                    <div className={style.alignment}>
                        <TextWithTooltip text={value} />
                    </div>
                );
            },
            width: deriveColumnWidth(12, tableContainerRef),
        });

    const selectionInformation = userSessionService.hasFeatureLicense("FEATURE_LICENSE_POOLS") && (
        <>
            <div className={classNames(style.centeredAlignedItems, style.tableDetailsGrid)}>
                <div
                    className={tableStyle.selectInformation}
                    data-testid={testIds.common.primaryView.table.selectCountLabel}
                >
                    {t("UsersTable.information.usersSelected", {
                        total: findSelectedUserUuids(tableState.usersData).length,
                    })}
                </div>
                <div className={style.divider} />
                <button
                    className={classNames(tableStyle.linkText)}
                    onClick={() => {
                        setTableState((prevState) => ({
                            ...prevState,
                            data: prevState.usersData.map((each) => {
                                if (each.checked) {
                                    each.checked = false;
                                }
                                return each;
                            }),
                        }));
                        setDeselected(true);
                    }}
                    data-testid={testIds.common.primaryView.table.deselectButton}
                >
                    {t("UsersTable.information.deselect")}
                </button>

                <div className={style.divider} />
                <button
                    className={classNames(tableStyle.linkText)}
                    onClick={() => setAssignLicensePoolFormVisible(true)}
                    data-testid={testIds.workArea.user.assignLicensePoolToUsersButton}
                >
                    <AddIcon color={theme.contentBackgroundColor} linecolor={theme.iconFillColor} />
                    <span className={addIconStyle.addMoreButton}>{t("UsersTable.assignLicensePool")}</span>
                </button>
            </div>
            <div className={style.divider} />
        </>
    );

    React.useEffect(() => {
        const abortController = new AbortController();
        setTableState({ usersData: [], cursor: "", scrollPosition: 0 });
        fetchData(abortController, true, true);
        fetchLicensePoolsList();
        return () => {
            abortController.abort();
        };
    }, [props.count, search, searchGroup, isLoading, searchLicensePool]);

    let dataCount = null;
    if (tableState.usersData.length > 0 && props.allLoaded) {
        dataCount = t("UsersTable.searchResultHint", {
            dataCount: tableState.usersData.length,
        });
    }
    return (
        <>
            <div
                className={classNames(
                    tableStyle.tableDetailsContainer,
                    tableStyle.tableDetailsGrid,
                    tableStyle.gridContentSpaceBetween,
                    tableStyle.centeredAlignedItems
                )}
            >
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div
                    className={classNames(
                        style.selected,
                        style.tableDetailsGrid,
                        dataCount === null ? tableStyle.gridContentEnd : tableStyle.gridContentSpaceBetween
                    )}
                >
                    {canAssignLicensePool && isAnyUsersSelected() && selectionInformation}
                </div>

                <div className={style.filter}>
                    <span className={style.label}>{t("UsersTable.filters.title")}</span>
                    {hasLicensePoolFeature && authorized && (
                        <div className={formStyle.search}>
                            <span>
                                <select
                                    className={formStyle.select}
                                    onChange={(e) => {
                                        setSearchLicensePool(e.target.value);
                                    }}
                                >
                                    <option value="">{t("UsersTable.filters.licensePoolFilterAll")}</option>
                                    {licensePoolsList.map((pool) => {
                                        return (
                                            <option key={pool.poolUuid} value={pool.poolUuid}>
                                                {pool.poolName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </span>
                        </div>
                    )}

                    {userGroupsEnabled && userGroupAccess && (
                        <div className={formStyle.search}>
                            <span>
                                <select className={formStyle.select} onChange={(e) => setSearchGroup(e.target.value)}>
                                    <option value="">{t("UsersTable.filters.groupFilterAll")}</option>
                                    {userGroups.groups.map((group) => {
                                        return (
                                            <option key={group.uuid} value={group.uuid}>
                                                {group.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </span>
                        </div>
                    )}
                    <div className={formStyle.search}>
                        <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                    </div>
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.USER_TABLE}
                    data={tableState.usersData}
                    columns={columns}
                    loaded={!initialLoading && props.allLoaded}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={t("UsersTable.emptyStateMessage")}
                    testId={testIds.workArea.user.table}
                />
            </div>
            {tableState.cursor != null &&
                tableState.usersData.length >= 100 &&
                tableState.usersData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            const abortController = new AbortController();
                            fetchData(abortController, false, false);
                            usageStatisticsService.sendEvent({
                                label: Label.USER,
                                action: Action.LOAD_MORE,
                                category: Category.USER,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}

            {hasLicensePoolFeature && licensePoolsLoading ? (
                <LoadingIndicator small={true} />
            ) : (
                <AssignLicensePoolDialog
                    userUuids={findSelectedUserUuids(tableState.usersData)}
                    licensePoolsList={licensePoolsList}
                    assignLPViewFormVisible={assignLicensePoolFormVisible}
                    setAssignLPViewFormVisible={() => {
                        setAssignLicensePoolFormVisible(false);
                    }}
                    updateLicensePoolToUsers={handleState}
                />
            )}
        </>
    );
};

export default connector(UsersTable);
