import { TFunction } from "i18next";
import * as React from "react";
import SelectSearch, { DomProps, SelectedOption, SelectSearchOption } from "react-select-search";

import style from "./create-report-view-modal.scss";
import { FilterViewData, PATH_TYPE_TO_OPERATORS } from "./FilterViewData";
import { movePathToAvailable } from "./Swap";
import Tooltip from "components/tooltip/Tooltip";
import { InvalidFilterReason, PathType, toPathType } from "domain/reports";
import { deriveProductName, Path } from "services/report/erasure/ReportService";
import { CustomReportViewFilter, Product, ReportPath, reportViewService } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import formStyle from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export function filterPaths(options: SelectSearchOption[], searchInput: string): SelectSearchOption[] {
    const lowered = searchInput.toLowerCase();
    return options.filter(
        (each) =>
            each.value.toString().toLowerCase().includes(lowered) || each.name.toLocaleLowerCase().includes(lowered)
    );
}

function createFilterPathsFunction(options: SelectSearchOption[]): (searchInput: string) => SelectSearchOption[] {
    return (searchInput: string) => filterPaths(options, searchInput);
}

export interface PathSelection {
    available: SelectSearchOption[];
    selected: SelectSearchOption[];
}

function areOptionsEqual(alpha: SelectSearchOption[], omega: SelectSearchOption[]): boolean {
    if (alpha.length !== omega.length) {
        return false;
    }
    // Before every invocation, generate tuples of two from the two arrays.
    return [...new Array(alpha.length).keys()]
        .map((index) => [alpha[index], omega[index]])
        .every((each) => each[0].value === each[1].value);
}

function movePath(pathSelection: PathSelection, selectedValue: string, toSelected: boolean): PathSelection {
    const moved = (toSelected ? pathSelection.available : pathSelection.selected).find(
        (each) => each.value.toString() === selectedValue
    );
    if (moved == null) {
        throw Error("Can't find " + selectedValue);
    }
    const reduced = (toSelected ? pathSelection.available : pathSelection.selected).filter((each) => each !== moved);
    if (toSelected) {
        return {
            available: reduced,
            selected: [...pathSelection.selected, moved],
        };
    }
    return {
        available: [...pathSelection.available, moved],
        selected: reduced,
    };
}

export function movePathToSelected(pathSelection: PathSelection, selectedValue: string): PathSelection {
    return movePath(pathSelection, selectedValue, true);
}

function sortAvailable(available: SelectSearchOption[]): SelectSearchOption[] {
    return available.sort((first, second) => first.name.localeCompare(second.name));
}

interface Props {
    maximumSelectedCount: number;
    onChange: (filters: CustomReportViewFilter[]) => void;
    paths: ReportPath[];
    t: TFunction;
    theme: typeof defaultColor | typeof tenantColor;
    translatePath: (path: string) => string;
    create: boolean;
    filters: CustomReportViewFilter[];
    validateFilter: (filter: CustomReportViewFilter) => InvalidFilterReason | null;
}

interface State {
    pathSelection: PathSelection;
    product: string;
}

export function createProductIdToPathMap(paths: ReportPath[]): Map<string, string[]> {
    return paths
        .map((each) => each.productIds.map((productId) => [productId.toString(), each.path]))
        .flat()
        .reduce<Map<string, string[]>>((accruing: Map<string, string[]>, [productId, path]: [string, string]) => {
            const paths =
                accruing.get(productId) ??
                (() => {
                    const productPaths: string[] = [];
                    accruing.set(productId, productPaths);
                    return productPaths;
                })();
            paths.push(path);
            return accruing;
        }, new Map<string, string[]>());
}

const NONE_PRODUCT_ID = "none";
const ALL_PRODUCT_ID = "all";

function createTooltip(visibleText: string, tooltipText: string): JSX.Element {
    return (
        <Tooltip content={tooltipText} maxWidth={350} delay={[300, 0]} placement={"auto"}>
            <span>{visibleText}</span>
        </Tooltip>
    );
}

/**
 * Within reportPaths, return those that can be used as filters.
 */
function filterWithSupportedPathTypes(reportPaths: ReportPath[]): ReportPath[] {
    // "Pretty stupid so fix this somehow. Maybe change definition of pathType in ReportPath." - Väinö
    // "Nah, this will do for now. The original author may feel free to return to this if he ever finds this again." - Mikko
    // "Challenge accepted." - Väinö
    const types: PathType[] = ["STRING", "ONLY_STRING", "KEYWORD"];
    const strings: Set<string> = new Set(types.map((each) => each.toString()));
    return reportPaths.filter((each) => strings.has(each.pathType));
}

function createFilterWithDefaultValues(path: string): CustomReportViewFilter {
    const reportPath = reportViewService.getPaths().find((each) => each.path === path);
    if (reportPath == null) {
        throw Error(`Can't find ReportPath for path ${path}`);
    }
    const path_type = toPathType(reportPath.pathType);
    const operators = PATH_TYPE_TO_OPERATORS.get(path_type);
    if (operators == null) {
        throw Error(`Received null operators for path ${path} and its path type ${path_type}`);
    }
    const operator = operators.find((each) => each === "MATCH") ?? operators[0];
    return {
        name: path,
        value: "",
        path_type,
        operator,
    };
}

export default class FilterViewSearch extends React.Component<Props, State> {
    private readonly commonPaths: SelectSearchOption[];
    private readonly productIdToPaths: Map<string, string[]>;
    private readonly products: Product[];
    private selectSearchComponent: React.RefObject<React.Component>;

    constructor(props: Props) {
        super(props);

        const filters = props.filters.map((each: CustomReportViewFilter) => ({
            name: this.props.translatePath(each.name),
            value: each.name,
        }));

        this.commonPaths = [
            ...filters,
            { name: props.t("ErasureReportsTable.reportVerification"), value: Path.VERIFIED },
            { name: props.t("ErasureReportsTable.productName"), value: Path.PRODUCT_ID },
        ];
        this.productIdToPaths = createProductIdToPathMap(props.paths);
        this.products = Array.from(this.productIdToPaths.keys())
            .map((id) => ({ id, name: deriveProductName(id) }))
            .sort((first, second) => first.name.localeCompare(second.name));
        const pathTypeName = filterWithSupportedPathTypes(props.paths);
        const selectedPaths = filters.reduce((accrued, each) => accrued.add(each.value), new Set<string>());
        this.state = {
            pathSelection: {
                available: sortAvailable(
                    pathTypeName
                        .map((each) => each.path)
                        .filter((each) => !selectedPaths.has(each))
                        .map((path) => ({
                            name: props.translatePath(path),
                            value: path,
                        }))
                ),
                selected: filters,
            },
            product: ALL_PRODUCT_ID,
        };

        this.selectSearchComponent = React.createRef();
    }

    render(): JSX.Element {
        const filterAvailableByProduct = (): SelectSearchOption[] => {
            const deriveAcceptablePaths = (): string[] => {
                const generateCommonPaths = () => this.commonPaths.map((each) => each.value.toString());
                switch (this.state.product) {
                    case NONE_PRODUCT_ID:
                        return generateCommonPaths();
                    case ALL_PRODUCT_ID:
                        return [...Array.from(this.productIdToPaths.values()).flat(), ...generateCommonPaths()];
                    default:
                        return (() => {
                            const paths = this.productIdToPaths.get(this.state.product);
                            if (paths == null) {
                                throw new Error("It should be impossible for acceptablePaths to be null");
                            }
                            return paths;
                        })();
                }
            };
            const acceptablePaths = new Set(deriveAcceptablePaths());
            return this.state.pathSelection.available.filter((each) => acceptablePaths.has(each.value.toString()));
        };
        return (
            <div>
                <div className={formStyle.formFields}>
                    <SelectSearch
                        ref={this.selectSearchComponent}
                        closeOnSelect={false}
                        onChange={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.REPORT_VIEW,
                                action: Action.ADD_FILTER,
                            });
                        }}
                        filterOptions={createFilterPathsFunction}
                        options={filterAvailableByProduct()}
                        disabled={this.state.pathSelection.selected.length >= this.props.maximumSelectedCount}
                        renderOption={(domProps: DomProps, option: SelectedOption): React.ReactNode => {
                            // Typing system claims that this is SelectedOption even thou it seems to be
                            // SelectedOptionValue. The latter contains the needed "name". So it seems we're once
                            // again forced to go around TypeScript's typing.
                            const { name } = option as unknown as { name: string };
                            return (
                                <button
                                    type={"button"}
                                    className={"src-components-reports-create-report-view-modal__select__option"}
                                    tabIndex={parseInt(domProps.tabIndex, 10)}
                                    value={option.value}
                                    onClick={() => {
                                        this.setState((current) => ({
                                            pathSelection: movePathToSelected(current.pathSelection, option.value),
                                        }));
                                        this.props.onChange([
                                            ...this.props.filters,
                                            createFilterWithDefaultValues(option.value),
                                        ]);
                                    }}
                                >
                                    {
                                        // TODO BCC-2571 Change path to full path.
                                        createTooltip(name, option.value)
                                    }
                                </button>
                            );
                        }}
                        search={true}
                        placeholder={this.props.t("Common.search")}
                        className={style.select}
                    />
                </div>
                {this.state.pathSelection.selected.length >= this.props.maximumSelectedCount && (
                    <div
                        data-testid={testIds.workArea.report.manageReportViewDialog.columnLimitReachedLabel}
                        className={style.maxColumnsWarning}
                    >
                        {this.props.t("CreateReportView.form.filterLimitReached")}
                    </div>
                )}
                <div>
                    <FilterViewData
                        filters={this.props.filters}
                        onChange={(filters, removedFilterPath?: string) => {
                            if (removedFilterPath !== undefined) {
                                this.setState((current) => ({
                                    pathSelection: movePathToAvailable(current.pathSelection, removedFilterPath),
                                }));
                            }
                            this.props.onChange(filters);
                        }}
                        translatePath={this.props.translatePath}
                        theme={this.props.theme}
                        products={this.products}
                        validateFilter={this.props.validateFilter}
                    />
                </div>
            </div>
        );
    }

    componentDidUpdate(_: Props, previousState: State): void {
        const getSelected = (state: State) => state.pathSelection.selected;
        if (!areOptionsEqual(getSelected(previousState), getSelected(this.state))) {
            this.state.pathSelection.selected.map((each) => ({
                name: each.name,
                value: each.value.toString(),
            }));
        }

        if (this.selectSearchComponent.current != null) {
            let selectSearchElement: HTMLElement | null;
            let searchInput: HTMLInputElement | null;
            try {
                // This is hacky but so far I haven't found a typesafe way to
                // get access to the underlying HTML element. It works at
                // least for now. I.e. with React ^16.13.1. Can't let the
                // entire component fail if there's an issue with setting an
                // HTML test ID so we'll catch all exceptions, print the
                // logger.error, and move on. Another option would be to not
                // do this at all and let test automation handle finding the
                // input on that side. At least for now we'll try to do our
                // part and lessen the work in test automation.
                selectSearchElement = this.selectSearchComponent.current as unknown as HTMLElement;
                searchInput = selectSearchElement.querySelector("input");
            } catch (error) {
                logger.error(
                    "Failed to find native input element when trying to set test ID for filter search input.",
                    error
                );
                return;
            }
            if (selectSearchElement != null) {
                selectSearchElement.dataset.testid = testIds.common.searchSelectContainer.itself;
            }
            if (searchInput != null) {
                searchInput.dataset.testid = testIds.common.searchSelectContainer.searchInput.itself;
            }
        }
    }
}
