import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Row } from "react-table";

import EditTenantView from "./edit-tenant/EditTenantView";
import style from "./tenants.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import Warning from "components/icons/Warning";
import { getFeatureLicense } from "components/licenses/common";
import AddLicenseDeliveryView from "components/licenses/delivery-history/AddLicenseDeliveryView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import { DELIVERYHISTORY_LICENSES_ROUTE } from "components/router/Routes";
import AddTenantView from "components/tenants/add-tenant/AddTenantView";
import ViewTenantHierarchy from "components/tenants/tenant-hierarchy/ViewTenantHierarchy";
import Heading from "components/typography/heading/Heading";
import {
    AUTH_LICENSE_ALERT,
    AUTH_LICENSE_ASSIGN,
    AUTH_TENANT_ACCESS,
    AUTH_TENANT_CREATE,
    AUTH_TENANT_DELETE,
    AUTH_TENANT_EDIT,
    AUTH_TENANT_VIEW,
} from "domain/authority";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { CombinedTier, LicensingModel, Tenant, TenantType } from "domain/tenants";
import { FLAG_TENANT_DELETE } from "services/feature/FeatureFlagService";
import { setTenantAccessUrls } from "services/login/endpointRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { EditTenantDto, tenantService } from "services/tenants/TenantService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { pushTenantDetails, updateTenantDetails } from "store/tenantDetails";
import { switchTheme } from "store/theme";
import buttons from "styles/buttons.scss";
import { toDate } from "utils/commonFunctions";
import { formatDateWithoutTime } from "utils/format";
import { logger } from "utils/logging";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState, { switchTheme, pushTenantDetails, updateTenantDetails });

const TenantMenuItems = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        customerName: string;
        status: boolean;
        onTenantEdit: () => void;
        onTenantDelete: () => void;
        tenantRegion: string;
        expirationDate: string;
        countryCode: string;
        contactName: string;
        contactEmail: string;
        notes: string;
        tier: CombinedTier;
        salesforceAccountId: string;
        parentExpirationDate: string;
        licenseCount: string;
        userCount: string;
        workflowCount: string;
        reportCount: string;
        parentLevel: number;
        licenses: string[];
        isBottomElement?: boolean;
        tenantType: TenantType;
        rowsUntilSubTenant: Row<Tenant>[];
        setNewComponent: (value: boolean) => void;
        setTenantUuid: (value: string) => void;
        setTenantName: (value: string) => void;
        licensingModel: LicensingModel;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteTenantModalVisible, setDeleteTenantModalVisible] = React.useState(false);
    const [subTenantModalVisible, setSubTenantModalVisible] = React.useState(false);
    const [addTenantModalVisible, setAddTenantModalVisible] = React.useState(false);
    const [retryModalVisible, setRetryModalVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const TENANT_MAX_LEVEL = 5;
    const [accessInProgress, setAccessInProgress] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [accessTenantModalVisible, setAccessTenantModalVisible] = React.useState(false);
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });

        if (hasTenantCookie()) {
            window.location.replace(location.pathname);
        }

        props.onTenantEdit();
        props.onTenantDelete();
    };
    const fetchData = () => {
        setAccessInProgress(true);
        const landingPage = props.user?.default_views.tenant_access_default_view;
        const abortController = new AbortController();
        abortControllers.push(abortController);
        if (props.rowsUntilSubTenant.length > 0) {
            props.rowsUntilSubTenant.forEach((eachRow) => {
                const tenant = eachRow.original;
                props.pushTenantDetails({
                    uuid: tenant.uuid,
                    featureLicenses: [],
                    region: tenant.region,
                    type: tenant.type,
                    tenantName: tenant.name,
                    loginMethod: tenant.loginMethod,
                    tenantTier: tenant.tier,
                    licensingModel: tenant.licensingModel,
                });
            });
        }
        tenantService
            .accessTenant(props.uuid, abortController)
            .then((data) => {
                setTenantAccessUrls(data.stan, data.laurel, data.oliver, data.publicApi, data.publicApiDocumentation);
                props.pushTenantDetails({
                    uuid: props.uuid,
                    featureLicenses: data.featureLicenses,
                    region: data.region,
                    type: data.type,
                    tenantName: data.tenantName,
                    loginMethod: data.loginMethod,
                    tenantTier: data.tenantTier,
                    licensingModel: data.licensingModel,
                });
                props.switchTheme();
                const checkFeatureLicense = getFeatureLicense();
                const checkIfFeatureLicensePresent: boolean = checkFeatureLicense.has(landingPage)
                    ? data.featureLicenses.some((item) => checkFeatureLicense.get(landingPage)?.includes(item))
                    : true;
                hasTenantCookie() &&
                    window.location.replace(
                        !checkIfFeatureLicensePresent
                            ? DELIVERYHISTORY_LICENSES_ROUTE.path
                            : props.user?.default_views.tenant_access_default_view
                            ? props.user.default_views.tenant_access_default_view
                            : "/"
                    );

                setResult({
                    title: t("AccessCustomerView.accessTitle"),
                    message: t("AccessCustomerView.accessSuccessMessage", { CustomerName: props.customerName }),
                    resultVisible: true,
                });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("AccessCustomerView.accessTitle"),
                        message: t("AccessCustomerView.accessFailureMessage", { customerName: props.customerName }),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setAccessInProgress(false);
                setAccessTenantModalVisible(false);
            });
    };

    const [editTenantModalVisible, setEditTenantModalVisible] = React.useState(false);
    const [editInProgress, setEditInProgress] = React.useState(false);
    const editTenant = (editTenant: EditTenantDto) => {
        setEditInProgress(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .editTenant(props.uuid, editTenant, abortController)
            .then(() => {
                setResult({
                    title: t("EditCustomerView.editCustomerTitle"),
                    message: t("EditCustomerView.successMessage", { customerName: props.customerName }),
                    resultVisible: true,
                });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("EditCustomerView.editCustomerFailedTitle"),
                        message: t("EditCustomerView.failureMessage", { customerName: props.customerName }),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setEditInProgress(false);
                setEditTenantModalVisible(false);
            });
    };
    const [okClicked, setOkClicked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [hierarchyVisibility, setHierarchyVisibility] = React.useState(false);

    const [checkHierarchy, setCheckHierarchy] = React.useState(false);

    const handleTenantDelete = () => {
        setOkClicked(true);
        deleteTenant();
    };

    const deleteTenant = async () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await tenantService.deleteTenant(props.uuid, props.tenantRegion, abortController);
        } catch (e) {
            if (!signal.aborted) {
                setOkClicked(false);
                setDeleteTenantModalVisible(false);
                setSubTenantModalVisible(false);
                setRetryModalVisible(true);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setDeleteTenantModalVisible(false);
        setSubTenantModalVisible(false);
        setRetryModalVisible(false);
        setOkClicked(false);
        setResult({
            title: t("DeleteTenant.inProgressTitle"),
            message: t("DeleteTenant.inProgressMessage"),
            resultVisible: true,
        });
    };

    const checkTenantHierarchy = async () => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        await tenantService
            .fetchHierarchy(props.uuid, abortController)
            .then((response) => {
                response?.tenants.length == 0 || undefined
                    ? setDeleteTenantModalVisible(true)
                    : setSubTenantModalVisible(true);
                setLoading(false);
            })
            .catch(() => {
                setCheckHierarchy(true);
                setRetryModalVisible(true);
                setLoading(false);
            });
    };

    function updateState() {
        setLoading(false);
        setAddTenantModalVisible(false);
    }

    function updateStateWhenLicenseAdded() {
        setLoading(false);
        setDeliveryCreationModalVisible(false);
    }

    const [deliveryCreationModalVisible, setDeliveryCreationModalVisible] = React.useState(false);

    const subject = encodeURIComponent(
        "Blancco Management Portal - Unexpected Error while deleting tenant : " + props.customerName
    );
    const body = encodeURIComponent(
        "Hi there\n\n" +
            "I've experienced a persistent issue with deleting tenant: " +
            props.customerName +
            "\nWould you be able to assist?\n\n"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;

    function showAddTenantFormModal() {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.ADD_SUB_TENANT,
        });
        setAddTenantModalVisible(true);
    }

    return (
        <div
            className={classNames(style.tenantIcon, {
                [style.kebabMenuAtBottom]: props.isBottomElement,
                [style.kebabMenuAtTop]: !props.isBottomElement,
            })}
        >
            <Menu className={style.kebabMenu}>
                <ul>
                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                        userSessionService.userHasAllAuthorities([AUTH_TENANT_ACCESS]) && (
                            <li>
                                <MenuItemButton
                                    onClick={() => {
                                        setAccessTenantModalVisible(true);
                                        logger.debug("handle isBottom", props.isBottomElement);
                                        usageStatisticsService.sendEvent({
                                            category: Category.TENANT,
                                            action: Action.ACCESS_TENANT,
                                        });
                                    }}
                                    className={style.menuItem}
                                >
                                    {t("AccessCustomerView.accessCustomerAccount")}
                                </MenuItemButton>
                            </li>
                        )}
                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                        userSessionService.userHasAllAuthorities([AUTH_TENANT_EDIT]) && (
                            <li>
                                <MenuItemButton
                                    onClick={() => {
                                        setEditTenantModalVisible(true);
                                        usageStatisticsService.sendEvent({
                                            category: Category.TENANT,
                                            action: Action.EDIT_TENANT,
                                        });
                                    }}
                                    className={style.menuItem}
                                >
                                    {t("EditCustomerView.edit")}
                                </MenuItemButton>
                            </li>
                        )}
                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                        userSessionService.userHasAllAuthorities([
                            AUTH_TENANT_VIEW,
                            AUTH_TENANT_EDIT,
                            AUTH_TENANT_ACCESS,
                            AUTH_TENANT_CREATE,
                        ]) && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    onClick={() => {
                                        setHierarchyVisibility(true);
                                        usageStatisticsService.sendEvent({
                                            category: Category.TENANT,
                                            action: Action.VIEW_TENANT_HIERARCHY,
                                        });
                                    }}
                                    className={style.menuItem}
                                >
                                    {t("ViewTenantHierarchy.iconTooltip")}
                                </MenuItemButton>
                            </li>
                        )}
                    {userSessionService.userHasAllAuthorities([AUTH_LICENSE_ASSIGN]) && (
                        <li>
                            <MenuItemButton
                                onClick={() => {
                                    setDeliveryCreationModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_DELIVERY,
                                        action: Action.ADD_LICENSE_DELIVERY,
                                    });
                                }}
                                className={style.menuItem}
                            >
                                {t("AddLicenseDelivery.title")}
                            </MenuItemButton>
                        </li>
                    )}
                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                        userSessionService.userHasAllAuthorities([AUTH_TENANT_CREATE]) &&
                        props.parentLevel < TENANT_MAX_LEVEL && (
                            <li className={style.listItem}>
                                <MenuItemButton onClick={showAddTenantFormModal} className={style.menuItem}>
                                    {t("Common.addSubTenant")}
                                </MenuItemButton>
                            </li>
                        )}
                    {userSessionService.hasFeatureLicense("FEATURE_LICENSE_ALERTS") &&
                        userSessionService.userHasAllAuthorities([AUTH_LICENSE_ALERT]) && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    onClick={() => {
                                        props.setNewComponent(true);
                                        props.setTenantUuid(props.uuid);
                                        props.setTenantName(props.customerName);
                                    }}
                                    className={style.menuItem}
                                >
                                    {t("LicenseAlerts.create")}
                                </MenuItemButton>
                            </li>
                        )}
                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                        userSessionService.userHasAllAuthorities([AUTH_TENANT_DELETE]) &&
                        useFeature(FLAG_TENANT_DELETE) && (
                            <li className={style.deleteListItem}>
                                <MenuItemButton
                                    onClick={() => {
                                        checkTenantHierarchy();
                                        usageStatisticsService.sendEvent({
                                            category: Category.TENANT,
                                            action: Action.DELETE_TENANT,
                                        });
                                    }}
                                    className={style.menuItem}
                                >
                                    {t("CustomersTable.deleteCustomer")}
                                </MenuItemButton>
                            </li>
                        )}
                </ul>
            </Menu>
            <Modal
                isOpen={accessTenantModalVisible}
                hideModal={() => setAccessTenantModalVisible(false)}
                modalTitle={t("AccessCustomerView.title")}
            >
                <div className={style.resultContainer}>
                    {t("AccessCustomerView.message", { customerName: props.customerName })}
                </div>

                {accessInProgress ? (
                    <LoadingIndicator />
                ) : (
                    <div className={style.buttonContainer}>
                        <button
                            className={classNames(buttons.secondaryButton, buttons.medium, style.button)}
                            onClick={() => setAccessTenantModalVisible(false)}
                        >
                            {t("Common.cancel")}
                        </button>

                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                            onClick={fetchData}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.buttonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={editTenantModalVisible}
                hideModal={() => setEditTenantModalVisible(false)}
                modalTitle={t("EditCustomerView.title", { customerName: props.customerName })}
            >
                {editInProgress ? (
                    <LoadingIndicator />
                ) : (
                    <EditTenantView
                        customerName={props.customerName}
                        editTenant={editTenant}
                        setEditTenantModalVisible={setEditTenantModalVisible}
                        status={props.status}
                        uuid={props.uuid}
                        tier={props.tier}
                        tenantRegion={props.tenantRegion}
                        expirationDate={props.expirationDate}
                        countryCode={props.countryCode}
                        contactName={props.contactName}
                        contactEmail={props.contactEmail}
                        notes={props.notes}
                        salesforceAccountId={props.salesforceAccountId}
                        parentExpirationDate={props.parentExpirationDate}
                        tenantType={props.tenantType}
                    />
                )}
            </Modal>
            <Modal
                isOpen={hierarchyVisibility}
                hideModal={() => setHierarchyVisibility(false)}
                modalTitle={t("ViewTenantHierarchy.title", { tenantName: props.customerName })}
            >
                <ViewTenantHierarchy uuid={props.uuid}></ViewTenantHierarchy>
            </Modal>
            <DeleteConfirmationDialog
                modalDisplayed={deleteTenantModalVisible}
                introductionMessage={
                    <div>
                        <p>
                            {t("DeleteTenant.introductionMessagePre")}
                            <b>{props.customerName}</b>
                            {props.expirationDate == undefined || props.expirationDate == ""
                                ? t("DeleteTenant.introductionMessageWithoutDate")
                                : t("DeleteTenant.introductionMessageWithDate", {
                                      expDate: formatDateWithoutTime(props.expirationDate),
                                  })}
                            <ul className={style.listStyleType}>
                                <li>{t("DeleteTenant.licenseCount", { licenseCount: props.licenseCount })}</li>
                                <li>{t("DeleteTenant.userCount", { userCount: props.userCount })}</li>
                                <li>{t("DeleteTenant.reportCount", { reportCount: props.reportCount })}</li>
                                <li>
                                    {t("DeleteTenant.workflowCount", {
                                        workflowCount: props.workflowCount,
                                    })}
                                </li>
                            </ul>
                            {t("DeleteTenant.introductionMessagePost")}
                        </p>
                    </div>
                }
                confirmationMessage={t("DeleteTenant.confirmationMessage")}
                handleCommand={handleTenantDelete}
                setModalDisplayed={() => setDeleteTenantModalVisible(false)}
                title={t("DeleteTenant.title")}
                loading={okClicked || loading}
            />
            {deliveryCreationModalVisible && (
                <AddLicenseDeliveryView
                    tenantUuid={props.uuid}
                    tenantName={props.customerName}
                    onLicenseAdded={updateState}
                    onHideModal={updateStateWhenLicenseAdded}
                    tenantTier={props.tier}
                    tenantType={props.tenantType}
                    licensingModel={props.licensingModel}
                />
            )}
            <Modal isOpen={subTenantModalVisible} hideModal={() => setSubTenantModalVisible(false)}>
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {subTenantModalVisible ? (
                            <>
                                <div className={style.warningContainer}>
                                    <Warning color={props.theme.secondaryWarningBackgroundColor} />
                                </div>
                                <div className={style.subTenant}>
                                    <Heading tag={"h2"}>{t("DeleteTenant.subTenantMessage")}</Heading>
                                </div>
                                <div className={style.warningContainer}>{t("DeleteTenant.subTenantErrorMessage")}</div>
                                <div className={style.warningContainer}>
                                    <button
                                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                                        onClick={() => setSubTenantModalVisible(false)}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.ok")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={retryModalVisible} hideModal={() => setRetryModalVisible(false)}>
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {retryModalVisible ? (
                            <>
                                <div className={style.warningContainer}>
                                    <Warning color={props.theme.errorBackgroundColor} />
                                </div>
                                <div className={style.subTenant}>{t("DeleteTenant.deletionFailed")}</div>
                                <div className={style.warningContainer}>
                                    {t("DeleteTenant.deletionFailedMessage")}
                                    <span>
                                        <a href={mailToLink}>{t("DeleteTenant.supportLink")}</a>
                                    </span>
                                </div>
                                <div className={style.warningContainer}>
                                    {checkHierarchy ? (
                                        <button
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                style.okButton
                                            )}
                                            onClick={() => {
                                                setRetryModalVisible(false);
                                                setCheckHierarchy(false);
                                            }}
                                            data-testid={testIds.common.dialog.closeButton}
                                        >
                                            {t("Common.ok")}
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className={classNames(
                                                    buttons.primaryButton,
                                                    style.deleteButton,
                                                    buttons.medium,
                                                    style.okButton
                                                )}
                                                data-testid={testIds.common.confirmationDialog.confirmButton}
                                                onClick={handleTenantDelete}
                                            >
                                                {t("Common.deleteAgain")}
                                            </button>
                                            <button
                                                className={classNames(
                                                    buttons.secondaryButton,
                                                    buttons.medium,
                                                    style.okButton
                                                )}
                                                onClick={() => setRetryModalVisible(false)}
                                                data-testid={testIds.common.dialog.closeButton}
                                            >
                                                {t("Common.cancel")}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={loading} hideModal={() => setLoading(false)}>
                <LoadingIndicator />
            </Modal>
            {addTenantModalVisible && (
                <AddTenantView
                    onHideModal={updateState}
                    onTenantAdded={updateState}
                    parentExpirationDate={toDate(props.expirationDate)}
                    parentLevel={props.parentLevel}
                    parentLicenses={undefined}
                    parentUuid={props.uuid}
                    tier={props.tier}
                    type={props.tenantType}
                    licensingModel={props.licensingModel}
                />
            )}
        </div>
    );
};

export default connector(TenantMenuItems);
