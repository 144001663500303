import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Delete from "components/icons/Delete";
import DragDropCell from "components/icons/DragDropCell";
import Edit from "components/icons/Edit";
import style from "components/licenses/license-configuration/bms/bms-config.scss";
import { FaultCode, NtfConfiguration } from "components/licenses/license-configuration/bms/BmsCommonInterfaces";
import Checkbox from "components/licenses/license-configuration/bms/form-components/BmsCheckboxComponent";
import FaultCodeForm from "components/licenses/license-configuration/bms/ntf/FaultCodeForm";
import defaultConfiguration from "components/licenses/license-configuration/bms/UiConfiguration.json";
import Modal from "components/modal/Modal";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import { StoreState } from "store";
import { getFaultCodeTranslation } from "utils/commonFunctions";

interface FormValues {
    ntfConfiguration: NtfConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfGroupedTestsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [faultCodes, setFaultCodes] = useState(props.formValues.ntfConfiguration.fault_codes);

    const [showAllFaultCodesVisible, setShowAllFaultCodesVisible] = React.useState(false);
    const [showFaultcode, setShowFaultcode] = React.useState(false);
    const NEW_FAULTCODE = {
        key: "",
        recommendations: [],
        tests: [],
    };
    const [selectedFaultCode, setSelectedFaultCode] = useState<FaultCode>(NEW_FAULTCODE);

    const defaultFaultCodes = defaultConfiguration.fault_codes;
    const faultCodeKeys = faultCodes.map((faultCode) => faultCode.key);

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleFaultCodesChange = (faultCodes: FaultCode[]) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, fault_codes: faultCodes } };
        });
    };

    const handleFaultCodeCheckboxChange = (key: string, isChecked: boolean) => {
        const selectedFaultCode = defaultFaultCodes.find((faultCode) => faultCode.key === key);

        if (selectedFaultCode) {
            if (isChecked) {
                setFaultCodes((prevSelectedFaultCodes) => [...prevSelectedFaultCodes, selectedFaultCode]);
            } else {
                setFaultCodes((prevSelectedFaultCodes) =>
                    prevSelectedFaultCodes.filter((faultCode) => faultCode.key !== key)
                );
            }
        }
    };

    const handleUpdatingFaultCodes = (faultCode: FaultCode) => {
        setShowFaultcode(false);

        setFaultCodes((prevFaultCodes) => {
            const index = prevFaultCodes.findIndex((fc) => fc.key === faultCode.key);

            if (index !== -1) {
                const updatedFaultCodes = [...prevFaultCodes];
                updatedFaultCodes[index] = faultCode;

                return updatedFaultCodes;
            } else {
                return [...prevFaultCodes, faultCode];
            }
        });
    };

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            const updatedFaultCodes = [...faultCodes];
            const [draggedFaultCode] = updatedFaultCodes.splice(draggedIndex, 1);
            updatedFaultCodes.splice(index, 0, draggedFaultCode);
            setFaultCodes(updatedFaultCodes);
            setDraggedIndex(index);
            handleFaultCodesChange(updatedFaultCodes);
        }
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDeleteFaultCode = (index: number) => {
        const updatedFaultCodes = [...faultCodes];
        updatedFaultCodes.splice(index, 1);
        setFaultCodes(updatedFaultCodes);
        handleFaultCodesChange(updatedFaultCodes);
    };

    const handleEditFaultCode = (faultCode: FaultCode) => {
        setSelectedFaultCode(faultCode);
        setShowFaultcode(true);
    };

    const hideShowFaultcode = () => {
        setShowFaultcode(false);
    };

    const showAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(true);
    };

    const hideAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(false);
    };

    const showCustomFaultCodesModal = () => {
        setSelectedFaultCode(NEW_FAULTCODE);
        setShowFaultcode(true);
    };

    const isSelected = (key: string) => {
        return faultCodes.some((faultCode) => faultCode.key === key);
    };

    const handleFaultCodesUpdate = () => {
        if (handleFaultCodesChange) {
            handleFaultCodesChange(faultCodes);
        }
    };

    function createNewFaultCode() {
        setSelectedFaultCode(NEW_FAULTCODE);
        showCustomFaultCodesModal();
    }

    React.useEffect(() => {
        handleFaultCodesUpdate();
    }, [faultCodes]);

    return (
        <div>
            <table className={classNames(style.conditionalQuestionTable)}>
                <tbody>
                    {faultCodes.map((faultCode: FaultCode, index: number) => (
                        <tr
                            key={faultCode.key}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(e) => handleDragOver(index, e)}
                            onDrop={handleDrop}
                            className={dragging && draggedIndex === index ? style.draggedRow : ""}
                        >
                            <td className={classNames(style.grab)}>
                                <DragDropCell color={props.theme.iconFillColor} />
                            </td>
                            <td>{t(getFaultCodeTranslation(faultCode.key))}</td>
                            <td>
                                <span
                                    onClick={() => handleEditFaultCode(faultCode)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Edit
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                            <td>
                                <span
                                    onClick={() => handleDeleteFaultCode(index)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Delete
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <span>
                <a onClick={showAllFaultCodesModal}>
                    <u>{t("Configuration.common.showAllLink")}</u>
                </a>
            </span>
            <span className={classNames(style.addCustomConfigurationButton)}>
                <a onClick={createNewFaultCode}>
                    <u>{t("Configuration.ntfConfigurationForm.addCustomFaultCode")}</u>
                </a>
            </span>

            <Modal
                isOpen={showAllFaultCodesVisible}
                hideModal={hideAllFaultCodesModal}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultCodesModalTitle")}
            >
                <div className={classNames(style.allFaultCodes)}>
                    {defaultFaultCodes.map((faultCode: FaultCode) => (
                        <Checkbox
                            labelKey={t(getFaultCodeTranslation(faultCode.key))}
                            key={faultCode.key}
                            checkboxId={faultCode.key}
                            checked={isSelected(faultCode.key)}
                            onChange={(newValue) => handleFaultCodeCheckboxChange(faultCode.key, newValue)}
                        />
                    ))}
                </div>
            </Modal>
            <Modal
                isOpen={showFaultcode}
                hideModal={hideShowFaultcode}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultTestsModalTitle")}
            >
                <FaultCodeForm
                    platform={props.formValues.platform}
                    faultCode={selectedFaultCode}
                    faultCodeKeys={faultCodeKeys}
                    editMode={!(selectedFaultCode.key.length > 0)}
                    faultCodeHandler={handleUpdatingFaultCodes}
                    ntfConfiguration={props.formValues.ntfConfiguration}
                />
            </Modal>
        </div>
    );
};

export default connector(NtfGroupedTestsForm);
