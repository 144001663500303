import { TableData } from "./table";
import { DefaultWorkflow, EditorGeneration } from "services/workflows/WorkflowService";
import { Profile } from "services/workflows/WorkflowService";

export interface EditorMetadata {
    profile: Profile;
}

export interface Workflow {
    name: string;
    version: string;
    editorMetadata?: EditorMetadata;
}

export function isWorkflow(candidate: unknown): candidate is Workflow {
    const workflow = candidate as Workflow;
    return workflow.name !== undefined && workflow.version !== undefined;
}

export interface WorkflowsData {
    count: number;
    cursor: string;
    workflowTableData: WorkflowTableData[];
}

export interface WorkflowTableData extends TableData {
    name: string;
    uuid: string;
    id: number;
    product: string;
    profile: Profile;
    createdDate: string;
    creator: string;
    defaultWorkflow: DefaultWorkflow;
    workflow: Workflow;
    userUuid: string;
    own: boolean;
    editorVersion: string;
    editorGeneration: EditorGeneration;
}

export interface VersionsData {
    id: string;
    modified: string;
    modifiedBy: string;
}

export interface FilterState {
    from: string;
    to: string;
}
