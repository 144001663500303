import * as LicenseRepository from "./licenseRepository";
import { SummaryLabelParameter } from "components/licenses/assign-bms-keys/Form";
import { EditDeliveryForm } from "components/licenses/delivery-history/DeliveryHistoryMenuItems";
import { EntitlementRequestLicense } from "components/licenses/entitlements/add-entitlement/AddEntitlementView";
import {
    Configuration,
    ConfigurationDto,
    ConfigureData,
    Container,
    ContainerCursor,
    ContainerData,
    ContainerType,
    DeliveryHistories,
    DeliveryHistory,
    DeliveryType,
    EmsConfiguration,
    EmsConfigurationDto,
    Entitlement,
    EntitlementCursor,
    EntitlementData,
    EntitlementType,
    Key,
    KeyCursor,
    KeyData,
    LicenseData,
    LicenseDelivery,
    LicenseDeliveryHistory,
    LicenseKeyCursor,
    LicenseList,
    toEmsConfiguration,
} from "domain/licenses";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { TenantLicense } from "services/tenants/TenantService";
import { ParameterQuery } from "utils/commonFunctions";
import { isExpired } from "utils/format";
import { logger } from "utils/logging";

const BMS_LICENSES = ["bms-validation", "bms-bbti", "bms-lease", "bms-insurance", "usdk", "bms-ntf", "bms-all"];

export interface LicenseDto {
    product: string;
    license: string;
    available: number;
    total: number;
    expiration_date: string;
    type: string;
    license_type: string;
    feature_license_present: boolean;
    assigned: number;
    overall_remaining?: number;
}

export interface LicenseResponseDto {
    licenses: LicenseDto[];
    parent_licenses: TenantLicense[];
}

export interface LicenseResponse {
    licenses: LicenseData[];
    parentLicenses: TenantLicense[];
}

function toLicensesResponse(dto: LicenseResponseDto): LicenseResponse {
    return {
        licenses: dto.licenses.map(toLicenseData),
        parentLicenses: dto.parent_licenses,
    };
}

export function toLicenseData(dto: LicenseDto): LicenseData {
    return {
        product: dto.product,
        license: dto.license,
        available: dto.available,
        total: dto.total,
        expirationDate: dto.expiration_date,
        type: dto.type,
        licenseType: dto.license_type,
        isInvalid: isInvalidLicense(dto.available, dto.expiration_date),
        isFeatureLicensePresent: dto.feature_license_present,
        assigned: dto.assigned,
        overallRemaining: dto.overall_remaining,
    };
}

export interface LicenseKey {
    licenseKeys: Key[];
}

export interface ConfigurationData {
    configuration: ConfigureData[];
}

export interface CommonDto {
    start_date: string;
    expiration_date: string;
}

export interface KeyDto extends CommonDto {
    identifier: string;
    license_key: string;
    assigned: number;
    used: number;
    remaining: number;
    usage_hours_limit: number;
    license_type: string;
    short_url: string;
    pool_name: string;
}

export interface LicenseDataDto extends CommonDto {
    product: string;
    license: string;
    available: number;
    total: number;
    expiration_date: string;
    type: string;
    license_type: string;
    feature_license_present: boolean;
    overall_remaining?: number;
    assigned: number;
}

export interface KeyCursorDto {
    license_key: string;
    tenant_identifier: string;
}

export interface LicensesCursorDto {
    identifier: string;
    tenant_identifier: string;
}

export interface KeysDto {
    licenseKeys: KeyDto[];
    cursor: KeyCursorDto;
}

export interface LicensesDto {
    licenses: LicenseDataDto[];
    cursor: LicensesCursorDto;
}

export interface KeyResponseDto {
    licenseKeys: KeysDto;
}

export interface EntitlementCursorDto {
    last_tenant_uuid: string;
    last_entitlement_id: string;
}

export interface EntitlementDto {
    entitlement_id: string;
    created: string;
    description: string;
    backup_uuid: string;
    container_id: string;
    tenant_uuid: string;
    type: EntitlementType;
}

export interface EntitlementsDto {
    entitlements: EntitlementDto[];
    cursor: EntitlementCursorDto;
}

export interface EntitlementResponseDto {
    entitlements: EntitlementsDto;
    count: number;
}

function toEntitlement(dto: EntitlementDto): Entitlement {
    return {
        entitlementId: dto.entitlement_id,
        created: dto.created,
        description: dto.description,
        backupUuid: dto.backup_uuid,
        containerId: dto.container_id,
        tenantUuid: dto.tenant_uuid,
        type: dto.type,
    };
}

function toEntitlementCursor(dto: EntitlementCursorDto): EntitlementCursor | null {
    return dto == null
        ? null
        : {
              lastTenantUuid: dto.last_tenant_uuid,
              lastEntitlementId: dto.last_entitlement_id,
          };
}

export interface ContainerCursorDto {
    last_tenant_uuid: string;
    last_container_id: string;
}

export interface ContainerDto {
    container_id: string;
    tenant_uuid: string;
    date: string;
    name: string;
    type: ContainerType;
}

export interface ContainersDto {
    containers: ContainerDto[];
    cursor: ContainerCursorDto;
}

export interface ContainersResponseDto {
    containers: ContainersDto;
}

function toContainer(dto: ContainerDto): Container {
    return {
        containerId: dto.container_id,
        tenantUuid: dto.tenant_uuid,
        date: dto.date,
        name: dto.name,
        type: dto.type,
    };
}

function toContainerCursor(dto: ContainerCursorDto): ContainerCursor | null {
    return dto == null
        ? null
        : {
              lastTenantUuid: dto.last_tenant_uuid,
              lastContainerId: dto.last_container_id,
          };
}

function toLicense(dto: LicenseDataDto): LicenseData {
    return {
        product: dto.product,
        license: dto.license,
        available: dto.available,
        total: dto.total,
        expirationDate: dto.expiration_date,
        type: dto.type,
        licenseType: dto.license_type,
        isInvalid: isInvalidLicense(dto.available, dto.expiration_date),
        isFeatureLicensePresent: dto.feature_license_present,
        overallRemaining: dto.overall_remaining,
        assigned: dto.assigned,
    };
}

const isInvalidLicense = (available: number, expiration: string): boolean => {
    return available < 1 || isExpired(expiration);
};

function toKey(dto: KeyDto): Key {
    return {
        licenseKey: dto.license_key,
        identifier: dto.identifier,
        assigned: dto.assigned,
        used: dto.used,
        remaining: dto.remaining,
        startDate: dto.start_date,
        expirationDate: dto.expiration_date,
        usageHoursLimit: dto.usage_hours_limit,
        licenseType: dto.license_type,
        shortUrl: dto.short_url,
        poolName: dto.pool_name,
    };
}

function toKeyCursor(dto: KeyCursorDto): KeyCursor | null {
    return dto == null
        ? null
        : {
              licenseKey: dto.license_key,
              tenantIdentifier: dto.tenant_identifier,
          };
}

function toLicenseCursor(dto: LicensesCursorDto): LicenseKeyCursor | null {
    return dto == null
        ? null
        : {
              identifier: dto.identifier,
              tenantIdentifier: dto.tenant_identifier,
          };
}

function toConfiguration(dto: ConfigurationDto): Configuration {
    return {
        tenantConfiguration: dto.tenant_configuration,
        bbtiConfiguration: dto.bbti_configuration,
        validationConfiguration: dto.validation_configuration,
        insuranceConfiguration: dto.insurance_configuration,
        ntfConfiguration: dto.ntf_configuration,
        leaseConfiguration: dto.lease_configuration,
        downloadAppPageConfiguration: dto.download_app_page_configuration,
    };
}

export interface LicenseKeyAssignment {
    keyCount: number;
    association: string;
    identifier?: string;
    hours: number;
    licenseType: string;
    available: number;
    assignCount: number;
    startDate: string;
    expirationDate: string;
}

interface LicenseKeyAssignmentDto extends CommonDto {
    key_count: number;
    identifier?: string;
    hours: number;
    assign_count: number;
    license_type: string;
}

interface RequestLicenseDto {
    blancco_product_id: number;
    license_amount: number;
}

function toRequestLicense(dto: EntitlementRequestLicense): RequestLicenseDto {
    return {
        blancco_product_id: dto.blanccoProductId,
        license_amount: dto.licenseAmount,
    };
}

interface LicenseDeliveryDto {
    type: DeliveryType;
    case_number: string;
    opportunity_id: string;
    notes: string;
    tenant_uuid?: string;
    licenses: {
        license_id: string;
        amount: number;
        expiration_date: string;
    }[];
}

export function toLicenseDelivery(delivery: LicenseDelivery): LicenseDeliveryDto {
    return {
        type: delivery.type,
        case_number: delivery.caseNumber,
        opportunity_id: delivery.opportunityId,
        notes: delivery.notes,
        licenses: delivery.licenses.map((each) => {
            return {
                license_id: each.licenseId,
                amount: each.amount,
                expiration_date: each.expirationDate,
            };
        }),
        tenant_uuid: delivery.tenantUuid ?? delivery.tenantUuid,
    };
}

export interface EditDeliveryFormDto {
    case_number: string;
    opportunity_id: string;
    notes: string;
}

function toEditLicenseDeliveryDto(delivery: EditDeliveryForm): EditDeliveryFormDto {
    return {
        case_number: delivery.caseNumber,
        opportunity_id: delivery.opportunityId,
        notes: delivery.notes,
    };
}

interface LicenseDeliveryHistoryDto {
    type: DeliveryType;
    case_number: string;
    opportunity_id: string;
    uuid: string;
    notes: string;
    creation_date: string;
    creator_uuid: string;
    editor_uuid: string;
    edit_date: string;
    licenses: {
        license_id: string;
        amount: number;
        expiration_date: string;
    }[];
}

function toLicenseDeliveryHistoryDto(delivery: LicenseDeliveryHistoryDto): LicenseDeliveryHistory {
    return {
        type: delivery.type,
        caseNumber: delivery.case_number,
        opportunityId: delivery.opportunity_id,
        notes: delivery.notes,
        creationDate: delivery.creation_date,
        creatorUuid: delivery.creator_uuid,
        editorUuid: delivery.editor_uuid,
        editDate: delivery.edit_date,
        uuid: delivery.uuid,
        licenses: delivery.licenses.map((each) => {
            return {
                licenseId: each.license_id,
                amount: each.amount,
                expirationDate: each.expiration_date,
                totalOfLicenses: each.amount,
            };
        }),
    };
}

export interface DeliveryHistoryDto {
    uuid: string;
    creation_date: string;
    notes: string;
    type: string;
}

export interface DeliveryHistoryResponseDto {
    deliveryHistory: DeliveryHistoryDto[];
    count: number;
    cursor: string;
}

function toDeliveryHistory(dto: DeliveryHistoryDto): DeliveryHistory {
    return {
        uuid: dto.uuid,
        type: dto.type,
        notes: dto.notes,
        creationDate: dto.creation_date,
    };
}

interface EmsConfigurationExistsResponse {
    exists: boolean;
}

const PATH_LICENSES = "/api/licenses";
const PATH_LICENSE_KEYS = "/license-keys";

class LicenseService {
    public fetchLicenses(
        abortController?: AbortController,
        search?: string,
        own = false,
        tenant_uuid?: string,
        pool_uuid?: string
    ): Promise<LicenseResponse> {
        const query = new ParameterQuery()
            .add("search", search)
            .add("all", own ? "false" : "true")
            .add("tenant_uuid", tenant_uuid)
            .add("pool_uuid", pool_uuid)
            .createQueryString();
        return apiGatewayService
            .invokeApi(`${PATH_LICENSES}${query}`, "GET", null, { abortController })
            .then((dto: LicenseResponseDto) => toLicensesResponse(dto));
    }

    public fetchAllLicenses({
        abortController,
        identifier,
        tenantIdentifier,
        search,
        own = true,
    }: {
        abortController?: AbortController;
        identifier?: string;
        tenantIdentifier?: string;
        search?: string;
        own?: boolean;
    } = {}): Promise<LicenseList> {
        const url =
            PATH_LICENSES +
            new ParameterQuery()
                .add("search", search)
                .addAll([
                    ["identifier", identifier],
                    ["tenant_identifier", tenantIdentifier],
                ])
                .add("all", own ? "false" : "true")
                .createQueryString();
        return apiGatewayService
            .invokeApi(url, "GET", null, {
                abortController: abortController,
            })
            .then((response: LicensesDto) => {
                const licenseData = response.licenses.map(toLicense);
                const cursor = toLicenseCursor(response.cursor);
                return {
                    licenses: {
                        licenseData: licenseData,
                        cursor: cursor,
                    },
                };
            });
    }

    public fetchLicenseKeys(
        abortController: AbortController,
        licenseKey?: string,
        tenantIdentifier?: string,
        search?: string
    ): Promise<KeyData> {
        const url =
            PATH_LICENSE_KEYS +
            new ParameterQuery()
                .add("search", search)
                .addAll([
                    ["license_key", licenseKey],
                    ["tenant_identifier", tenantIdentifier],
                ])
                .createQueryString();
        return apiGatewayService
            .invokeApi(url, "GET", null, {
                abortController: abortController,
            })
            .then((response: KeysDto) => {
                const licenseKeys = response.licenseKeys.map(toKey);
                const cursor = toKeyCursor(response.cursor);
                return {
                    licenseKeys: {
                        licenseKeys: licenseKeys,
                        cursor: cursor,
                    },
                };
            });
    }

    public assignLicenseKey(assignment: LicenseKeyAssignment, abortController?: AbortController): Promise<void> {
        const dto: LicenseKeyAssignmentDto = {
            key_count: assignment.keyCount,
            identifier: assignment.identifier,
            hours: assignment.hours,
            assign_count: assignment.assignCount,
            start_date: assignment.startDate,
            expiration_date: assignment.expirationDate,
            license_type: assignment.licenseType,
        };
        return apiGatewayService.invokeApi("/license-keys", "POST", dto, { abortController });
    }

    public addConfiguration(configuration: Configuration, abortController: AbortController): Promise<void> {
        const dto: ConfigurationDto = {
            tenant_configuration: configuration.tenantConfiguration,
            bbti_configuration: configuration.bbtiConfiguration,
            validation_configuration: configuration.validationConfiguration,
            insurance_configuration: configuration.insuranceConfiguration,
            ntf_configuration: configuration.ntfConfiguration,
            lease_configuration: configuration.leaseConfiguration,
            download_app_page_configuration: configuration.downloadAppPageConfiguration,
        };
        return apiGatewayService.invokeApi("/client-configurations", "POST", dto, {
            abortController,
        });
    }

    public saveConfiguration(
        uuid: string,
        configuration: Configuration,
        abortController: AbortController
    ): Promise<void> {
        const dto: ConfigurationDto = {
            tenant_configuration: configuration.tenantConfiguration,
            bbti_configuration: configuration.bbtiConfiguration,
            validation_configuration: configuration.validationConfiguration,
            insurance_configuration: configuration.insuranceConfiguration,
            ntf_configuration: configuration.ntfConfiguration,
            lease_configuration: configuration.leaseConfiguration,
            download_app_page_configuration: configuration.downloadAppPageConfiguration,
        };
        return apiGatewayService.invokeApi("/client-configurations/" + uuid, "POST", dto, {
            abortController,
        });
    }

    public fetchConfigurationData(abortController: AbortController): Promise<ConfigurationData> {
        return apiGatewayService.invokeApi("/client-configurations", "GET", null, { abortController });
    }

    public fetchClientConfigurationData(
        uuid: string | undefined,
        abortController: AbortController
    ): Promise<Configuration> {
        return apiGatewayService
            .invokeApi("/client-configurations/" + uuid, "GET", null, {
                abortController,
            })
            .then((dto: ConfigurationDto) => toConfiguration(dto));
    }

    public fetchDefaultConfigurationData(abortController: AbortController): Promise<Configuration> {
        return apiGatewayService
            .invokeApi("/client-configurations/default", "GET", null, {
                abortController,
            })
            .then((dto: ConfigurationDto) => toConfiguration(dto));
    }

    public fetchAvailableLicenses(
        type: string | undefined,
        abortController: AbortController
    ): Promise<SummaryLabelParameter> {
        return apiGatewayService.invokeApi("/api/licenses/" + type, "GET", null, { abortController });
    }

    public fetchEntitlements(
        abortController: AbortController,
        cursor: EntitlementCursor | null,
        search?: string
    ): Promise<EntitlementData> {
        const cursorDto: EntitlementCursorDto = {
            last_entitlement_id: cursor ? cursor.lastEntitlementId : "",
            last_tenant_uuid: cursor ? cursor.lastTenantUuid : "",
        };
        const url = "/api/tenants/ems/entitlements" + new ParameterQuery().add("search", search).createQueryString();
        return apiGatewayService
            .invokeApi(url, "POST", cursor ? { cursor: cursorDto } : null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
            })
            .then((data: EntitlementResponseDto) => {
                const entitlements = data.entitlements.entitlements.map(toEntitlement);
                const entitlementCursor = toEntitlementCursor(data.entitlements.cursor);
                return {
                    entitlements: {
                        entitlements: entitlements,
                        cursor: entitlementCursor,
                        count: data.count,
                    },
                };
            });
    }

    public fetchAllContainersRecursively(
        abortController: AbortController,
        cursor: ContainerCursor | null,
        callCount: number
    ): Promise<Container[]> {
        // Safety switch for recursion
        if (callCount >= 5) {
            logger.error("Maximum amount of recursive container fetches reached");
            return Promise.resolve([]);
        }
        return this.fetchContainers(abortController, cursor).then((containerData) => {
            const containers = containerData.containers.containers;
            const newCursor = containerData.containers.cursor;
            if (newCursor != null) {
                return this.fetchAllContainersRecursively(abortController, newCursor, ++callCount).then(
                    (moreContainers) => {
                        return containers.concat(moreContainers);
                    }
                );
            }
            return containers;
        });
    }

    public fetchContainers(
        abortController: AbortController,
        cursor: ContainerCursor | null,
        search?: string
    ): Promise<ContainerData> {
        const cursorDto: ContainerCursorDto = {
            last_container_id: cursor ? cursor.lastContainerId : "",
            last_tenant_uuid: cursor ? cursor.lastTenantUuid : "",
        };
        const url = "/api/tenants/ems/containers" + new ParameterQuery().add("search", search).createQueryString();
        return apiGatewayService
            .invokeApi(url, "POST", cursor ? { cursor: cursorDto } : null, {
                abortController,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
                refreshSession: true,
            })
            .then((data: ContainersResponseDto) => {
                const containers = data.containers.containers.map(toContainer);
                const containerCursor = toContainerCursor(data.containers.cursor);
                return {
                    containers: {
                        containers: containers,
                        cursor: containerCursor,
                    },
                };
            });
    }

    public editEntitlement(
        entitlementId: string,
        description: string,
        abortController: AbortController
    ): Promise<void> {
        const postData = { description };
        return apiGatewayService.invokeApi("/api/tenants/ems/entitlements/" + entitlementId, "POST", postData, {
            refreshSession: true,
            abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public fetchEmsConfiguration(
        uuid: string,
        abortController?: AbortController,
        region?: string
    ): Promise<EmsConfiguration> {
        return apiGatewayService
            .invokeApi(`/api/tenants/${uuid}/ems/configuration`, "GET", null, {
                abortController,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
                region: region,
            })
            .then((dto: EmsConfigurationDto) => toEmsConfiguration(dto));
    }

    public fetchEmsConfigurationExistence(
        uuid: string,
        abortController?: AbortController,
        region?: string
    ): Promise<boolean> {
        return apiGatewayService
            .invokeApi(`/api/tenants/${uuid}/ems/configuration?exists`, "GET", null, {
                abortController,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
                region: region,
            })
            .then((dto: EmsConfigurationExistsResponse) => dto.exists);
    }

    public editContainer(containerId: string, name: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(
            "/api/tenants/ems/containers/" + containerId,
            "POST",
            { name: name },
            {
                refreshSession: true,
                abortController: abortController,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }

    public checkEmsCustomerIdExistence(id: string, abortController?: AbortController): Promise<boolean> {
        return apiGatewayService
            .invokeApi(`/api/ems/customer/${id}`, "GET", null, {
                abortController,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            })
            .then(() => Promise.resolve(true))
            .catch(() => Promise.resolve(false));
    }

    public synchronize(abortController?: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/tenants/ems/synchronize", "POST", null, {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public createEntitlement(
        type: string,
        description: string,
        licensesList: EntitlementRequestLicense[],
        abortController: AbortController,
        containerId?: string
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            "/api/tenants/ems/entitlement/create",
            "POST",
            {
                type: type,
                description: description,
                licenses: licensesList.map(toRequestLicense),
                key_id: containerId,
            },
            {
                refreshSession: true,
                abortController: abortController,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }

    public fetchDeliveryHistory(
        abortController: AbortController,
        search?: string,
        cursor?: string
    ): Promise<DeliveryHistories> {
        const url =
            "/api/license-delivery" +
            new ParameterQuery().add("search", search).add("cursor", cursor).createQueryString();
        return apiGatewayService
            .invokeApi(url, "GET", null, { abortController: abortController })
            .then((dto: DeliveryHistoryResponseDto) => {
                return {
                    count: dto.count,
                    cursor: dto.cursor,
                    deliveryHistories: dto.deliveryHistory.map(toDeliveryHistory),
                };
            });
    }

    public createLicenseDelivery(
        licenseDelivery: LicenseDelivery,
        abortController: AbortController,
        region?: string
    ): Promise<void> {
        return apiGatewayService.invokeApi("/api/license-delivery", "POST", toLicenseDelivery(licenseDelivery), {
            abortController: abortController,
            region: region,
            emptyResponse: true,
        });
    }

    public fetchLicenseDeliveryHistory(
        uuid: string,
        abortController?: AbortController
    ): Promise<LicenseDeliveryHistory> {
        return apiGatewayService
            .invokeApi(`/api/license-delivery/${uuid}`, "GET", null, { abortController: abortController })
            .then((dto: LicenseDeliveryHistoryDto) => toLicenseDeliveryHistoryDto(dto));
    }

    public editLicenseDelivery(
        uuid: string,
        licenseDeliveryDetails: EditDeliveryForm,
        abortController: AbortController,
        region?: string
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            `/api/license-delivery/${uuid}`,
            "POST",
            toEditLicenseDeliveryDto(licenseDeliveryDetails),
            {
                abortController: abortController,
                region: region,
            }
        );
    }

    public checkBmsLicenses(own: boolean, abortController: AbortController, tenantUuid: string) {
        licenseService.fetchAllLicenses({ abortController, tenantIdentifier: tenantUuid, own }).then((licenses) => {
            fillBmsLocalStorageVariables(licenses.licenses.licenseData);
        });
    }

    public async userHasOwnBmsLicense(abortController: AbortController, tenantUuid: string): Promise<boolean> {
        let foundBmsLicense = false;
        await licenseService
            .fetchAllLicenses({
                abortController,
                tenantIdentifier: tenantUuid,
                own: true,
            })
            .then((licenses) => {
                const licenseData = licenses.licenses.licenseData;
                BMS_LICENSES.forEach((bmsLicense) => {
                    if (licenseData.some((license) => license.type === bmsLicense)) {
                        foundBmsLicense = true;
                    }
                });
            });
        return foundBmsLicense;
    }
}

export function fillBmsLocalStorageVariables(licenseData: LicenseData[]) {
    LicenseRepository.clear();
    let hasAnyBmsLicense = false;
    let hasOnlyBmsLicenses = true;
    licenseData.forEach((licenseData) => {
        if (BMS_LICENSES.includes(licenseData.type)) {
            hasAnyBmsLicense = true;
        } else {
            hasOnlyBmsLicenses = false;
        }
    });
    LicenseRepository.setBmsLicenses(hasAnyBmsLicense);
    LicenseRepository.setOnlyBms(hasOnlyBmsLicenses);
}

export const licenseService = new LicenseService();
