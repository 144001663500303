import * as React from "react";

const AdvanceSearch = (props: { color: string }): JSX.Element => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: "10px" }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.773994 0.886507C0.879864 0.674767 1.09628 0.541016 1.33301 0.541016H14.6663C14.9031 0.541016 15.1195 0.674767 15.2254 0.886507C15.3312 1.09825 15.3084 1.35163 15.1663 1.54102L10.2913 8.04102V12.2771C10.2913 12.4861 10.1869 12.6812 10.013 12.7972L6.6797 15.0194C6.48791 15.1472 6.24132 15.1592 6.0381 15.0504C5.83488 14.9416 5.70801 14.7298 5.70801 14.4993V8.04102L0.833011 1.54102C0.690972 1.35163 0.668124 1.09825 0.773994 0.886507ZM2.58301 1.79102L6.83301 7.45768C6.91415 7.56587 6.95801 7.69745 6.95801 7.83268V13.3315L9.04134 11.9426V7.83268C9.04134 7.69745 9.08521 7.56587 9.16634 7.45768L13.4163 1.79102H2.58301Z"
                fill={props.color}
            />
        </svg>
    );
};

export default AdvanceSearch;
