import { FunctionComponent } from "react";

import AllApiKeysView from "components/api-keys-settings/AllApiKeysView";
import ApiKeysView from "components/api-keys-settings/ApiKeysView";
import MyApiKeysView from "components/api-keys-settings/MyApiKeysView";
import DashboardView from "components/dashboard/DashboardView";
import CustomerEsgDashboardView from "components/dashboard/esg/CustomerEsgDashboardView";
import EsgDashboardView from "components/dashboard/esg/EsgDashboardView";
import CustomDashboard from "components/dashboard/overview/CustomDashboard";
import DownloadPageView from "components/download-page/DownloadPageView";
import IosProfileDownload from "components/ios-profile-download/IosProfileDownload";
import IosProfilePage from "components/ios-profile-download/IosProfilePage";
import AllLicensesView from "components/licenses/AllLicensesView";
import BmsConfigurationView from "components/licenses/BmsConfigurationView";
import BmsLicenseKeysView from "components/licenses/BmsLicenseKeysView";
import ContainersView from "components/licenses/containers/ContainersView";
import DeliveryHistoryView from "components/licenses/delivery-history/DeliveryHistoryView";
import EntitlementView from "components/licenses/entitlements/EntitlementView";
import AllLicenseAlertsView from "components/licenses/license-alerts/AllLicenseAlertsView";
import LicenseEventsView from "components/licenses/license-history/LicenseEventsView";
import AllLicensePoolsView from "components/licenses/licenses-pool/AllLicensePoolsView";
import LicensesView from "components/licenses/LicensesView";
import MyLicensesView from "components/licenses/MyLicensesView";
import CreateNewPasswordView from "components/login/create-new-password/CreateNewPasswordView";
import LoginView from "components/login/LoginView";
import LogoutView from "components/login/LogoutView";
import AllBmsReportsView from "components/reports/AllBmsReportsView";
import AllReportsView from "components/reports/AllReportsView";
import ReportTemplatesView from "components/reports/report-template/ReportTemplatesView";
import ReportsView from "components/reports/ReportsView";
import ApiGuideView from "components/support/api-guide/ApiDocumentationView";
import SupportView from "components/support/api-guide/SupportView";
import ReportPathsView from "components/support/report-paths/ReportPathsView";
import SupportAndHelpView from "components/support/support-and-help/SupportAndHelpView";
import AllTenantsView from "components/tenants/AllTenantsView";
import TenantsView from "components/tenants/TenantsView";
import AllUsersView from "components/users/AllUsersView";
import UserProfileView from "components/users/profile/UserProfileView";
import UserGroupsView from "components/users/user-groups/UserGroupsView";
import UserRolesView from "components/users/user-roles/UserRolesView";
import UsersView from "components/users/UsersView";
import AllWorkflowsView from "components/workflows/AllWorkflowsView";
import WorkflowsView from "components/workflows/WorkflowsView";
import {
    AUTH_API_KEY_VIEW_ALL,
    AUTH_API_KEY_VIEW_OWN,
    AUTH_EMS_CREATE_ENTITLEMENT,
    AUTH_EMS_SYNCHRONIZE,
    AUTH_EMS_VIEW,
    AUTH_LICENSE_ALERT,
    AUTH_LICENSE_ASSIGN,
    AUTH_LICENSE_HISTORY_VIEW_ALL,
    AUTH_LICENSE_HISTORY_VIEW_OWN,
    AUTH_LICENSE_POOL_VIEW,
    AUTH_LICENSE_VIEW,
    AUTH_LICENSE_VIEW_OWN,
    AUTH_REPORT_TEMPLATE_VIEW,
    AUTH_ROLE_CREATE,
    AUTH_ROLE_DEFAULT,
    AUTH_ROLE_DELETE,
    AUTH_ROLE_EDIT,
    AUTH_ROLE_VIEW,
    AUTH_TENANT_SETTINGS,
    AUTH_TENANT_VIEW,
    AUTH_USER_CREATE,
    AUTH_USER_DELETE,
    AUTH_USER_EDIT,
    AUTH_USER_GROUP_CREATE,
    AUTH_USER_GROUP_DELETE,
    AUTH_USER_GROUP_EDIT,
    AUTH_USER_GROUP_VIEW,
    AUTH_USER_VIEW,
    AUTH_WORKFLOW_VIEW,
} from "domain/authority";
import { CustomReportView } from "domain/reports";
import { UserDetails } from "domain/user";
import { FeatureLicenseType } from "domain/users";
import {
    FLAG_LICENSE_ALERTS,
    FLAG_ROLES,
    FLAG_SUPPORT_REPORT_PATHS,
    FLAG_USER_GROUPS,
} from "services/feature/FeatureFlagService";
import { hasEmsId } from "services/licenses/licenseRepository";
import {
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    tenantHasFeatureLicense,
} from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { TenantDetails } from "store/tenantDetails";

import testIds from "testIds.json";

type UserValidator = (user: UserDetails | null) => boolean;

const OK_USER_VALIDATOR: UserValidator = () => true;

export class RouteDefinition {
    public path: string;
    public title: string;
    public component: FunctionComponent;
    public secured: boolean;
    public childRoutes: RouteDefinition[];
    public testId?: string;
    public featureFlag?: string;
    public featureLicenses?: FeatureLicenseType[];
    private userValidators: UserValidator[];
    public custom: boolean;
    public isShared: boolean | undefined;
    public advertised: boolean | undefined;

    constructor(
        path: string,
        title: string,
        component: FunctionComponent,
        {
            secured = true,
            childRoutes = [],
            testId = undefined,
            featureFlag = undefined,
            featureLicenses = undefined,
            userValidators = [OK_USER_VALIDATOR],
            custom = false,
            isShared = undefined,
            advertised = undefined,
        }: {
            secured?: boolean;
            childRoutes?: RouteDefinition[];
            testId?: string;
            featureFlag?: string;
            featureLicenses?: FeatureLicenseType[];
            userValidators?: UserValidator[];
            custom?: boolean;
            isShared?: boolean;
            advertised?: boolean;
        } = {}
    ) {
        this.path = path;
        this.title = title;
        this.component = component;
        this.secured = secured;
        this.childRoutes = childRoutes;
        this.testId = testId;
        this.featureFlag = featureFlag;
        this.featureLicenses = featureLicenses;
        this.userValidators = userValidators;
        this.custom = custom;
        this.isShared = isShared;
        this.advertised = advertised;
    }

    isValidUser(user: UserDetails | null): boolean {
        for (const each of this.userValidators) {
            const valid = each(user);
            if (!valid) {
                return valid;
            }
        }
        return this.childRoutes.length === 0 || this.childRoutes.some((route) => route.isValidUser(user));
    }

    isAccessibleTo(tenant: TenantDetails | null): boolean {
        if (!this.featureLicenses) {
            return true;
        }
        if (!tenant) {
            return false;
        }
        return this.featureLicenses.some((feature) => {
            return tenant.featureLicenses.includes(feature);
        });
    }

    getFeatureFlag(): string | undefined {
        return this.featureFlag;
    }

    containsChildRoute(route: RouteDefinition): boolean {
        for (const childRoute of this.childRoutes) {
            if (childRoute.path === route.path) {
                return true;
            }
        }
        return false;
    }
}

export function enableLicenseChildRoutes(bms: boolean): void {
    LICENSES_ROUTE.childRoutes = [];
    LICENSES_ROUTE.childRoutes.push(LICENSES_MY_LICENSES_ROUTE);
    LICENSES_ROUTE.childRoutes.push(LICENSES_ALL_LICENSES_ROUTE);
    LICENSES_ROUTE.childRoutes.push(LICENSES_POOL_ROUTE);
    if (!hasTenantCookie() && !hasSubTenantCookie()) {
        LICENSES_ROUTE.childRoutes.push(LICENSES_ALERT_ROUTE);
    }
    LICENSES_ROUTE.childRoutes.push(DELIVERYHISTORY_LICENSES_ROUTE);
    LICENSES_ROUTE.childRoutes.push(LICENSE_HISTORY_ROUTE);
    LICENSES_ROUTE.childRoutes.push(LICENSES_EXTRACTION_ROUTE);
    if (bms && isUserParentInternal()) {
        LICENSES_ROUTE.childRoutes.push(CONFIGURATION_BMS_LICENSES_ROUTE);
    }
}

export function enableLicenseExtractionChildRoutes(bms: boolean): void {
    LICENSES_EXTRACTION_ROUTE.childRoutes = [];
    if (bms) {
        LICENSES_EXTRACTION_ROUTE.childRoutes.push(LICENSES_BMS_LICENSES_ROUTE);
    }
    LICENSES_EXTRACTION_ROUTE.childRoutes.push(ENTITLEMENT_LICENSES_ROUTE);
    LICENSES_EXTRACTION_ROUTE.childRoutes.push(CONTAINERS_LICENSES_ROUTE);
}

export function enableBmsReportChildRoutes(bms: boolean): void {
    REPORTS_ROUTE.childRoutes = [];
    REPORTS_ROUTE.childRoutes.push(ALL_REPORTS_ROUTE);
    if (bms) {
        REPORTS_ROUTE.childRoutes.push(ALL_BMS_REPORTS_ROUTE);
    }
    REPORTS_ROUTE.childRoutes.push(REPORTS_OUR_VIEWS_ROUTE);
    REPORTS_ROUTE.childRoutes.push(REPORTS_TEMPLATE_ROUTE);
}

export function enableCustomReportViewRoutes(customReportViews: CustomReportView[]): void {
    // Clear any existing custom routes
    removeCustomRoutes(REPORTS_OUR_VIEWS_ROUTE.childRoutes);
    removeCustomRoutes(ROUTES);
    for (const view of customReportViews) {
        const customRoute = new RouteDefinition(
            "/reports/ourViews" + "/" + view.uuid,
            view.name,
            () => AllReportsView({ viewDetails: view }),
            { custom: true, isShared: view.shared }
        );
        if (!REPORTS_OUR_VIEWS_ROUTE.containsChildRoute(customRoute)) {
            REPORTS_OUR_VIEWS_ROUTE.childRoutes.push(customRoute);
            ROUTES.unshift(customRoute);
        }
    }
}

const removeCustomRoutes = (routes: RouteDefinition[]) => {
    let i = routes.length;
    while (i--) {
        if (routes[i].custom) {
            routes.splice(i, 1);
        }
    }
};

export const CUSTOM_DASHBOARD_OVERVIEW = new RouteDefinition(
    "/dashboard/overview/custom-overview",
    "Routes.overviewDashboard",
    CustomDashboard,
    {
        featureLicenses: ["FEATURE_OVERVIEW_DASHBOARD"],
    }
);

export const BLANCCO_IMPACT_DASHBOARD_ROUTE = new RouteDefinition(
    "/dashboard/sustainability/blancco",
    "Routes.blanccosImpact",
    EsgDashboardView
);

export const OUR_IMPACT_DASHBOARD_ROUTE = new RouteDefinition(
    "/dashboard/sustainability/own",
    "Routes.ourImpact.title",
    CustomerEsgDashboardView,
    {
        featureLicenses: ["FEATURE_SUSTAINABILITY_DASHBOARD"],
        advertised: true,
    }
);

export const SUSTAINABILITY_DASHBOARD_ROUTE = new RouteDefinition(
    "/dashboard/sustainability/blancco",
    "Routes.esgDashboard",
    EsgDashboardView,
    {
        childRoutes: [BLANCCO_IMPACT_DASHBOARD_ROUTE, OUR_IMPACT_DASHBOARD_ROUTE],
        testId: testIds.navigation.reportingMenu.sustainabilityLink,
    }
);

export const DASHBOARD_ROUTE = new RouteDefinition("/dashboard", "Routes.dashboard", DashboardView, {
    childRoutes: [CUSTOM_DASHBOARD_OVERVIEW],
    testId: testIds.navigation.reportingMenu.dashboardLink,
});
export const LOGIN_ROUTE = new RouteDefinition("/login", "Routes.login", LoginView, { secured: false });
export const LOGOUT_ROUTE = new RouteDefinition("/logout", "Routes.logout", LogoutView);
export const PROFILE_ROUTE = new RouteDefinition("/users/profile", "Routes.profile", UserProfileView);
export const CREATE_NEW_PASSWORD_ROUTE = new RouteDefinition(
    "/create-new-password",
    "Routes.createNewPassword",
    () => CreateNewPasswordView(),
    { secured: false }
);

const LOGGED_IN_NON_ACCESS_USER_VALIDATOR: UserValidator = (user: UserDetails | null) =>
    user !== null && !hasTenantCookie();

export const REPORTS_TEMPLATE_ROUTE = new RouteDefinition(
    "/reports/erasure/templates",
    "Routes.reportTemplates",
    ReportTemplatesView,
    {
        featureLicenses: ["FEATURE_REPORT_CUSTOMIZATION"],
        userValidators: [createAuthorityUserValidator([AUTH_REPORT_TEMPLATE_VIEW])],
    }
);

export const ALL_REPORTS_ROUTE = new RouteDefinition("/reports/all", "Common.allReports", () => AllReportsView(), {
    testId: testIds.workArea.report.sideMenu.allReportsLink,
});

export const ALL_BMS_REPORTS_ROUTE = new RouteDefinition(
    "/reports/bms",
    "Common.mobileRetailReports",
    () => AllBmsReportsView(),
    {
        featureLicenses: ["FEATURE_REPORTING"],
        testId: testIds.workArea.report.sideMenu.allBmsReportsLink,
    }
);

export const REPORTS_OUR_VIEWS_ROUTE = new RouteDefinition(
    "/reports/ourViews",
    "Routes.ourViews",
    () => AllReportsView(),
    {
        featureLicenses: ["FEATURE_REPORT_CUSTOMIZATION", "FEATURE_CUSTOM_REPORT_VIEWS"],
        testId: testIds.workArea.report.sideMenu.ourViews,
    }
);

export const REPORTS_ROUTE = new RouteDefinition("/reports", "Routes.reports", ReportsView, {
    childRoutes: [ALL_REPORTS_ROUTE, REPORTS_OUR_VIEWS_ROUTE, REPORTS_TEMPLATE_ROUTE],
    testId: testIds.navigation.reportingMenu.reportLink,
    featureLicenses: ["FEATURE_REPORTING"],
});

export const USERS_ALL_USERS_ROUTE = new RouteDefinition("/users/all", "Common.userTitle", AllUsersView, {
    userValidators: [createAuthorityUserValidator([AUTH_USER_VIEW])],
});

export const USERS_ROLES_ROUTE = new RouteDefinition("/users/roles", "UserRoles.title", UserRolesView, {
    userValidators: [
        createAuthorityUserValidator([
            AUTH_ROLE_VIEW,
            AUTH_ROLE_CREATE,
            AUTH_ROLE_DEFAULT,
            AUTH_ROLE_DELETE,
            AUTH_ROLE_EDIT,
        ]),
    ],
    featureLicenses: ["FEATURE_CUSTOM_ROLES"],
    featureFlag: FLAG_ROLES,
});

export const USER_GROUPS_ROUTE = new RouteDefinition("/users/groups", "UserGroups.title", UserGroupsView, {
    userValidators: [
        createAuthorityUserValidator([
            AUTH_USER_GROUP_CREATE,
            AUTH_USER_GROUP_DELETE,
            AUTH_USER_GROUP_EDIT,
            AUTH_USER_GROUP_VIEW,
        ]),
    ],
    featureLicenses: ["FEATURE_USER_GROUPS"],
    featureFlag: FLAG_USER_GROUPS,
});

const createUsersViewValidator = (): UserValidator => {
    const roleValidator = createAuthorityUserValidator([
        AUTH_ROLE_VIEW,
        AUTH_ROLE_CREATE,
        AUTH_ROLE_DEFAULT,
        AUTH_ROLE_DELETE,
        AUTH_ROLE_EDIT,
    ]);
    const userValidator = createAuthorityUserValidator([
        AUTH_USER_VIEW,
        AUTH_USER_CREATE,
        AUTH_USER_DELETE,
        AUTH_USER_EDIT,
    ]);
    return (user: UserDetails | null) => {
        if (userValidator(user)) {
            return true;
        }
        if (roleValidator(user)) {
            return tenantHasFeatureLicense("FEATURE_CUSTOM_ROLES");
        }

        return false;
    };
};

export const USERS_ROUTE = new RouteDefinition("/users", "Common.users", UsersView, {
    childRoutes: [USERS_ALL_USERS_ROUTE, USER_GROUPS_ROUTE, USERS_ROLES_ROUTE],
    testId: testIds.navigation.managementMenu.userLink,
    userValidators: [createUsersViewValidator()],
});

export const TENANTS_ALL_TENANTS_ROUTE = new RouteDefinition("/customers/all", "Common.customerTitle", AllTenantsView, {
    userValidators: [createAuthorityUserValidator([AUTH_TENANT_VIEW])],
    featureLicenses: ["FEATURE_TENANT_MANAGEMENT"],
});
export const TENANTS_ROUTE = new RouteDefinition("/customers", "Common.customers", TenantsView, {
    childRoutes: [TENANTS_ALL_TENANTS_ROUTE],
    testId: testIds.navigation.managementMenu.tenantLink,
    featureLicenses: ["FEATURE_TENANT_MANAGEMENT"],
});

export const ALL_API_KEYS_ROUTE = new RouteDefinition("/support/apiKeys/all", "Common.allApiKeys", AllApiKeysView, {
    userValidators: [LOGGED_IN_NON_ACCESS_USER_VALIDATOR, createAuthorityUserValidator([AUTH_API_KEY_VIEW_ALL])],
});

export const MY_API_KEYS_ROUTE = new RouteDefinition("/support/apiKeys/own", "Common.myApiKeys", MyApiKeysView, {
    userValidators: [LOGGED_IN_NON_ACCESS_USER_VALIDATOR, createAuthorityUserValidator([AUTH_API_KEY_VIEW_OWN])],
});
export const API_KEYS_ROUTE = new RouteDefinition("/support/apiKeys", "Routes.apiKeys", ApiKeysView, {
    childRoutes: [ALL_API_KEYS_ROUTE, MY_API_KEYS_ROUTE],
    featureLicenses: ["FEATURE_PUBLIC_APIS"],
    userValidators: [LOGGED_IN_NON_ACCESS_USER_VALIDATOR],
});

export const API_GUIDE_ROUTE = new RouteDefinition("/support/api", "Routes.apiGuide", ApiGuideView, {
    featureLicenses: ["FEATURE_PUBLIC_APIS"],
    userValidators: [createAuthorityUserValidator([AUTH_API_KEY_VIEW_ALL, AUTH_API_KEY_VIEW_OWN])],
});
export const REPORT_PATHS_ROUTE = new RouteDefinition("/support/report-paths", "Routes.reportPaths", ReportPathsView, {
    featureFlag: FLAG_SUPPORT_REPORT_PATHS,
    featureLicenses: ["FEATURE_PUBLIC_APIS", "FEATURE_REPORTING"],
});
export const SUPPORT_AND_HELP_ROUTE = new RouteDefinition(
    "/support/supportAndHelp",
    "Routes.supportAndHelp",
    SupportAndHelpView
);
export const SUPPORT_ROUTE = new RouteDefinition("/support", "Routes.support", SupportView, {
    childRoutes: [SUPPORT_AND_HELP_ROUTE, API_GUIDE_ROUTE, API_KEYS_ROUTE, REPORT_PATHS_ROUTE],
    testId: testIds.navigation.supportLink,
});

function createAuthorityUserValidator(authorities: string[]): UserValidator {
    return (user: UserDetails | null) => {
        if (user == null) {
            return false;
        }
        if (authorities.length === 0) {
            return true;
        }
        const userAuthorities = new Set<string>(user.role.authorities);
        for (const each of authorities) {
            if (userAuthorities.has(each)) {
                return true;
            }
        }
        return false;
    };
}

export const LICENSES_ALL_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/all",
    "Licenses.allLicenses",
    AllLicensesView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_LICENSE_VIEW])],
    }
);

export const LICENSES_MY_LICENSES_ROUTE = new RouteDefinition("/licenses/own", "Licenses.myLicenses", MyLicensesView, {
    userValidators: [LOGGED_IN_NON_ACCESS_USER_VALIDATOR, createAuthorityUserValidator([AUTH_LICENSE_VIEW_OWN])],
});

export const LICENSES_POOL_ROUTE = new RouteDefinition(
    "/licenses/pools",
    "Licenses.allLicensesPools",
    AllLicensePoolsView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_LICENSE_POOL_VIEW])],
        featureLicenses: ["FEATURE_LICENSE_POOLS"],
    }
);

export const LICENSES_ALERT_ROUTE = new RouteDefinition(
    "/licenses/alerts",
    "LicenseAlerts.title",
    AllLicenseAlertsView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_LICENSE_ALERT])],
        featureFlag: FLAG_LICENSE_ALERTS,
        featureLicenses: ["FEATURE_LICENSE_ALERTS"],
    }
);

export const LICENSES_BMS_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/bms",
    "LicenseKeys.mobileSolutionsKeys",
    BmsLicenseKeysView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_LICENSE_VIEW, AUTH_LICENSE_VIEW_OWN, AUTH_LICENSE_ASSIGN])],
    }
);

export const CONFIGURATION_BMS_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/configuration",
    "Configuration.title",
    BmsConfigurationView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_TENANT_SETTINGS])],
    }
);

export const ENTITLEMENT_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/entitlement",
    "Entitlements.title",
    EntitlementView,
    {
        userValidators: [
            createAuthorityUserValidator([AUTH_EMS_VIEW, AUTH_EMS_CREATE_ENTITLEMENT, AUTH_EMS_SYNCHRONIZE]),
            hasEmsId,
        ],
    }
);

export const CONTAINERS_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/containers",
    "Containers.title",
    ContainersView,
    {
        userValidators: [
            createAuthorityUserValidator([AUTH_EMS_VIEW, AUTH_EMS_CREATE_ENTITLEMENT, AUTH_EMS_SYNCHRONIZE]),
        ],
    }
);

export const DELIVERYHISTORY_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/delivery-history",
    "DeliveryHistory.title",
    DeliveryHistoryView,
    {
        userValidators: [createAuthorityUserValidator([AUTH_LICENSE_VIEW, AUTH_LICENSE_ASSIGN])],
    }
);

export const LICENSE_HISTORY_ROUTE = new RouteDefinition("/licenses/history", "LicenseEvent.title", LicenseEventsView, {
    userValidators: [createAuthorityUserValidator([AUTH_LICENSE_HISTORY_VIEW_OWN, AUTH_LICENSE_HISTORY_VIEW_ALL])],
});

export const LICENSES_EXTRACTION_ROUTE = new RouteDefinition(
    "/licenses",
    "Licenses.licenseKeyGeneration",
    LicensesView,
    {
        childRoutes: [ENTITLEMENT_LICENSES_ROUTE, CONTAINERS_LICENSES_ROUTE],
        testId: testIds.navigation.managementMenu.licenseLink,
    }
);

export const LICENSES_ROUTE = new RouteDefinition("/licenses", "Licenses.licenses", LicensesView, {
    childRoutes: [LICENSES_ALL_LICENSES_ROUTE, CONTAINERS_LICENSES_ROUTE, LICENSES_MY_LICENSES_ROUTE],
    testId: testIds.navigation.managementMenu.licenseLink,
});

export const DOWNLOAD_PAGE_ROUTE = new RouteDefinition("/download/bms/:licenseKey?", "", DownloadPageView, {
    secured: false,
});

export const IOS_PROFILE_PAGE_ROUTE = new RouteDefinition("/download/profile/:journeyId?", "", IosProfilePage, {
    secured: false,
});

export const IOS_PROFILE_DOWNLOAD_ROUTE = new RouteDefinition(
    "/download/ios/profile/:journeyId?/complete",
    "",
    IosProfileDownload,
    {
        secured: false,
    }
);

export const ALL_WORKFLOWS_ROUTE = new RouteDefinition("/workflows/all", "Common.workflowsTitle", AllWorkflowsView, {
    userValidators: [createAuthorityUserValidator([AUTH_WORKFLOW_VIEW])],
});

export const WORKFLOWS_ROUTE = new RouteDefinition("/workflows", "Routes.workflowsMenu.workflows", WorkflowsView, {
    childRoutes: [ALL_WORKFLOWS_ROUTE],
    testId: testIds.navigation.managementMenu.workflowsLink,
    featureLicenses: ["FEATURE_WORKFLOW_BDE", "FEATURE_WORKFLOW_BMDE"],
    advertised: true,
});

// Note: child routes must precede parent routes. E.g. ENTITLEMENT_LICENSES_ROUTE must come before LICENSES_ROUTE.
export const ROUTES: RouteDefinition[] = [
    REPORT_PATHS_ROUTE,
    API_GUIDE_ROUTE,
    SUPPORT_AND_HELP_ROUTE,
    CREATE_NEW_PASSWORD_ROUTE,
    CUSTOM_DASHBOARD_OVERVIEW,
    BLANCCO_IMPACT_DASHBOARD_ROUTE,
    OUR_IMPACT_DASHBOARD_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    PROFILE_ROUTE,
    REPORTS_TEMPLATE_ROUTE,
    ALL_REPORTS_ROUTE,
    ALL_BMS_REPORTS_ROUTE,
    REPORTS_ROUTE,
    REPORTS_OUR_VIEWS_ROUTE,
    MY_API_KEYS_ROUTE,
    ALL_API_KEYS_ROUTE,
    API_KEYS_ROUTE,
    SUPPORT_ROUTE,
    TENANTS_ALL_TENANTS_ROUTE,
    TENANTS_ROUTE,
    USERS_ALL_USERS_ROUTE,
    USERS_ROLES_ROUTE,
    USER_GROUPS_ROUTE,
    USERS_ROUTE,
    LICENSES_MY_LICENSES_ROUTE,
    LICENSES_ALL_LICENSES_ROUTE,
    LICENSES_ALERT_ROUTE,
    LICENSES_POOL_ROUTE,
    DELIVERYHISTORY_LICENSES_ROUTE,
    LICENSE_HISTORY_ROUTE,
    LICENSES_BMS_LICENSES_ROUTE,
    CONFIGURATION_BMS_LICENSES_ROUTE,
    ENTITLEMENT_LICENSES_ROUTE,
    CONTAINERS_LICENSES_ROUTE,
    LICENSES_EXTRACTION_ROUTE,
    LICENSES_ROUTE,
    DOWNLOAD_PAGE_ROUTE,
    ALL_WORKFLOWS_ROUTE,
    WORKFLOWS_ROUTE,
    IOS_PROFILE_PAGE_ROUTE,
    IOS_PROFILE_DOWNLOAD_ROUTE,
];

export function deriveRedirectTarget(route: RouteDefinition): RouteDefinition {
    const target = route.childRoutes.find((r) => r.isValidUser(userSessionService.currentUserDetails()));
    if (target == null) {
        return LOGIN_ROUTE;
    }
    if (target.childRoutes.length === 0) {
        return target;
    }
    return deriveRedirectTarget(target);
}
