import Cookie from "js-cookie";

import { CORPORATE_BUNDLES, PROCESSOR_BUNDLES } from "components/licenses/bundles";
import {
    CombinedTier,
    INTERNAL_TENANT_UUID,
    LicensingModel,
    LoginMethod,
    OldLicensingModelTier,
    TenantType,
} from "domain/tenants";
import { FeatureLicenseType } from "domain/users";
import { getUser } from "services/user/userSessionRepository";
import { TenantDetails, TenantDetailsState } from "store/tenantDetails";
import { getObject, RepositoryKey } from "utils/repository";

const TENANT_UUID_COOKIE_NAME = "TENANT_UUID";
const TENANT_NAME_COOKIE_NAME = "TENANT_NAME";
const TENANT_TIER_COOKIE_NAME = "TENANT_TIER";
const PARENT_TENANT_TIER_COOKIE_NAME = "PARENT_TENANT_TIER";
const TENANT_DETAILS = "TENANT_DETAILS";
const INTERNAL_TENANT_TYPE = "INTERNAL";

export function getTenantUuids(): string[] {
    const tenantDetails = Cookie.get(TENANT_DETAILS);
    let uuids: string[] = [];
    if (typeof tenantDetails !== "undefined") {
        uuids = JSON.parse(tenantDetails).tenant_uuids;
    }
    return uuids;
}

function getUuid(): string | undefined {
    let uuid = Cookie.get(TENANT_UUID_COOKIE_NAME);
    const uuids = getTenantUuids();

    if (typeof uuids !== undefined && uuids.length > 0) {
        uuid = uuids[uuids.length - 1];
    }
    return uuid;
}

export function hasTenantCookie(): boolean {
    return getUuid() != null;
}

export function hasSubTenantCookie(): boolean {
    const uuids = getTenantUuids();
    if (typeof uuids !== undefined && uuids.length > 0) {
        return uuids.length > 2;
    } else {
        return false;
    }
}

export function getUserTier(): CombinedTier {
    const userDetails = getUser();
    if (userDetails !== null) {
        return userDetails.tier;
    }
    return "";
}

export function getCurrentTenantDetails(): TenantDetails {
    const tenantDetailsState: TenantDetailsState | null = getObject(RepositoryKey.TENANT_DETAILS);
    if (typeof tenantDetailsState !== undefined && tenantDetailsState !== null) {
        const tenantDetails = tenantDetailsState.stack;
        if (tenantDetails.length > 0) {
            return tenantDetails[tenantDetails.length - 1];
        }
    }
    return {
        uuid: "",
        region: "",
        type: "CUSTOMER",
        featureLicenses: [],
        tenantName: "",
        tenantTier: "BASIC",
        licensingModel: LicensingModel.OLD_MODEL,
    };
}

export function isCurrentTenantInternal(): boolean {
    const userDetails = getUser();
    if (userDetails !== null) {
        return userDetails.tenantType == INTERNAL_TENANT_TYPE && !hasTenantCookie() && !hasSubTenantCookie();
    }
    return false;
}

export function getParentUuid(): string | undefined {
    const allUuids = getTenantUuids();
    return allUuids[allUuids.length - 2];
}

export function showAllowedTierList(
    targetedUuid: string,
    tenantType?: TenantType,
    tenantTier?: string,
    oldLicensingModel?: boolean
): string[] {
    let finalTierList: string[] = [];
    const parentTier = getParentTenantTier();
    const parentUuid = getParentUuid();
    const currentTenantUuid = getUuid();
    const tier = tenantTier === undefined ? getUserTier() : tenantTier;
    const currentTenantTier = hasTenantCookie() ? getTenantTier() : tier;
    const completeTiers: string[] = [];
    if (tenantType === undefined || oldLicensingModel === undefined) {
        completeTiers.push(...Object.keys(OldLicensingModelTier));
    } else {
        completeTiers.push(
            ...(oldLicensingModel
                ? Object.keys(OldLicensingModelTier)
                : tenantType == "CORPORATE"
                ? CORPORATE_BUNDLES
                : PROCESSOR_BUNDLES)
        );
    }

    if (parentUuid === INTERNAL_TENANT_UUID) {
        // Logged in as root or tenant user
        finalTierList = getAllowedTierList(completeTiers, currentTenantTier);
    } else {
        if (targetedUuid == "") {
            // Add sub tenant
            if (tenantType == "CHANNEL") {
                return [OldLicensingModelTier.ADVANCED];
            }
            return getAllowedTierList(completeTiers, currentTenantTier);
        }
        if (targetedUuid != currentTenantUuid) {
            // Edit sub tenant
            return getAllowedTierList(completeTiers, currentTenantTier);
        }
        if (targetedUuid == currentTenantUuid) {
            // Self tenant edit after accessing from breadcrumb
            return getAllowedTierList(completeTiers, parentTier);
        }
    }
    return finalTierList;
}

const getAllowedTierList = (tierList: string[], tier: string) => {
    const finalTierList: string[] = [];
    // Check the order of items in tierList as it is just an array.
    for (let i = 0; i < tierList.length; i++) {
        finalTierList.push(tierList[i]);
        if (tierList[i] == tier) {
            break;
        }
    }
    return finalTierList;
};

export function removeTenantCookie(): void {
    const domain = "." + window.location.hostname;
    [TENANT_UUID_COOKIE_NAME, TENANT_NAME_COOKIE_NAME, TENANT_TIER_COOKIE_NAME, TENANT_DETAILS].forEach((each) =>
        Cookie.remove(each, { domain })
    );
}

export function getTenantName(): string {
    return Cookie.get(TENANT_NAME_COOKIE_NAME) || "";
}

export function getTenantTier(): CombinedTier {
    return Cookie.get(TENANT_TIER_COOKIE_NAME) || "";
}

export function getParentTenantTier(): string {
    return Cookie.get(PARENT_TENANT_TIER_COOKIE_NAME) || "";
}

export function updateTenantName(value: string) {
    const domainName = "." + window.location.hostname;
    const options = {
        expires: 30,
        secure: true,
        domain: domainName,
    };

    Cookie.remove(TENANT_NAME_COOKIE_NAME, { domainName });
    Cookie.set(TENANT_NAME_COOKIE_NAME, value, options);
}

export function updateTenantUuid(value: string) {
    const domainName = "." + window.location.hostname;
    const options = {
        expires: 30,
        secure: true,
        domain: domainName,
    };

    Cookie.remove(TENANT_UUID_COOKIE_NAME, { domainName });
    Cookie.set(TENANT_UUID_COOKIE_NAME, value, options);
}

export function getTenantUuid(): string {
    const uuid = getUuid();
    if (uuid == null) {
        throw new Error("Tenant UUID is null");
    }
    return uuid;
}

export function tenantHasFeatureLicense(featureLicense: FeatureLicenseType): boolean {
    return getCurrentTenantDetails().featureLicenses.includes(featureLicense);
}

export function deriveTenantUuid(): string {
    if (hasTenantCookie()) {
        return getTenantUuid();
    }

    const user = getUser();
    if (user == null) {
        throw new Error("Can't derive tenant UUID. It should be impossible for user to be null but it is.");
    }
    return user.tenantUuid;
}

export function isUserParentInternal(): boolean {
    const userDetails = getUser();
    if (userDetails !== null) {
        return userDetails.tenantType == INTERNAL_TENANT_TYPE;
    }
    return false;
}

export function getTenantLayerList(): TenantDetails[] {
    const tenantDetailsState: TenantDetailsState | null = getObject(RepositoryKey.TENANT_DETAILS);
    if (typeof tenantDetailsState !== undefined && tenantDetailsState !== null) {
        const tenantDetails = tenantDetailsState.stack;
        if (tenantDetails.length > 0) {
            return tenantDetails;
        }
    }
    return [
        {
            uuid: "",
            region: "",
            type: "CUSTOMER",
            featureLicenses: [],
            tenantName: "",
            tenantTier: "BASIC",
            licensingModel: LicensingModel.OLD_MODEL,
        },
    ];
}

export function getTenantLoginMethod(): LoginMethod | undefined {
    const tenantDetails = getCurrentTenantDetails();
    return tenantDetails.loginMethod;
}
