import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useState } from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";

import style from "./user-menu.scss";
import { ViewTenantDetails } from "components/customer-details/ViewTenantDetails";
import DarkModeSwitch from "components/dark-mode-switch/DarkModeSwitch";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import CreateNewPasswordView from "components/login/create-new-password/CreateNewPasswordView";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import SettingsView from "components/settings/SettingsView";
import UserProfileView from "components/users/profile/UserProfileView";
import { Tenant } from "domain/tenants";
import { FLAG_CHANGE_PASSWORD } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantUuid, hasTenantCookie } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

enum ProfileState {
    LOADING = 0,
    LOADING_SUCCESS,
    LOADING_FAILED,
}

interface Props {
    className?: string;
}

export const UserMenu = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [userProfileVisibility, setUserProfileVisibility] = useState(false);
    const [settingsVisibility, setSettingsVisibility] = useState(false);
    const [changePasswordFormVisibility, setChangePasswordFormVisibility] = useState(false);
    const showUserProfile = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_PROFILE,
        });
        setUserProfileVisibility(true);
    };

    const hideUserProfile = () => {
        setUserProfileVisibility(false);
    };

    const showChangePasswordForm = () => {
        setChangePasswordFormVisibility(true);
    };
    const hideChangePassword = () => {
        setChangePasswordFormVisibility(false);
    };

    const showSettings = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_SETTINGS,
        });
        setSettingsVisibility(true);
    };

    const hideSettings = () => {
        setSettingsVisibility(false);
    };

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tenantDetails, setTenantDetails] = React.useState<Tenant>();
    const [profileState, setProfileState] = React.useState(ProfileState.LOADING);
    const [tenantProfileVisibility, setTenantProfileVisibility] = useState(false);

    const fetchData = () => {
        setTenantProfileVisibility(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchTenant(getTenantUuid(), abortController)
            .then((tenantDetails) => {
                setTenantDetails(tenantDetails);
                setProfileState(ProfileState.LOADING_SUCCESS);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setProfileState(ProfileState.LOADING_FAILED);
                }
            });
    };

    let child = <></>;
    switch (profileState) {
        case ProfileState.LOADING:
            child = <LoadingIndicator />;
            break;
        case ProfileState.LOADING_SUCCESS:
            child = tenantDetails ? <ViewTenantDetails activeTenant={tenantDetails} /> : <></>;
            break;
        case ProfileState.LOADING_FAILED:
            child = (
                <div>
                    <div className={style.errorContainer}>{t("CustomerProfile.refreshRequestFailed")}</div>
                    <div className={form.buttonContainer}>
                        <button
                            className={classNames(buttonStyle.primaryButton, buttonStyle.medium, style.okButton)}
                            onClick={() => {
                                setTenantProfileVisibility(false);
                            }}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            );
            break;
    }

    const tenantProfileModal = (
        <Modal
            key={1}
            isOpen={tenantProfileVisibility}
            hideModal={() => setTenantProfileVisibility(false)}
            modalTitle={t("Routes.profile")}
        >
            {child}
        </Modal>
    );

    const userProfileModal = (
        <Modal isOpen={userProfileVisibility} hideModal={hideUserProfile} modalTitle={t("Routes.profile")}>
            <UserProfileView />
        </Modal>
    );

    const menuItems: JSX.Element[] = [
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={hasTenantCookie() ? fetchData : showUserProfile}
            data-testid={testIds.header.userMenu.profileLink}
        >
            {t("Routes.profile")}
        </MenuItemButton>,
        <MenuItemButton key="2" tabIndex={0} onClick={showSettings} data-testid={testIds.header.userMenu.settingLink}>
            {t("Common.settings")}
        </MenuItemButton>,
        <MenuItem key="3">
            <div className={style.showOnlyOnMobile}>
                <DarkModeSwitch textOnly={true} />
            </div>
        </MenuItem>,
        <>
            {useFeature(FLAG_CHANGE_PASSWORD) && (
                <MenuItemButton key="4" tabIndex={0} onClick={showChangePasswordForm}>
                    {t("Routes.changePassword")}
                </MenuItemButton>
            )}
        </>,
        <MenuItem
            key="5"
            tag="a"
            tabIndex={0}
            href="/logout"
            data-testid={testIds.header.userMenu.logoutLink}
            onClick={() => {
                usageStatisticsService.sendEvent({
                    category: Category.HEADER,
                    action: Action.LOGOUT,
                });
            }}
        >
            {t("Routes.logout")}
        </MenuItem>,
    ];

    return (
        <>
            <Menu className={classNames(style.menu, props.className)}>
                <ul>
                    {menuItems.map((item, key) => (
                        <li key={key} className={style.item}>
                            {item}
                        </li>
                    ))}
                </ul>
            </Menu>
            {hasTenantCookie() ? tenantProfileModal : userProfileModal}
            <Modal isOpen={settingsVisibility} hideModal={hideSettings} modalTitle={t("Common.settings")}>
                <SettingsView onCancel={hideSettings} />
            </Modal>
            <Modal
                isOpen={changePasswordFormVisibility}
                hideModal={hideChangePassword}
                modalTitle={t("Routes.changePassword")}
            >
                <CreateNewPasswordView changePassword={true} setChangePasswordFormVisibility={hideChangePassword} />
            </Modal>
        </>
    );
};
