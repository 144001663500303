import {
    BBTIConfiguration,
    InsuranceConfiguration,
    LeaseConfiguration,
    NtfConfiguration,
    Test,
    TestConfiguration,
    ValidationConfiguration,
} from "./BmsCommonInterfaces";
import defaultConfiguration from "./UiConfiguration.json";
import {
    BMS_LICENSE_FEATURE_ALL,
    BMS_LICENSE_FEATURE_GATHER_IMEI,
    BMS_LICENSE_TYPE_ALL,
    bmsLicenseTypesAndFeatureKeys,
} from "components/licenses/common";
import { LicenseList } from "domain/licenses";

export interface FeatureTestsConfiguration {
    features: string[];
    bbtiConfiguration: BBTIConfiguration;
    validationConfiguration: ValidationConfiguration;
    insuranceConfiguration: InsuranceConfiguration;
    ntfConfiguration: NtfConfiguration;
    leaseConfiguration: LeaseConfiguration;
}

export function getPlatformTests(platform: string): TestConfiguration[] {
    switch (platform) {
        case "appUSDK":
            return defaultConfiguration.appUSDKBasedTests;
        case "appISDK":
            return defaultConfiguration.appISDKBasedTests;
        case "browser":
            return defaultConfiguration.browserBasedTests;
        default:
            return defaultConfiguration.appUSDKBasedTests;
    }
}

export function getTestMode(testName: string, platformTests: TestConfiguration[]): string {
    const test = platformTests.find((test: { name: string }) => test.name === testName);
    if (test && test.mode && test.mode.length > 0) {
        return test.mode[0];
    }
    return "dedicated";
}

function updateTestsOnPlatformChange(
    currentEnabledTests: Test[],
    useUsdk: boolean,
    platformTests: TestConfiguration[]
): Test[] {
    currentEnabledTests.forEach((test: Test) => {
        test.usdk = useUsdk;
        test.mode = getTestMode(test.name, platformTests);
    });
    return currentEnabledTests;
}

export function updateFeatureTests(featureConfigurations: FeatureTestsConfiguration, platform: string) {
    const enabledFeature = featureConfigurations.features;
    const usdk = platform.includes("USDK");
    const platformTests = getPlatformTests(platform);

    enabledFeature.forEach((feature: string) => {
        switch (feature) {
            case "TradeIn":
                featureConfigurations.bbtiConfiguration.tests = updateTestsOnPlatformChange(
                    featureConfigurations.bbtiConfiguration.tests,
                    usdk,
                    platformTests
                );
                break;
            case "PreSalesInsurance":
                featureConfigurations.insuranceConfiguration.tests = updateTestsOnPlatformChange(
                    featureConfigurations.insuranceConfiguration.tests,
                    usdk,
                    platformTests
                );
                break;
            case "Validation":
                featureConfigurations.validationConfiguration.tests = updateTestsOnPlatformChange(
                    featureConfigurations.validationConfiguration.tests,
                    usdk,
                    platformTests
                );
                break;
            case "FaultCheck":
                featureConfigurations.ntfConfiguration.tests = updateTestsOnPlatformChange(
                    featureConfigurations.ntfConfiguration.tests,
                    usdk,
                    platformTests
                );
                break;
            case "Lease":
                featureConfigurations.leaseConfiguration.tests = updateTestsOnPlatformChange(
                    featureConfigurations.leaseConfiguration.tests,
                    usdk,
                    platformTests
                );
                break;
        }
    });

    return featureConfigurations;
}

export function selectLanguageForConditionalQuestions(
    bmpLanguage: string
): keyof typeof defaultConfiguration.questions {
    const normalizedLanguage = bmpLanguage.toLowerCase();
    const languages = Object.keys(defaultConfiguration.questions);

    // Check if the specified language exists in the configuration
    if (languages.includes(normalizedLanguage)) {
        return normalizedLanguage as keyof typeof defaultConfiguration.questions;
    } else {
        // Default to "en" if the specified language is not found
        return "en";
    }
}

export function fetchFeaturesWithAvailableLicense(licenseList: LicenseList) {
    const featuresWithAvailableLicense = [];
    const hasAllFeaturesLicenses = licenseList.licenses.licenseData.some(
        (license) => license.type === BMS_LICENSE_TYPE_ALL
    );
    if (hasAllFeaturesLicenses) {
        featuresWithAvailableLicense.push(BMS_LICENSE_FEATURE_ALL);
        return featuresWithAvailableLicense;
    }

    for (const { type, feature } of bmsLicenseTypesAndFeatureKeys) {
        if (
            licenseList.licenses.licenseData.some(
                (license: { type: string; available: number }) => license.type === type
            )
        ) {
            featuresWithAvailableLicense.push(feature);
        }
    }

    if (featuresWithAvailableLicense.length > 0) {
        featuresWithAvailableLicense.push(BMS_LICENSE_FEATURE_GATHER_IMEI);
    }

    return featuresWithAvailableLicense;
}
